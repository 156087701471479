import { useState, useEffect } from 'react';
import classes from './Modal.module.css';
import ReactModal from 'react-modal';

const getCustomStyles = (width: number) => {
  let right = '55%';
  if (width < 600) {
    right = '10%';
  } else if (width < 1000) {
    right = '30%';
  }
  return {
    overlay: {
      zIndex: 1000,
      background: 'rgba(0, 0, 0, 0.3)',
    },
    content: {
      top: '50%',
      left: '50%',
      right,
      bottom: 'auto',
      marginRight: '-50%',
      border: 'none',
      transform: 'translate(-50%, -50%)',
    },
  };
};

type Props = {
  title: string;
  display: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const Modal: React.FC<Props> = ({ title, display, onClose, children }) => {
  const getWindowDimensions = () => {
    const { innerWidth: width } = window;
    return { width };
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  const customStyles = getCustomStyles(windowDimensions.width);

  return (
    <ReactModal
      isOpen={display}
      style={customStyles}
      onRequestClose={() => onClose()}
      appElement={document.getElementById('root') as HTMLElement}
    >
      <p className={classes.title}>{title}</p>
      <button className={classes.closeButton} onClick={() => onClose()}>
        ×
      </button>
      {children}
    </ReactModal>
  );
};

export default Modal;
