import firebase from '../config/firebase';
import 'firebase/firestore';
import 'firebase/functions';
import { isLetterDocument } from './LetterDocumentTypeGuard';
import LetterDocument from './InterfaceOfLetterDocument';
import 'firebase/storage';
import { fetchAttachedImages } from './StorageUtils';

// fetch Document
const fetchLetterDocument = async (
  uid: string,
  id: string,
): Promise<LetterDocument | null> => {
  const fetchAttachedDatas = async (documentData: LetterDocument) => {
    const identifiers: string[] = [];
    documentData.elements.forEach((element) => {
      if (element.className.includes('set-image')) {
        // get className 'set-imageX'
        identifiers.push(element.className.split(' ')[0]);
      }
    });

    const datas: { key: string; url: string }[] = await fetchAttachedImages(
      uid,
      id,
      identifiers,
    );
    // set-imageXのXが一致するfirestoreのフィールドとstorageのデータを紐付ける
    documentData.elements.forEach((element) => {
      datas.forEach((data) => {
        if (element.className.split(' ')[0] === data.key) {
          element.src = data.url;
        }
      });
    });

    return documentData;
  };

  const db: firebase.firestore.Firestore = firebase.firestore();
  const lettersRef = db.collection('lightLetters').doc(uid);
  /* guest ユーザ用の処理 */
  if (uid === 'guests') {
    const func = firebase
      .app()
      .functions('asia-northeast1')
      .httpsCallable('fetchGuestDraft');
    const res = await func({ docId: id });
    const documentData = res.data;
    if (isLetterDocument(documentData)) {
      return fetchAttachedDatas(documentData);
    }
    console.error('letter type is mistaken.');
    return null;
  }

  /* guest以外のユーザ用の処理 */
  return lettersRef
    .collection('letters')
    .doc(id)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        const documentData = doc.data();
        if (isLetterDocument(documentData)) {
          return fetchAttachedDatas(documentData);
        }
      }
      // doc.data() will be undefined in this case
      console.log('No such document!');
      return null;
    })
    .catch((error) => {
      console.log('Error getting document:', error);
      return null;
    });
};

export default fetchLetterDocument;
