import { useState } from 'react';
import classes from '../../views/EditView.module.css';
import moveImg from './MoveImage';
import countImages from './CountImages';
import CustomContextMenu from './CustomContextMenu';

type Props = {
  selectedImageList: string[];
  onImportImageList: (imageList: string[], size: number) => void;
};

const SelectedImages: React.FC<Props> = ({
  selectedImageList,
  onImportImageList,
}) => {
  const [isContextMenuShown, setContextMenuShown] = useState<boolean>(false);
  const [menuPoint, setMenuPoint] = useState<{ x: number; y: number }>();
  const [removeIndex, setRemoveIndex] = useState<number>(0);
  const [rightCLickedClassNumber, setRightCLickedClassNumber] =
    useState<number>(0);

  const max = countImages('set-image');

  return (
    <>
      {selectedImageList?.map((imagePath: string, index: number) => {
        const addedIndex: number = max + index + 1;
        if (imagePath !== '') {
          return (
            <img
              key={addedIndex}
              src={imagePath}
              width=""
              height=""
              alt=""
              uk-img="true"
              className={'set-image' + addedIndex + ' ' + classes.img}
              onMouseDown={() => {
                moveImg('.set-image' + addedIndex);
              }}
              onContextMenu={(event) => {
                event.preventDefault();
                const area: HTMLDivElement | null =
                  document.querySelector('.area');
                const areaRect = area?.getBoundingClientRect();
                setMenuPoint({
                  x: event.pageX - areaRect!.left - window.pageXOffset,
                  y: event.pageY - areaRect!.top - window.pageYOffset,
                });
                setRemoveIndex(index);
                setRightCLickedClassNumber(addedIndex);
                setContextMenuShown(true);
              }}
            />
          );
        } else {
          return null;
        }
      })}
      {isContextMenuShown && (
        <CustomContextMenu
          menuPoint={menuPoint!}
          toggleContextMenu={(flag) => {
            setContextMenuShown(flag);
          }}
          removeElement={() => {
            const tmpString: string[] = selectedImageList;
            const size = tmpString[removeIndex].length;
            tmpString.splice(removeIndex, 1, '');
            onImportImageList([...tmpString], size);
          }}
          resizeElement={(action: string) => {
            const rightClickedElement: HTMLImageElement | null =
              document.querySelector('.set-image' + rightCLickedClassNumber);
            const resizeWeight = 0.1;
            if (rightClickedElement) {
              switch (action) {
                case 'minus': {
                  let size = 0;
                  if (!rightClickedElement.style.transform) {
                    // undefinedの場合、100%想定
                    size = 1;
                  } else {
                    // 現在のscaleを取得し、resizeWeight分[%]減少
                    // replace で数字のみ抽出
                    size = Number(
                      rightClickedElement.style.transform
                        .toString()
                        .replace(/[^0-9.]/g, ''),
                    );
                    size = size - resizeWeight;
                  }
                  rightClickedElement.style.transform = 'scale(' + size + ')';
                  break;
                }
                case 'plus': {
                  let size = 0;
                  if (!rightClickedElement.style.transform) {
                    // undefinedの場合、100%想定
                    size = 1;
                  } else {
                    // 現在のscaleを取得し、resizeWeight分[%]増加
                    // replace で数字のみ抽出
                    size = Number(
                      rightClickedElement.style.transform
                        .toString()
                        .replace(/[^0-9.]/g, ''),
                    );
                    size = size + resizeWeight;
                  }
                  rightClickedElement.style.transform = 'scale(' + size + ')';
                  break;
                }
                default: {
                  return;
                }
              }
            }
          }}
        />
      )}
    </>
  );
};

export default SelectedImages;
