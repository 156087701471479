import { useState, useEffect, useContext } from 'react';
import classes from './MyFonts.module.css';
import mountainMoon from '../../assets/imgs/mountain&moon.png';
import firebase from '../../config/firebase';
import { useHistory } from 'react-router-dom';
import { FontContext } from '../../FontProvider';
import { AuthContext } from '../../AuthProvider';
import { PlanContext } from '../../PlanProvider';
import { fetchMyFont, uploadHandwritingImage } from '../../lib/StorageUtils';
import UIkit from 'uikit';

const MyFonts = () => {
  const { user } = useContext(AuthContext);
  const { plan, loadingPlanState } = useContext(PlanContext);
  const {
    myFontExists,
    setMyFontExists,
    loadingFontState,
    setUrl,
    url,
    generatingMyFont,
    setGeneratingMyFont,
    failedLastTime,
    setFailedLastTime,
  } = useContext(FontContext);
  const [uploadfile, setUploadfile] = useState<File | null>();
  const [uploadImage, setUploadImage] = useState<string>();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const history = useHistory();
  const uid = user ? user.uid : null;
  const functions = firebase.app().functions('asia-northeast1');
  const deleteInprogressFunc = functions.httpsCallable('deleteInprogress');

  useEffect(() => {
    // free プランの場合、MyFontsのページは表示しない.
    if (plan === 'free' || uid === null) {
      history.push('/');
      return;
    }

    if (
      setFailedLastTime === undefined ||
      setGeneratingMyFont === undefined ||
      setMyFontExists === undefined ||
      setUrl === undefined
    ) {
      return;
    }

    // GCSからダウンロードしたマイフォントのblobURLが生成されている場合
    if (url) {
      const fontface = new FontFace('MyFont', `url(${url})`, {
        style: 'normal',
        weight: '400',
      });
      document.fonts.add(fontface);
      fontface.load();
    }

    const getStatus = async () => {
      try {
        // uid, plan を引数に取り、以下の返り値を返す
        // res[0]: フォントのbase64 string or null
        // res[1]: GCS上にinprogress ファイルが存在 -> true
        // res[2]: GCS上にfailed ファイルが存在 -> true
        const res = await fetchMyFont(uid);
        // GCS上にinprogress ファイルが存在
        if (res[1] === true) {
          setIsUploading(false);
          setGeneratingMyFont(true);
        }
        // GCS上にfailed ファイルが存在
        else if (res[2] === true) {
          setFailedLastTime(true);
          setGeneratingMyFont(false);
          setIsUploading(false);
          // ユーザがアップロードした画像を消去
          setUploadImage('');
          // 既存のマイフォントがあれば使用
          if (res[0] !== null || res[0] !== undefined) {
            setUrl(res[0]);
            setMyFontExists(true);
          }
        }
        // GCS上にinprogress/failed がない
        else {
          setUrl(res[0]);
          if (res[0] !== undefined) {
            setMyFontExists(true);
            setFailedLastTime(false);
            setGeneratingMyFont(false);
            setIsUploading(false);
            setUploadImage('');
          }
        }
      } catch (e) {
        console.error('error=' + e);
      }
    };

    // ポーリング処理用
    let intervalID = 0;

    const runClearInterval = () => {
      clearInterval(intervalID);
    };

    // ローカルの画像ファイルをアップロードする処理
    const reader = new FileReader();
    reader.onload = (e) => {
      if (typeof e.target?.result === 'string') {
        setUploadImage(e.target.result);
      }
    };

    if (uploadfile) {
      reader.readAsDataURL(uploadfile);
    }

    if (isUploading) {
      intervalID = window.setInterval(() => {
        getStatus();
      }, 35000);
    } else {
      if (generatingMyFont) {
        intervalID = window.setInterval(async () => {
          await getStatus();
          // timeout should be set only when inprogress file exists
          // if inprogress file does not exist, user can go back to MyFonts page by reload
          const myfontUploadedStrTime =
            localStorage.getItem('myfontUploadedStrTime') || '';
          const now = new Date();
          const myfontUploadedTime = new Date(myfontUploadedStrTime);
          if (now.getTime() - myfontUploadedTime.getTime() > 600000) {
            const response: { data: boolean } = await deleteInprogressFunc({
              uid: uid,
            });
            // deleteInprogressFunc should always responds true but
            // check if it is actually true in case of trouble with firebase function
            if (response.data === true) {
              setFailedLastTime(true);
              setGeneratingMyFont(false);
              setUploadImage('');
              localStorage.removeItem('myfontUploadedStrTime');
            } else {
              console.error(
                'application error, please contact the administrator (lightletter.contact@gmail.com).',
              );
            }
          }
        }, 35000);
      }
    }

    return () => {
      runClearInterval();
    };
  }, [
    deleteInprogressFunc,
    failedLastTime,
    generatingMyFont,
    history,
    isUploading,
    loadingFontState,
    loadingPlanState,
    myFontExists,
    plan,
    setFailedLastTime,
    setGeneratingMyFont,
    setMyFontExists,
    setUrl,
    uid,
    uploadfile,
    url,
  ]);

  return (
    <div>
      {/* myfont 無い場合かつ処理走ってない場合 */}
      {!myFontExists &&
      !failedLastTime &&
      !isUploading &&
      !generatingMyFont &&
      loadingFontState ? (
        <span
          className="uk-position-center"
          uk-spinner="true"
          style={{ color: 'orange' }}
        />
      ) : (
        !myFontExists &&
        !failedLastTime &&
        !isUploading &&
        !generatingMyFont && (
          <div className={classes.myfonts}>
            <h3 className={classes.ukH3 + ' ukH3'}>マイフォント</h3>
            <div>
              <h4 className="ukH3">
                あなただけのフォントで
                <p>想いがもっと届く手紙を。</p>
              </h4>
            </div>
            <h4>あなたの文字で手紙を書くことができます。</h4>
            {/* ここからアップロードボタン */}
            <div className="uk-flex" uk-grid="true">
              <div
                id="upload-drop"
                className={'uk-placeholder uk-flex ' + classes.textCenter}
                uk-form-custom="true"
                style={{ width: '500px' }}
              >
                Drag & Drop もしくは
                <br />
                あなたのコンピュータから画像を選択しましょう
                <img
                  src={mountainMoon}
                  className={classes.dragImage + ' uk-flex uk-margin-left'}
                  alt=""
                />
                <input
                  id="input"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className={classes.inputButtomContainer}
                  onChange={(e) => {
                    if (
                      e.target.files === null ||
                      e.target.files === undefined
                    ) {
                      return;
                    }
                    if (e.target.files[0].size < 5 * 1024 * 1024) {
                      setUploadfile(e.target.files ? e.target.files[0] : null);
                    } else {
                      UIkit.notification({
                        message: '画像のサイズが5 MBを超えています。',
                        pos: 'top-center',
                        timeout: 750,
                        status: 'danger',
                      });
                    }
                  }}
                />
              </div>
              <div
                id="myfont-preview"
                className={'uk-placeholder ' + classes.myFontUnregisterd}
                style={{ width: '200px', fontFamily: 'Noto Serif JP' }}
              >
                myfont 生成後、
                <br />
                こちらに表示されます
              </div>
            </div>
            <div className="uk-flex" uk-grid="true">
              {uploadfile?.name && (
                <div>
                  <p className="uk-margin-right">{`${uploadfile.name}が選択中です`}</p>
                  <button
                    className={classes.ukButtonPrimary + ' uk-button'}
                    type="button"
                    onClick={async () => {
                      if (!uploadfile) {
                        return;
                      }
                      const now = new Date();
                      localStorage.setItem(
                        'myfontUploadedStrTime',
                        now.toString(),
                      );
                      setIsUploading(true);
                      const reader = new FileReader();
                      const blob = new Blob([uploadfile], {
                        type: uploadfile.type,
                      });
                      reader.readAsDataURL(blob);
                      reader.onloadend = async () => {
                        const base64data = reader.result;
                        try {
                          if (uid && base64data) {
                            await uploadHandwritingImage(
                              uid,
                              base64data.toString(),
                            );
                          }
                        } catch (e) {
                          console.error('error=' + e);
                        }
                        setUploadfile(null);
                      };
                    }}
                  >
                    upload
                  </button>
                </div>
              )}
              {uploadfile && <img alt="uploadImage" src={uploadImage} />}
            </div>
          </div>
        )
      )}
      {/* 処理中画面 */}
      {(isUploading || generatingMyFont) && loadingFontState ? (
        <span
          className="uk-position-center"
          uk-spinner="true"
          style={{ color: 'orange' }}
        />
      ) : (
        (isUploading || generatingMyFont) && (
          <div>
            <div className={classes.myfonts}>
              <h3 style={{ color: 'rgb(252, 164, 105)' }}>マイフォント</h3>
              <h3>
                <p>現在処理中です。</p>
              </h3>
              <h5>もう少しであなただけのフォントが完成します。</h5>
              <span
                className="uk-position-center"
                uk-spinner="true"
                style={{ color: 'orange' }}
              />
            </div>
          </div>
        )
      )}
      {/* myfont ある場合 */}
      {myFontExists &&
      !failedLastTime &&
      !isUploading &&
      !generatingMyFont &&
      loadingFontState ? (
        <span
          className="uk-position-center"
          uk-spinner="true"
          style={{ color: 'orange' }}
        />
      ) : (
        myFontExists &&
        !failedLastTime &&
        !isUploading &&
        !generatingMyFont && (
          <div className={classes.myfonts}>
            <h3
              className={classes.ukH3 + ' ukH3'}
              style={{ color: 'rgb(252, 164, 105)' }}
            >
              マイフォント
            </h3>
            <div>
              <h4>あなた専用のオリジナルフォントを作成済みです！</h4>
            </div>
            <h4>
              新しい画像をアップロードすることで、何度でも作成し直すことができます。
            </h4>
            {/* ここからアップロードボタン */}
            <div className="uk-flex" uk-grid="true">
              <div
                id="upload-drop"
                className={'uk-placeholder ' + classes.textCenter}
                uk-form-custom="true"
                style={{ width: '500px' }}
              >
                Drag & Drop もしくは
                <br />
                あなたのコンピュータから画像を選択しましょう
                <img
                  src={mountainMoon}
                  className={classes.dragImage + ' uk-flex uk-margin-left'}
                  alt=""
                />
                <div>
                  <input
                    id="input"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    className={classes.inputButtomContainer}
                    onChange={(e) => {
                      if (
                        e.target.files === null ||
                        e.target.files === undefined
                      ) {
                        return;
                      }
                      if (e.target.files[0].size < 5 * 1024 * 1024) {
                        setUploadfile(
                          e.target.files ? e.target.files[0] : null,
                        );
                      } else {
                        UIkit.notification({
                          message: '画像のサイズが5 MBを超えています。',
                          pos: 'top-center',
                          timeout: 750,
                          status: 'danger',
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div
                id="myfont-preview"
                className={'uk-placeholder ' + classes.myFontRegisterd}
                style={{ width: '200px', fontFamily: 'MyFont' }}
              >
                myfont
              </div>
            </div>
            <div className="uk-flex" uk-grid="true">
              {uploadfile?.name && (
                <div>
                  <p className="uk-margin-right">{`${uploadfile.name}が選択中です`}</p>
                  <button
                    className={classes.ukButtonPrimary + ' uk-button'}
                    type="button"
                    uk-toggle="target: #create-font-modal; mode: click"
                    onClick={async () => {
                      if (!uploadfile) {
                        return;
                      }
                      const now = new Date();
                      localStorage.setItem(
                        'myfontUploadedStrTime',
                        now.toString(),
                      );
                      setIsUploading(true);
                      const reader = new FileReader();
                      const blob = new Blob([uploadfile], {
                        type: uploadfile.type,
                      });
                      reader.readAsDataURL(blob);
                      reader.onloadend = async () => {
                        const base64data = reader.result;
                        try {
                          if (uid && base64data) {
                            await uploadHandwritingImage(
                              uid,
                              base64data.toString(),
                            );
                          }
                        } catch (e) {
                          console.error('error=' + e);
                        }
                        setUploadfile(null);
                      };
                    }}
                  >
                    upload
                  </button>
                </div>
              )}
              {uploadfile && <img alt="uploadImage" src={uploadImage} />}
            </div>
          </div>
        )
      )}
      {/* 失敗時 & myfont 登録済み*/}
      {failedLastTime &&
      myFontExists &&
      !isUploading &&
      !generatingMyFont &&
      loadingFontState ? (
        <span
          className="uk-position-center"
          uk-spinner="true"
          style={{ color: 'orange' }}
        />
      ) : (
        failedLastTime &&
        myFontExists &&
        !isUploading &&
        !generatingMyFont && (
          <div>
            <div className={classes.myfonts}>
              <h1 style={{ color: 'rgb(252, 164, 105)' }}>マイフォント</h1>
              <h5>
                <p style={{ color: 'red' }}>
                  ⚠マイフォントの登録に失敗しました。
                </p>
                <p>他の画像で試してみましょう。</p>
                <p>あなただけの文字で手紙を書くことができます。</p>
              </h5>
              {/* ここからアップロードボタン */}
              <div className="uk-flex" uk-grid="true">
                <div
                  id="upload-drop"
                  className={'uk-placeholder ' + classes.textCenter}
                  uk-form-custom="true"
                  style={{ width: '500px' }}
                >
                  Drag & Drop もしくは
                  <br />
                  あなたのコンピュータから画像を選択しましょう
                  <img
                    src={mountainMoon}
                    className={classes.dragImage + ' uk-flex uk-margin-left'}
                    alt=""
                  />
                  <input
                    id="input"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    className={classes.inputButtomContainer}
                    onChange={(e) => {
                      if (
                        e.target.files === null ||
                        e.target.files === undefined
                      ) {
                        return;
                      }
                      if (e.target.files[0].size < 5 * 1024 * 1024) {
                        setUploadfile(
                          e.target.files ? e.target.files[0] : null,
                        );
                      } else {
                        UIkit.notification({
                          message: '画像のサイズが5 MBを超えています。',
                          pos: 'top-center',
                          timeout: 750,
                          status: 'danger',
                        });
                      }
                    }}
                  />
                </div>
                <div
                  id="myfont-preview"
                  className={'uk-placeholder ' + classes.myFontRegisterd}
                  style={{ width: '200px', fontFamily: 'MyFont' }}
                >
                  myfont
                </div>
              </div>
              <div className="uk-flex" uk-grid="true">
                {uploadfile?.name && (
                  <div>
                    <p className="uk-margin-right">{`${uploadfile.name}が選択中です`}</p>
                    <button
                      className={classes.ukButtonPrimary + ' uk-button'}
                      type="button"
                      onClick={async () => {
                        if (!uploadfile) {
                          return;
                        }
                        const now = new Date();
                        localStorage.setItem(
                          'myfontUploadedStrTime',
                          now.toString(),
                        );
                        setIsUploading(true);
                        const reader = new FileReader();
                        const blob = new Blob([uploadfile], {
                          type: uploadfile.type,
                        });
                        reader.readAsDataURL(blob);
                        reader.onloadend = async () => {
                          const base64data = reader.result;
                          try {
                            if (uid && base64data) {
                              await uploadHandwritingImage(
                                uid,
                                base64data.toString(),
                              );
                            }
                          } catch (e) {
                            console.error('error=' + e);
                          }
                          setUploadfile(null);
                        };
                      }}
                    >
                      upload
                    </button>
                  </div>
                )}
                {uploadfile && <img alt="uploadImage" src={uploadImage} />}
              </div>
            </div>
          </div>
        )
      )}
      {/* 失敗時 & myfont 未登録 */}
      {failedLastTime &&
      !myFontExists &&
      !isUploading &&
      !generatingMyFont &&
      loadingFontState ? (
        <span
          className="uk-position-center"
          uk-spinner="true"
          style={{ color: 'orange' }}
        />
      ) : (
        failedLastTime &&
        !myFontExists &&
        !isUploading &&
        !generatingMyFont && (
          <div>
            <div className={classes.myfonts}>
              <h3 style={{ color: 'rgb(252, 164, 105)' }}>マイフォント</h3>
              <h5>
                <p style={{ color: 'red' }}>
                  ⚠マイフォントの登録に失敗しました。
                </p>
                <p>他の画像で試してみましょう。</p>
                <p>あなたの文字で手紙を書くことができます。</p>
              </h5>
              {/* ここからアップロードボタン */}
              <div className="uk-flex" uk-grid="true">
                <div
                  id="upload-drop"
                  className={'uk-placeholder ' + classes.textCenter}
                  uk-form-custom="true"
                  style={{ width: '500px' }}
                >
                  Drag & Drop もしくは
                  <br />
                  あなたのコンピュータから画像を選択しましょう
                  <img
                    src={mountainMoon}
                    className={classes.dragImage + ' uk-flex uk-margin-left'}
                    alt=""
                  />
                  <input
                    id="input"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    className={classes.inputButtomContainer}
                    onChange={(e) => {
                      if (
                        e.target.files === null ||
                        e.target.files === undefined
                      ) {
                        return;
                      }
                      if (e.target.files[0].size < 5 * 1024 * 1024) {
                        setUploadfile(
                          e.target.files ? e.target.files[0] : null,
                        );
                      } else {
                        UIkit.notification({
                          message: '画像のサイズが5 MBを超えています。',
                          pos: 'top-center',
                          timeout: 750,
                          status: 'danger',
                        });
                      }
                    }}
                  />
                </div>
                <div
                  id="myfont-preview"
                  className={'uk-placeholder ' + classes.myFontUnregisterd}
                  style={{ width: '200px', fontFamily: 'Noto Serif JP' }}
                >
                  myfont 生成後、
                  <br />
                  こちらに表示されます
                </div>
              </div>
              <div className="uk-flex" uk-grid="true">
                {uploadfile && (
                  <div>
                    <p className="uk-margin-right">{`${uploadfile.name}が選択中です`}</p>
                    <button
                      className={classes.ukButtonPrimary + ' uk-button'}
                      type="button"
                      onClick={async () => {
                        if (!uploadfile) {
                          return;
                        }
                        const now = new Date();
                        localStorage.setItem(
                          'myfontUploadedStrTime',
                          now.toString(),
                        );
                        setIsUploading(true);
                        const reader = new FileReader();
                        const blob = new Blob([uploadfile], {
                          type: uploadfile.type,
                        });
                        reader.readAsDataURL(blob);
                        reader.onloadend = async () => {
                          const base64data = reader.result;
                          try {
                            if (uid && base64data) {
                              await uploadHandwritingImage(
                                uid,
                                base64data.toString(),
                              );
                            }
                          } catch (e) {
                            console.error('error=' + e);
                          }
                          setUploadfile(null);
                        };
                      }}
                    >
                      upload
                    </button>
                  </div>
                )}
                {uploadfile && <img alt={uploadfile.name} src={uploadImage} />}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default MyFonts;
