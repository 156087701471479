import { useContext } from 'react';
import classes from './Home.module.css';
import { Link, useHistory } from 'react-router-dom';
import { PlanContext } from '../../PlanProvider';
import draftImage from '../../assets/imgs/draft.jpg';
import myFont from '../../assets/imgs/myFont.jpg';
import completedLetter from '../../assets/imgs/completeLetter.jpg';
import recieveLetter from '../../assets/imgs/recieveLetter.jpg';

type Props = {
  toggleModal: (flags: boolean) => void;
};

const Home: React.FC<Props> = ({ toggleModal }) => {
  const { plan } = useContext(PlanContext);
  const history = useHistory();

  return (
    <>
      <main>
        <div
          className={
            classes.ukPositionCenter + ' uk-column-1-2 uk-position-center'
          }
        >
          <div
            className={classes.ukFlexCenter + ' uk-flex uk-flex-center'}
          ></div>
        </div>
        <div className={classes.ukGrid}>
          <div className="uk-child-width-1-2@m" uk-grid="true">
            <div>
              <div
                className={
                  classes.ukCardTopRadius +
                  ' ' +
                  classes.ukCardBottomRadius +
                  ' uk-card uk-card-default'
                }
              >
                <Link className={' uk-card uk-link-reset'} to="/mypage/draft">
                  <div
                    className={classes.ukCardTopRadius + ' uk-card-media-top'}
                  >
                    <img
                      className={classes.ukCardTopRadius}
                      src={draftImage}
                      alt=""
                    />
                  </div>
                  <div className="uk-card-body">
                    <h3 className="uk-card-title">下書き</h3>
                    <p className="uk-margin-small-top">
                      保存した下書きを確認・編集することができます
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div>
              <div
                className={
                  classes.ukCardTopRadius +
                  ' ' +
                  classes.ukCardBottomRadius +
                  ' uk-card uk-card-default'
                }
              >
                <div
                  className={' uk-card uk-link-reset'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (plan !== 'free') {
                      history.push('/mypage/myfonts');
                    } else {
                      toggleModal(true);
                    }
                  }}
                >
                  <div
                    className={classes.ukCardTopRadius + ' uk-card-media-top'}
                  >
                    <img
                      className={classes.ukCardTopRadius}
                      src={myFont}
                      alt=""
                    />
                  </div>
                  <div className="uk-card-body">
                    <h3 className="uk-card-title">マイフォント</h3>
                    <p className="uk-margin-small-top">
                      あなた専用のフォントを作成しましょう
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className={
                  classes.ukCardTopRadius +
                  ' ' +
                  classes.ukCardBottomRadius +
                  ' uk-card uk-card-default'
                }
              >
                <Link
                  className={' uk-card uk-link-reset'}
                  to="/mypage/completed"
                >
                  <div
                    className={classes.ukCardTopRadius + ' uk-card-media-top'}
                  >
                    <img
                      className={classes.ukCardTopRadius}
                      src={completedLetter}
                      alt=""
                    />
                  </div>
                  <div className="uk-card-body">
                    <h3 className="uk-card-title">送った手紙</h3>
                    <p>これまでに送った手紙を確認することができます</p>
                  </div>
                </Link>
              </div>
            </div>
            <div>
              <div
                className={
                  classes.ukCardTopRadius +
                  ' ' +
                  classes.ukCardBottomRadius +
                  ' uk-card uk-card-default'
                }
              >
                <Link
                  className={' uk-card uk-link-reset'}
                  to="/mypage/received"
                >
                  <div
                    className={classes.ukCardTopRadius + ' uk-card-media-top'}
                  >
                    <img
                      className={classes.ukCardTopRadius}
                      src={recieveLetter}
                      alt=""
                    />
                  </div>
                  <div className="uk-card-body">
                    <h3 className="uk-card-title">受け取った手紙</h3>
                    <p>これまでに受け取った手紙を確認することができます</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
