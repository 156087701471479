import firebase from '../config/firebase';
import 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import format from 'date-fns/format';
import { uploadAttachedImages } from './StorageUtils';

// generate random string for password
const genPassword = () => {
  const length = 20; // password length
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789-_!';
  const baseLength: number = chars.length;
  let password = '';

  for (let i = 0; i < length; i++) {
    password += chars[Math.floor(Math.random() * baseLength)];
  }
  return password;
};
// generate random string for password
const genUUID = () => {
  const uuid: string = uuidv4().replace(/-/g, '');
  const length = 10; // password length
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_';
  const baseLength: number = chars.length;
  let password = '';

  for (let i = 0; i < length; i++) {
    password += chars[Math.floor(Math.random() * baseLength)];
  }
  return uuid + password;
};

const filterAttachedImages = (
  elements: {
    id: string;
    className: string;
    src: string;
    top: string;
    left: string;
    transform: string;
  }[],
) => {
  // data: base64 string
  // idetifier: set-image'n'
  const attachedImages: { data: string; identifier: string }[] = [];
  let size = 0;

  const modElements = elements.map((element) => {
    // When it's only image
    if (element.className.includes('set-image')) {
      attachedImages.push({
        data: element.src,
        identifier: element.className.split(' ')[0],
      });
      // 画像のサイズを集計
      size = size + element.src.length;
      // remove src attribute
      element.src = '';
    }
    return element;
  });

  return { modElements, attachedImages, size };
};

const createLetterDocument = (
  uid: string,
  username: string,
  elements: {
    id: string;
    className: string;
    src: string;
    top: string;
    left: string;
    transform: string;
  }[],
  text: string,
  activeFontFamily: string,
  status: string,
) => {
  const db = firebase.firestore();
  const lettersRef = db.collection('lightLetters').doc(uid);

  const formattedDate = firebase.firestore.Timestamp.now();

  const password = genPassword();
  const uuid = genUUID();
  const documentId = genUUID();

  const { modElements, attachedImages, size } = filterAttachedImages(elements);

  // 添付画像の合計サイズが5 MB以上のとき処理を実施しない。
  if (size > 5 * 1024 * 1024) return;

  const documentData = {
    createdDate: formattedDate,
    creator: username,
    elements: modElements,
    envelopeImageName: localStorage.getItem('selectedEnvelopeName') || '',
    fontFamily: activeFontFamily,
    letterBackgroundImageName: localStorage.getItem('selectedLetterName') || '',
    password,
    receiver: [],
    status,
    text,
    uid,
    uuid,
    updatedDate: formattedDate,
  };

  return lettersRef
    .collection('letters')
    .doc(documentId)
    .set({
      createdDate: documentData.createdDate,
      creator: documentData.creator,
      elements: documentData.elements,
      envelopeImageName: documentData.envelopeImageName,
      fontFamily: documentData.fontFamily,
      letterBackgroundImageName: documentData.letterBackgroundImageName,
      password: documentData.password,
      receiver: documentData.receiver,
      status: documentData.status,
      text: documentData.text,
      uid: documentData.uid,
      uuid: documentData.uuid,
      updatedDate: documentData.updatedDate,
    })
    .then(async () => {
      const url = `https://lightletter.app/letter?id=${documentData.uuid}&q=${documentData.password}`;
      const utcDate = formattedDate
        .toDate()
        .toLocaleString('en-US', { timeZone: 'UTC' });

      /* 添付画像をstorage に保管*/
      await uploadAttachedImages(uid, documentId, attachedImages, size);

      localStorage.setItem('url', url);
      localStorage.setItem('documentId', documentId);
      localStorage.setItem(
        'createdDate',
        format(Date.parse(utcDate), 'yyyy-MM-dd-HH:mm:ss'),
      );
    })
    .catch((error) => {
      console.error('Error writing document: ', error);
    });
};

export default createLetterDocument;
