import { useContext } from 'react';
import { PlanContext } from '../../PlanProvider';
import classes from './Toolbar.module.css';

// スタンプ
import redCherry from '../../assets/stamps/red_cherry.svg';
import orangeCloud from '../../assets/stamps/orange_cloud.svg';
import blueFlower from '../../assets/stamps/blue_flower.svg';
import pinkRibon from '../../assets/stamps/pink_ribon.svg';
import pinkFriedegg from '../../assets/stamps/pink_friedegg.svg';
import smileyFace from '../../assets/stamps/smiley_face.svg';
import grinningFace from '../../assets/stamps/grinning_face.svg';
import excitedFace from '../../assets/stamps/excited_face.svg';
import cuteFace from '../../assets/stamps/cute_face.svg';
import cryingFace from '../../assets/stamps/crying_face.svg';
import sadFace from '../../assets/stamps/sad_face.svg';
import writingBalloon from '../../assets/stamps/writing_stamp/Stamp_balloon.svg';
import writingClover from '../../assets/stamps/writing_stamp/Stamp_clover.svg';
import writingExclamation from '../../assets/stamps/writing_stamp/Stamp_exclamation.svg';
import writingFlower from '../../assets/stamps/writing_stamp/Stamp_flower.svg';
import writingGinkgo from '../../assets/stamps/writing_stamp/Stamp_ginkgo.svg';
import writingHeart from '../../assets/stamps/writing_stamp/Stamp_heart.svg';
import writingKirakira from '../../assets/stamps/writing_stamp/Stamp_kirakira.svg';
import writingMacaroon from '../../assets/stamps/writing_stamp/Stamp_macaroon.svg';
import writingMaple from '../../assets/stamps/writing_stamp/Stamp_maple.svg';
import writingNote1 from '../../assets/stamps/writing_stamp/Stamp_note_1.svg';
import writingNote2 from '../../assets/stamps/writing_stamp/Stamp_note_2.svg';
import writingQuestion from '../../assets/stamps/writing_stamp/Stamp_question.svg';
import writingShootingstar from '../../assets/stamps/writing_stamp/Stamp_shootingstar.svg';
import writingSnowman from '../../assets/stamps/writing_stamp/Stamp_snowman.svg';
import writingSprout from '../../assets/stamps/writing_stamp/Stamp_sprout.svg';
import writingStar from '../../assets/stamps/writing_stamp/Stamp_star.svg';
import writingStrawberry from '../../assets/stamps/writing_stamp/Stamp_Strawberry.svg';
import writingTulip from '../../assets/stamps/writing_stamp/Stamp_tulip.svg';
import writingWatermellon from '../../assets/stamps/writing_stamp/Stamp_watermellon.svg';

type Props = {
  columnNum: number;
  onImportStamp: (importStamp: string) => void;
};

const DropdownStamps: React.FC<Props> = ({ columnNum, onImportStamp }) => {
  const { plan } = useContext(PlanContext);

  interface stamp {
    id: number;
    image: string;
  }

  const stamps: stamp[] = [
    { id: 0, image: smileyFace },
    { id: 1, image: redCherry },
    { id: 2, image: writingStrawberry },
  ];

  if (plan === 'premium') {
    // for Premium
    // Premium用のスタンプを新規で追加する場合、idを100以上に指定すること
    stamps.push(
      { id: 101, image: grinningFace },
      { id: 102, image: excitedFace },
      { id: 103, image: cuteFace },
      { id: 104, image: cryingFace },
      { id: 105, image: sadFace },
      { id: 106, image: blueFlower },
      { id: 107, image: orangeCloud },
      { id: 108, image: pinkRibon },
      { id: 109, image: pinkFriedegg },
      { id: 111, image: writingBalloon },
      { id: 112, image: writingClover },
      { id: 113, image: writingExclamation },
      { id: 114, image: writingFlower },
      { id: 115, image: writingGinkgo },
      { id: 116, image: writingHeart },
      { id: 117, image: writingKirakira },
      { id: 118, image: writingMacaroon },
      { id: 119, image: writingMaple },
      { id: 120, image: writingNote1 },
      { id: 121, image: writingNote2 },
      { id: 122, image: writingQuestion },
      { id: 123, image: writingShootingstar },
      { id: 124, image: writingSnowman },
      { id: 125, image: writingSprout },
      { id: 126, image: writingStar },
      { id: 127, image: writingTulip },
      { id: 128, image: writingWatermellon },
    );
  }
  return (
    <div>
      <ul className="uk-nav uk-dropdown-nav">
        {stamps.map((stamp) => {
          if (stamp.id % 3 === columnNum) {
            return (
              <li key={stamp.id}>
                <div className="stamp-img">
                  <button
                    className={[classes.dropButton].join(' ')}
                    onClick={() => {
                      onImportStamp(stamp.image);
                    }}
                  >
                    <img
                      src={stamp.image}
                      width=""
                      height=""
                      alt=""
                      uk-img="true"
                      className="stampImage"
                    />
                  </button>
                </div>
              </li>
            );
          } else {
            return null;
          }
        })}
      </ul>
    </div>
  );
};

export default DropdownStamps;
