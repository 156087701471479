import { useEffect, useContext } from 'react';
import firebase from '../config/firebase';
import classes from './TopView.module.css';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import LightLetterNameGray from '../assets/imgs/lightletter-name-gray.svg';
import { useHistory } from 'react-router-dom';
import { FontContext } from '../FontProvider';
import topBackgroundTitle from '../assets/imgs/top-background-title.jpg';
import topBackgroundCircles from '../assets/imgs/top-background-circles.svg';
import myfontExample from '../assets/imgs/myfont-example.svg';
import step1Image from '../assets/imgs/step1-image.png';
import step2Image from '../assets/imgs/step2-image.png';
import step3Image from '../assets/imgs/step3-image.png';

const TopView = () => {
  const history = useHistory();

  const { myFontExists, setMyFontExists } = useContext(FontContext);

  useEffect(() => {
    if (setMyFontExists === undefined) return;
    const logout = async () => {
      await firebase.auth().signOut();
    };
    // 右上のLogout ボタンでTopに戻ってきた際にログアウトする処理
    if (history.location.state === 'logout') {
      // clear MyFont when user logs out
      if (myFontExists) {
        document.fonts.clear();
        setMyFontExists(false);
      }
      localStorage.removeItem('documentId');
      logout();
    }
  }, [history.location.state, myFontExists, setMyFontExists]);

  return (
    <div className={classes.topView + ' top_view'}>
      <div className="uk-position-top">
        <Header headerColor="#ffffffe6" />
      </div>
      <main>
        <div
          className={
            classes.ukPositionCenter + ' uk-column-1-2 uk-position-center'
          }
        >
          <div
            className={classes.ukFlexCenter + ' uk-flex uk-flex-center'}
          ></div>
        </div>
        <div
          className="uk-background-cover uk-height-large uk-panel uk-flex uk-flex-center uk-child-width-1-2@s"
          style={{
            height: '800px',
            backgroundImage: 'url(' + topBackgroundTitle + ')',
          }}
        >
          <div className={classes.ukCard + ' uk-position-center'}>
            <p className={classes.ukH6 + ' uk-h6'}>ライト・レター</p>
            <p>
              <img
                className={classes.lightletterNameGray}
                src={LightLetterNameGray}
                alt=""
              />
            </p>
            <p className={classes.ukH4 + ' uk-h4'}>
              大切な人に伝えたい あの言葉。
              <br />
              今、手紙で伝えませんか？
            </p>
            <div>
              <button
                id="writeLetterButton"
                onClick={() => {
                  localStorage.clear();
                  history.push('/designs');
                }}
                className={
                  classes.ukButtonPrimary +
                  ' uk-margin uk-button uk-button-primary'
                }
              >
                手紙を書く
              </button>
            </div>
          </div>
        </div>
        <div
          className="uk-text-center uk-margin-top uk-margin-large-bottom uk-padding"
          uk-grid="true"
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(' + topBackgroundCircles + ')',
          }}
        >
          <div
            className="uk-width-1-2"
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
          >
            <p className={classes.divEng + ' uk-h2'}>
              anytime, anywhere
              <br />
              write a special letter.
            </p>
          </div>
          <div className="uk-width-1-2">
            <p className={classes.divBold + ' uk-h4'}>
              いつでも、どこでも、
              <br />
              あなたの気持ちを手紙に綴るための
              <br />
              <strong className={classes.divBoldOrange}>
                「電子手紙サービス」
              </strong>
            </p>
            <p className={classes.divNormal + ' uk-h5'}>
              LightLetter
              は、今あなたが誰かに伝えたいことを、電子手紙として届けることができるサービスです。
              封筒と手紙をひらく動きや手書き風の文字などを用いて、本物の手紙のようなあたたかさを残しながら、
              いつでも「手紙」というかたちで気持ちを伝え合う機会をお届けできることを目指しています。
            </p>
          </div>
        </div>
        <div
          className="uk-text-center uk-margin-top uk-margin-large-bottom uk-padding"
          uk-grid="true"
        >
          <div className="uk-width-1-2">
            <p className={classes.divBold + ' uk-h4'}>
              あなただけの手書き文字を作成する、
              <br />
              <strong className={classes.divBoldOrange}>
                「マイフォント機能」
              </strong>
              を鋭意開発中！
              <br />
            </p>
            <p className={classes.divNormal + ' uk-h5'}>
              手書きのノートやメモの写真をアップロードして頂くことで、 PC
              やスマートフォンで入力した文字を、手書きで書いたような見た目に変えることができます。
              <br />
              現時点では英数字にしか対応しておらず、手書き文字の再現度にも課題が残るため、開発者一同、機能の改善に力を入れております。
            </p>
          </div>
          <div className="uk-width-1-2">
            <img src={myfontExample} width="650px" alt="" />
          </div>
        </div>
        <div className={classes.ukGrid}>
          <p className={classes.divEngBorderTop + ' uk-h2'}>
            How to use LightLetter
          </p>
          <div
            className="uk-grid-match uk-child-width-auto uk-child-width-1-3@m"
            uk-grid="true"
          >
            <div style={{ margin: '0 auto' }}>
              <div
                className={
                  classes.ukCardTopRadius +
                  ' ' +
                  classes.ukCardBottomRadius +
                  ' uk-card uk-card-default'
                }
              >
                <div className={classes.ukCardTopRadius + ' uk-card-media-top'}>
                  <img
                    className={classes.ukCardTopRadius}
                    src={step1Image}
                    width="650px"
                    alt=""
                  />
                </div>
                <div className="uk-card-body">
                  <h4>STEP 1</h4>
                  <p>封筒と便箋のデザインを選択</p>
                  <p>
                    複数のカテゴリの中から好きなデザインの封筒と便箋を見つけましょう。
                  </p>
                </div>
              </div>
            </div>
            <div style={{ margin: '0 auto' }}>
              <div
                className={
                  classes.ukCardTopRadius +
                  ' ' +
                  classes.ukCardBottomRadius +
                  ' uk-card uk-card-default'
                }
              >
                <div className={classes.ukCardTopRadius + ' uk-card-media-top'}>
                  <img
                    className={classes.ukCardTopRadius}
                    src={step2Image}
                    width="650px"
                    alt=""
                  />
                </div>
                <div className="uk-card-body">
                  <h4>STEP 2</h4>
                  <p>便箋に文字を入力</p>
                  <p>
                    スタンプや写真等も使って、あなたが手紙を送る方に伝えたいことを綴りましょう。
                  </p>
                </div>
              </div>
            </div>
            <div style={{ margin: '0 auto' }}>
              <div
                className={
                  classes.ukCardTopRadius +
                  ' ' +
                  classes.ukCardBottomRadius +
                  ' uk-card uk-card-default'
                }
              >
                <div className={classes.ukCardTopRadius + ' uk-card-media-top'}>
                  <img
                    className={classes.ukCardTopRadius}
                    src={step3Image}
                    width="650px"
                    alt=""
                  />
                </div>
                <div className="uk-card-body">
                  <h4>STEP 3</h4>
                  <p>手紙のリンクを送信</p>
                  <p>
                    メールや SNS
                    で、作成した手紙・アニメーションのリンクを送りましょう。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default TopView;
