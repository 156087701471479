import classes from '../../views/EditView.module.css';

type Props = {
  menuPoint: { x: number; y: number };
  toggleContextMenu: (flag: boolean) => void;
  removeElement: () => void;
  resizeElement: (action: string) => void;
};

const CustomContextMenu: React.FC<Props> = ({
  menuPoint,
  toggleContextMenu,
  removeElement,
  resizeElement,
}) => {
  const closeContextMenu = () => {
    toggleContextMenu(false);
    document.removeEventListener('click', closeContextMenu);
  };

  return (
    <div
      className={classes.contextmenu}
      style={{ top: menuPoint.y, left: menuPoint.x }}
      onMouseLeave={() => {
        document.addEventListener('click', closeContextMenu);
      }}
    >
      <ul className={classes.ul}>
        <div
          className={
            'uk-align-center uk-margin-remove-top uk-margin-remove-bottom ' +
            classes.menuitem
          }
        >
          <li
            className={classes.litext}
            onClick={() => {
              resizeElement('plus');
            }}
            uk-icon="plus"
            // style={{width: 'calc(100% / 3)'}}
          ></li>
          <li className={classes.litext}>拡大</li>
        </div>
        <div
          className={
            'uk-align-center uk-margin-remove-top uk-margin-remove-bottom ' +
            classes.menuitem
          }
        >
          <li
            className={classes.litext}
            onClick={() => {
              resizeElement('minus');
            }}
            uk-icon="minus"
            // style={{width: 'calc(100% / 3)'}}
          ></li>
          <li className={classes.litext}>縮小</li>
        </div>
        <div
          onClick={() => {
            toggleContextMenu(false);
            removeElement();
          }}
          className={classes.menuitem}
        >
          <li className={classes.liimg} uk-icon="trash"></li>
          <li className={classes.litext}>削除</li>
        </div>
      </ul>
    </div>
  );
};

export default CustomContextMenu;
