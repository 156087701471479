import { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Header from '../components/common/Header';
import SideBar from '../components/mypage/Sidebar';
import ResponsibleSideBar from '../components/mypage/ResponsibleSideBar';
import Home from '../components/mypage/Home';
import Draft from '../components/mypage/Draft';
import Completed from '../components/mypage/Completed';
import MyFonts from '../components/mypage/MyFonts';
import Received from '../components/mypage/Received';
import classes from './Mypage.module.css';
import Setting from '../components/mypage/SettingView';
import toggleSideBar from '../components/mypage/toggleSideBar';

const Mypage = (props: RouteComponentProps) => {
  const history = useHistory();
  const [shownPlanChangeModal, setShownPlanChangeModal] =
    useState<boolean>(false);

  return (
    <div className={'setting ' + classes.setting} id="setting">
      <Header
        customHeaderMenu={
          // 幅が960px 以下の場合に表示する
          <li className="uk-hidden@m">
            <button
              className={classes.menuIcon + ' uk-navber-item uk-button'}
              type="button"
              uk-icon="menu"
              onClick={() => {
                // メニュークリック時の処理
                // class にopend を付与し、サイドバーっぽいアニメーションを表示
                // 既にopend が付与されている場合は、opend を除去することでサイドバーを閉じる
                toggleSideBar();
              }}
            />
          </li>
        }
      />
      <SideBar
        activeRoot={props.match.path}
        toggleModal={setShownPlanChangeModal}
      />
      <ResponsibleSideBar activeRoot={props.match.path} />
      <div id="mainContainer" className={classes.mainContainer}>
        <div className={classes.scrollContainer}>
          {props.match.path === '/mypage' && (
            <Home toggleModal={setShownPlanChangeModal} />
          )}
          {props.match.path === '/mypage/home' && (
            <Home toggleModal={setShownPlanChangeModal} />
          )}
          {props.match.path === '/mypage/settings' && <Setting />}
          {props.match.path === '/mypage/draft' && <Draft />}
          {props.match.path === '/mypage/completed' && <Completed />}
          {props.match.path === '/mypage/myfonts' && <MyFonts />}
          {props.match.path === '/mypage/received' && <Received />}
        </div>
      </div>
      {shownPlanChangeModal && (
        <div
          className="uk-position-cover uk-overlay uk-overlay-primary uk-animation-fade uk-animation-fast"
          style={{
            marginTop: '80px',
            marginBottom: '0px',
            background: 'rgba(0, 0, 0, .32)',
            zIndex: 1000,
            position: 'fixed',
          }}
          onClick={() => {
            setShownPlanChangeModal(false);
          }}
        >
          <div
            className="uk-position-center uk-position-middle uk-overlay-default uk-animation-fade uk-animation-fast"
            style={{
              width: '600px',
              height: '300px',
              background: 'rgba(255, 255, 255, 1)',
              top: '30%',
              borderRadius: '5px',
            }}
          >
            <div className="uk-animation-fade uk-animation-fast uk-modal-dialog-small">
              <div style={{ width: '100%', paddingTop: '40px' }}>
                <div>
                  <p
                    className="uk-position-top-left"
                    style={{ color: '#000', margin: '20px' }}
                  >
                    マイフォント有効化
                  </p>
                  <button
                    className="uk-modal-close-default"
                    type="button"
                    uk-close="true"
                    style={{ color: '#999' }}
                    onClick={() => {
                      setShownPlanChangeModal(false);
                    }}
                  ></button>
                  <div
                    className="uk-alert uk-alert-warning"
                    uk-alert="true"
                    style={{
                      marginLeft: '20px',
                      marginRight: '20px',
                      borderRadius: '5px',
                    }}
                  >
                    <p>
                      <span
                        className="uk-icon uk-margin-small-right"
                        uk-icon="icon: info"
                      />
                      マイフォントはスタンダード・プレミアムプラン向けの機能です。
                    </p>
                  </div>
                  <div
                    className="uk-grid uk-position-bottom-right uk-width-auto"
                    style={{ margin: '20px' }}
                  >
                    <button
                      className="uk-button "
                      type="button"
                      style={{
                        backgroundColor: 'rgb(239, 239, 239)',
                        borderRadius: '5px',
                        color: 'rgba(59, 59, 59, 1)',
                      }}
                    >
                      キャンセル
                    </button>
                    <div
                      className="uk-padding-remove"
                      style={{ width: '5px' }}
                    ></div>
                    <button
                      className={classes.ukButtonPrimary + ' uk-button'}
                      type="button"
                      style={{ borderRadius: '5px', marginTop: '0px' }}
                      onClick={() => {
                        history.push('/payment/plan');
                      }}
                    >
                      プラン確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Mypage;
