import classes from './Sidebar.module.css';
import classesmy from '../../views/Mypage.module.css';

const toggleSideBar = () => {
  const sidebar = document.getElementById('responsibleSideBar');
  const mainContainer = document.getElementById('mainContainer');
  const shadow = document.getElementById('shadowOverlay');

  const hideShadow = () => {
    if (shadow) {
      shadow.style.display = 'none';
      shadow.removeEventListener('transitionend', hideShadow);
    }
  };

  if (sidebar && mainContainer && shadow) {
    if (sidebar.classList.contains(classes.opened)) {
      sidebar.classList.remove(classes.opened);
      shadow.addEventListener('transitionend', hideShadow);
      shadow.classList.remove(classes.opened);
      mainContainer.classList.remove(classesmy.opened);
      window.document.body.classList.remove(classesmy.opened);
    } else {
      if (shadow.style.display === 'block') return;
      sidebar.classList.add(classes.opened);
      shadow.style.display = 'block';
      shadow.classList.add(classes.opened);
      mainContainer.classList.add(classesmy.opened);
      window.document.body.classList.add(classesmy.opened);
    }
  }
};

export default toggleSideBar;
