import AppRouter from './AppRouter';
import { AuthProvider } from './AuthProvider';
import { FontProvider } from './FontProvider';
import { PlanProvider } from './PlanProvider';

const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <PlanProvider>
          <FontProvider>
            <AppRouter />
          </FontProvider>
        </PlanProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
