import { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classes from './Sidebar.module.css';
import toggleSideBar from './toggleSideBar';
import { PlanContext } from '../../PlanProvider';
import { mainMenus, letterMenus, fontMenus } from './Menus';

const ResponsibleSideBar: React.FC<{ activeRoot: string }> = ({
  activeRoot,
}) => {
  const history = useHistory();
  const { plan } = useContext(PlanContext);
  const [shownPlanChangeModal, setShownPlanChangeModal] =
    useState<boolean>(false);
  const root: string = activeRoot === '/mypage' ? 'home' : activeRoot;

  return (
    <>
      <div className={classes.responsibleSideBar} id="responsibleSideBar">
        <ul className="uk-nav uk-nav-default">
          {mainMenus.map((menu, index) => (
            <li
              key={index}
              className={`${menu.path.includes(root) ? classes.active : ''} ${
                classes.menu
              }`}
            >
              <Link
                className={
                  menu.path.includes(root) ? classes.activeText : classes.text
                }
                to={menu.path}
                onClick={() => {
                  toggleSideBar();
                }}
              >
                <div
                  className={`${
                    menu.path.includes(root) ? classes.activeIcon : classes.icon
                  }`}
                  uk-icon={`icon:${menu.icon}`}
                ></div>
                {menu.text}
              </Link>
            </li>
          ))}
          <li className={classes.ukNavDivider + ' uk-nav-divider'}></li>
          {letterMenus.map((menu, index) => (
            <li
              key={index}
              className={`${menu.path.includes(root) ? classes.active : ''} ${
                classes.menu
              }`}
            >
              <Link
                className={
                  menu.path.includes(root) ? classes.activeText : classes.text
                }
                to={menu.path}
                onClick={() => {
                  toggleSideBar();
                }}
              >
                <div
                  className={`${
                    menu.path.includes(root) ? classes.activeIcon : classes.icon
                  }`}
                  uk-icon={`icon:${menu.icon}`}
                ></div>
                {menu.text}
              </Link>
            </li>
          ))}
          <li className={classes.ukNavDivider + ' uk-nav-divider'}></li>
          {/* fontMenus は free accountの場合は無効化する */}
          {fontMenus.map((menu, index) => (
            <li
              key={index}
              className={[
                `${menu.path.includes(root) ? classes.active : ''}`,
                `${plan === 'free' ? classes.disableItem : ''}`,
                `${classes.menu}`,
              ].join(' ')}
            >
              <div
                className={[
                  `${
                    menu.path.includes(root) ? classes.activeText : classes.text
                  }`,
                  // `${plan === 'free' ? 'uk-disabled' : ''}`,
                ].join(' ')}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (plan !== 'free') {
                    history.push(menu.path);
                    toggleSideBar();
                  } else {
                    setShownPlanChangeModal(true);
                  }
                }}
              >
                <div
                  className={[
                    `${
                      menu.path.includes(root)
                        ? classes.activeIcon
                        : classes.icon
                    }`,
                  ].join(' ')}
                  uk-icon={`icon:${menu.icon}`}
                ></div>
                {menu.text}
                {
                  // free のユーザにのみ表示する
                  plan === 'free' && (
                    <span
                      className={[
                        `${
                          menu.path.includes(root)
                            ? classes.activeKeyIcon
                            : classes.keyIcon
                        }`,
                        'uk-badge',
                        'uk-position-small',
                        'uk-position-center-right',
                      ].join(' ')}
                      style={{
                        backgroundColor: 'rgb(255 91 91)',
                        color: 'rgb(255, 255, 255)',
                      }}
                    >
                      S
                    </span>
                  )
                }
              </div>
            </li>
          ))}
          <li>
            <div
              className={
                classes.ukDivButtonPrimary + ' uk-position-bottom-center'
              }
            >
              <button
                onClick={() => {
                  localStorage.removeItem('createdDate');
                  localStorage.removeItem('documentId');
                  localStorage.removeItem('letter');
                  localStorage.removeItem('receivedLetter');
                  localStorage.removeItem('selectedEnvelopeName');
                  localStorage.removeItem('selectedLetterName');
                  localStorage.removeItem('url');
                  toggleSideBar();
                  history.push('/designs');
                }}
                className={
                  classes.ukButtonPrimary +
                  ' uk-margin uk-button uk-button-primary'
                }
              >
                手紙を書く
              </button>
            </div>
          </li>
        </ul>
      </div>
      {shownPlanChangeModal && (
        <div
          className="uk-position-cover uk-overlay uk-overlay-primary uk-animation-fade uk-animation-fast"
          style={{
            marginTop: '80px',
            marginBottom: '0px',
            background: 'rgba(0, 0, 0, .32)',
            zIndex: 1000,
          }}
          onClick={() => {
            setShownPlanChangeModal(false);
          }}
        >
          <div
            className="uk-position-center uk-position-middle uk-overlay-default uk-animation-fade uk-animation-fast"
            style={{
              width: '600px',
              height: '300px',
              background: 'rgba(255, 255, 255, 1)',
              top: '30%',
              borderRadius: '5px',
            }}
          >
            <div className="uk-animation-fade uk-animation-fast uk-modal-dialog-small">
              <div style={{ width: '100%', paddingTop: '40px' }}>
                <div>
                  <p
                    className="uk-position-top-left"
                    style={{ color: '#000', margin: '20px' }}
                  >
                    マイフォント有効化
                  </p>
                  <button
                    className="uk-modal-close-default"
                    type="button"
                    uk-close="true"
                    style={{ color: '#999' }}
                    onClick={() => {
                      setShownPlanChangeModal(false);
                    }}
                  ></button>
                  <div
                    className="uk-alert uk-alert-warning"
                    uk-alert="true"
                    style={{
                      marginLeft: '20px',
                      marginRight: '20px',
                      borderRadius: '5px',
                    }}
                  >
                    <p>
                      <span
                        className="uk-icon uk-margin-small-right"
                        uk-icon="icon: info"
                      />
                      マイフォントはスタンダード・プレミアムプラン向けの機能です。
                    </p>
                  </div>
                  <div
                    className="uk-grid uk-position-bottom-right uk-width-auto"
                    style={{ margin: '20px' }}
                  >
                    <button
                      className="uk-button "
                      type="button"
                      style={{
                        backgroundColor: 'rgb(239, 239, 239)',
                        borderRadius: '5px',
                        color: 'rgba(59, 59, 59, 1)',
                      }}
                    >
                      キャンセル
                    </button>
                    <div
                      className="uk-padding-remove"
                      style={{ width: '5px' }}
                    ></div>
                    <button
                      className={classes.ukButtonPrimary + ' uk-button'}
                      type="button"
                      style={{ borderRadius: '5px', marginTop: '0px' }}
                      onClick={() => {
                        history.push('/payment/plan');
                      }}
                    >
                      プラン確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        <div
          id="shadowOverlay"
          className={
            'uk-position-cover uk-overlay uk-overlay-primary ' + classes.shadow
          }
          style={{ marginTop: '80px', minHeight: '100vh', zIndex: 450 }}
          onClick={() => {
            toggleSideBar();
          }}
        ></div>
      }
    </>
  );
};

export default ResponsibleSideBar;
