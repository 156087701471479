import { useState, useEffect, useContext, useReducer } from 'react';
import classes from './Draft.module.css';
import LetterContainer from './LetterContainer';
import fetchUserLetters from '../../lib//FetchUserLetters';
import { AuthContext } from '../../AuthProvider';
import LetterDocument from '../../lib/InterfaceOfLetterDocument';
import countDraftLetters from '../../lib/CountDraftLetters';
import deleteLetterDocument from '../../lib/DeleteLetterDocument';

const Draft = () => {
  const { user } = useContext(AuthContext);
  const initialFetchStatus = false;
  const initialDelimiterPosition: string[] = [];

  const toggleFetchState = (state: boolean, action: { type: string }) => {
    switch (action.type) {
      case 'fetched':
        state = true;
        return state;
      case 'reset':
        return initialFetchStatus;
      default:
        throw new Error();
    }
  };

  const delimiterPositionReducer = (
    state: string[],
    value: { id: number; position: string; action: string },
  ) => {
    switch (value.action) {
      case 'count': {
        if (value.id !== 0) {
          state[value.id] = value.position;
        }
        return state;
      }
      case 'reset': {
        return initialDelimiterPosition;
      }
      default:
        throw new Error();
    }
  };

  const [isFetched, setFetchState] = useReducer(
    toggleFetchState,
    initialFetchStatus,
  );
  const [delimiterPositions, setDelimiterPositions] = useReducer(
    delimiterPositionReducer,
    initialDelimiterPosition,
  );

  const [numberOfDraft, setNumberOfDraft] = useState<number>(0);
  const [data, setData] = useState<{ [id: string]: LetterDocument }>();
  const [page, setPage] = useState<number>(0);
  // const [orderField, setOrderField] = useState<string>("updatedDate");
  const orderField = 'updatedDate';

  const title = { value: 'draft', text: '下書き' };

  useEffect(() => {
    const fetchUserLettersData = async () => {
      const uid = user ? user.uid : '';
      try {
        const letters = await fetchUserLetters(
          uid,
          orderField,
          'draft',
          delimiterPositions[page],
        );
        if (letters !== undefined && letters !== null) {
          setFetchState({ type: 'fetched' }); // set true
          setData(letters.collection);
          setDelimiterPositions({
            id: page + 1,
            position: letters.delimiterPosition,
            action: 'count',
          });
        } else {
          setFetchState({ type: 'fetched' }); // set true
          console.log('there is not documents');
        }
      } catch (error) {
        console.error(error);
      }
    };
    const countDraftLettersData = async () => {
      const uid = user ? user.uid : '';
      try {
        const number = await countDraftLetters(uid, 'draft');
        if (number) {
          setNumberOfDraft(number);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (!isFetched) {
      fetchUserLettersData();
      countDraftLettersData();
    }
  }, [user, page, orderField, delimiterPositions, isFetched]);

  return (
    <>
      {isFetched ? (
        data ? (
          <div className={classes.draft}>
            {numberOfDraft < 10 ? (
              <div
                className={classes.ukAlert + ' uk-alert-warning'}
                uk-alert="alert"
              >
                <p>
                  {'使用状況： ' + numberOfDraft + ' 通'}
                  <br />
                  {'下書きを最大 10 通まで保存することができます。'}
                  <br />
                </p>
                <progress
                  id="js-progressbar"
                  className={
                    classes.ukProgress + ' uk-progress uk-margin-small-top'
                  }
                  value={numberOfDraft}
                  max="10"
                  uk-progress="true"
                ></progress>
              </div>
            ) : (
              <div
                className={classes.ukAlert + ' uk-alert-danger'}
                uk-alert="true"
              >
                <p>
                  {'使用状況： ' + numberOfDraft + ' 通'}
                  <br />
                  {
                    'これ以上は下書きを保存することができません。不要な下書きを削除してください。'
                  }
                  <br />
                </p>
                <progress
                  id="js-progressbar"
                  className={classes.ukProgressDanger + ' uk-progress'}
                  value={numberOfDraft}
                  max="10"
                  uk-progress="true"
                ></progress>
              </div>
            )}
            <LetterContainer
              title={title}
              data={data}
              onDeleteDB={async (documentId: string) => {
                if (user) {
                  await deleteLetterDocument(user.uid, documentId);
                }
                setFetchState({ type: 'reset' });
                setDelimiterPositions({ id: 0, position: '', action: 'reset' });
                setPage(0);
                setData(undefined);
              }}
            />
          </div>
        ) : (
          <div className={classes.draft}>
            <h3 className={classes.ukH3}>下書き</h3>
            <div className="uk-grid-small uk-grid-match" uk-grid="true">
              手紙がありません
            </div>
          </div>
        )
      ) : (
        <span
          className="uk-position-center"
          uk-spinner="true"
          style={{ color: 'orange' }}
        />
      )}
    </>
  );
};

export default Draft;
