import Header from '../components/common/Header';

const Terms = () => {
  return (
    <>
      <Header />
      <article className="uk-article uk-padding-small uk-margin uk-margin-left uk-margin-right">
        <h1 className="uk-article-title">利用規約</h1>
        <p className="uk-article-meta">
          Written by Softech on May 22nd, 2021.{' '}
        </p>
        <p>
          この利用規約（以下、「本規約」といいます。）は、LightLetter
          開発チーム（以下、「我々」という。）がウェブサイト上で提供する「LightLetter」（以下、「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さまには、本規約に従って、本サービスをご利用いただきます。
        </p>

        <p className="uk-text-lead">第1条（適用）</p>
        <ul className="uk-list uk-list-decimal">
          <li>
            本規約は、ユーザーと我々との間の本サービスの利用に関わる一切の関係に適用されるものとします。
          </li>
          <li>
            我々は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
          </li>
          <li>
            本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
          </li>
        </ul>

        <p className="uk-text-lead">第2条（利用登録）</p>
        <ul className="uk-list uk-list-decimal">
          <li>
            本サービスにおいては、登録希望者が本規約に同意の上、我々の定める方法によって利用登録を申請し、我々がこれを承認することによって、利用登録が完了するものとします。
          </li>
          <li>
            我々は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
            <ul>
              <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
              <li>本規約に違反したことがある者からの申請である場合</li>
              <li>その他、我々が利用登録を相当でないと判断した場合</li>
            </ul>
          </li>
        </ul>

        <p className="uk-text-lead">
          第3条（ユーザーIDおよびパスワードの管理）
        </p>
        <ul className="uk-list uk-list-decimal">
          <li>
            ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
          </li>
          <li>
            ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。我々は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
          </li>
          <li>
            ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は、我々に故意又は重大な過失がある場合を除き、我々は一切の責任を負わないものとします。
          </li>
          <li>
            お客様は、アカウント、パスワードが第三者によって不正に使用されていることが判明した場合には、直ちに我々に連絡するものとします。
          </li>
        </ul>

        <p className="uk-text-lead">第4条（利用料金および支払方法）</p>
        <ul className="uk-list uk-list-decimal">
          <li>
            ユーザーは、本サービスの有料部分の対価として、我々が別途定め、本ウェブサイトに表示する利用料金を、我々が指定する方法により支払うものとします。
          </li>
          <li>
            ユーザーが利用料金の支払を遅滞した場合には、ユーザーは年14.6％の割合による遅延損害金を支払うものとします。
          </li>
        </ul>

        <p className="uk-text-lead">第5条（禁止事項）</p>
        <p>
          ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
        </p>
        <ul className="uk-list uk-list-decimal">
          <li>法令または公序良俗に違反する行為</li>
          <li>犯罪行為に関連する行為</li>
          <li>
            我々、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
          </li>
          <li>我々のサービスの運営を妨害するおそれのある行為</li>
          <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
          <li>不正アクセスをし、またはこれを試みる行為</li>
          <li>他のユーザーに成りすます行為</li>
          <li>
            我々のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
          </li>
          <li>
            我々，本サービスの他のユーザーまたは第三者の知的財産権，肖像権，プライバシー，名誉その他の権利または利益を侵害する行為
          </li>
          <li>
            以下の表現を含み，または含むと我々が判断する内容を本サービス上に投稿し，または送信する行為
            <ul>
              <li>過度に暴力的な表現</li>
              <li>露骨な性的表現</li>
              <li>
                人種，国籍，信条，性別，社会的身分，門地等による差別につながる表現
              </li>
              <li>自殺，自傷行為，薬物乱用を誘引または助長する表現</li>
              <li>その他反社会的な内容を含み他人に不快感を与える表現</li>
            </ul>
          </li>
          <li>
            本サービスを利用する目的が以下と我々が判断する行為
            <ul>
              <li>
                営業，宣伝，広告，勧誘，その他営利を目的とする行為（我々の認めたものを除きます。）
              </li>
              <li>性行為やわいせつな行為を目的とする行為</li>
              <li>面識のない異性との出会いや交際を目的とする行為</li>
              <li>他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為</li>
              <li>
                我々，本サービスの他のユーザー，または第三者に不利益，損害または不快感を与えることを目的とする行為
              </li>
              <li>
                その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
              </li>
            </ul>
          </li>
          <li>宗教活動または宗教団体への勧誘行為</li>
          <li>その他、我々が不適切と判断する行為</li>
        </ul>

        <p className="uk-text-lead">第6条（JavaScriptの使用）</p>
        <p>
          本サービスでは、JavaScriptを使用しているページがございます。お使いのブラウザでJavaScriptを無効にされている場合には、正しく機能しない、もしくは正しく表示されないことがございます。
          サービスをご利用頂くために、ブラウザの設定画面にてJavaScriptを有効にしてください。
        </p>

        <p className="uk-text-lead">第7条（本サービスの提供の停止等）</p>
        <ul className="uk-list uk-list-decimal">
          <li>
            我々は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
            <ul>
              <li>
                本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
              </li>
              <li>
                地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
              </li>
              <li>コンピュータまたは通信回線等が事故により停止した場合</li>
              <li>その他、我々が本サービスの提供が困難と判断した場合</li>
            </ul>
          </li>
          <li>
            我々は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
          </li>
        </ul>

        <p className="uk-text-lead">第8条（利用制限および登録抹消）</p>
        <ul className="uk-list uk-list-decimal">
          <li>
            我々は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
            <ul>
              <li>本規約のいずれかの条項に違反した場合</li>
              <li>登録事項に虚偽の事実があることが判明した場合</li>
              <li>
                決済手段として当該ユーザーが届け出たクレジットカードが利用停止となった場合
              </li>
              <li>料金等の支払債務の不履行があった場合</li>
              <li>その他、我々が本サービスの利用を適当でないと判断した場合</li>
            </ul>
          </li>
          <li>
            我々は、本条に基づき我々が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
          </li>
        </ul>

        <p className="uk-text-lead">第9条（アカウント削除）</p>
        <p>
          {/*  ユーザーは、我々の定めるアカウント削除手続により、本サービスから退会できるものとします。本サービスを退会した場合は、次回支払日までに発生する利用料は返金されず、退会したタイミングから本サービスを利用することができません。 */}
          ユーザーは、我々の定めるアカウント削除手続により、本サービスから退会できるものとします。本サービスを退会した場合は、退会当日までの日割料金となり、退会したタイミングから本サービスを利用することができません。
        </p>

        <p className="uk-text-lead">第10条（プラン変更）</p>
        <p>
          {/*  利用プランをダウングレードした場合（PremiumからFreeへのプラン変更等）は、次回支払日までに発生する利用料は返金されませんが、次回支払日までの期間は継続して元々登録頂いてたプランをご利用頂くことができます。 */}
          利用プランをダウングレードした場合（PremiumからFreeへのプラン変更等）は、変更日までの日割料金となり、変更したタイミングから元々登録頂いてたプランをご利用頂くことができなくなります。
        </p>

        <p className="uk-text-lead">第11条（保証の否認および免責事項）</p>
        <ul className="uk-list uk-list-decimal">
          <li>
            我々は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
          </li>
          <li>
            我々は、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する我々とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
          </li>
          <li>
            我々は、我々の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（我々またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、我々の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は、ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
          </li>
          <li>
            我々は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
          </li>
        </ul>

        <p className="uk-text-lead">第12条（サービス内容の変更等）</p>
        <p>
          我々は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
        </p>

        <p className="uk-text-lead">第13条（利用規約の変更）</p>
        <p>
          我々は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。
        </p>

        <p className="uk-text-lead">第14条（個人情報の取扱い）</p>
        <p>
          我々は、本サービスの利用によって取得する個人情報については、我々「プライバシーポリシー」に従い適切に取り扱うものとします。
        </p>

        <p className="uk-text-lead">第15条（通知または連絡）</p>
        <p>
          ユーザーと我々との間の通知または連絡は、我々の定める方法によって行うものとします。我々は、ユーザーから、我々が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。
        </p>

        <p className="uk-text-lead">第16条（権利義務の譲渡の禁止）</p>
        <p>
          ユーザーは、我々の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
        </p>

        <p className="uk-text-lead">第17条（準拠法・裁判管轄）</p>
        <p>
          本規約の解釈にあたっては、日本法を準拠法とします。本サービスに関して紛争が生じた場合には、我々の本店所在地を管轄する裁判所を専属的合意管轄とします。
        </p>
      </article>
    </>
  );
};
export default Terms;
