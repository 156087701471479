import {
  useState,
  useEffect,
  useContext,
  createContext,
  FC,
  useCallback,
} from 'react';
import firebase from './config/firebase';
import { AuthContext } from './AuthProvider';

type ContextProps = {
  plan: string;
  loadingPlanState: boolean;
  coupon: Coupon | null;
};

type Price = {
  // eslint-disable-next-line camelcase
  unit_amount: number;
};

const isPriceType = (price: unknown): price is Price => {
  // eslint-disable-next-line camelcase
  const { unit_amount } = price as Price;
  // eslint-disable-next-line camelcase
  if (typeof unit_amount === 'number') {
    return true;
  }
  return false;
};

type Customer = {
  items: {
    plan: {
      id: string;
    };
  }[];
};

const isCustomers = (customer: unknown): customer is Customer => {
  try {
    const { items } = customer as Customer;
    return typeof items[0].plan.id === 'string';
  } catch {
    return false;
  }
};

type Coupon = {
  uid: string | null;
  expiration: firebase.firestore.Timestamp | null;
  plan: 'standard' | 'premium';
  valid: boolean;
};

const isCoupon = (coupon: unknown): coupon is Coupon => {
  try {
    const { uid, expiration, plan, valid } = coupon as Coupon;
    if (
      (uid === null || typeof uid === 'string') &&
      (expiration === null || expiration.toDate() instanceof Date) &&
      (plan === 'premium' || plan === 'standard') &&
      typeof valid === 'boolean'
    ) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

export const PlanContext = createContext<Partial<ContextProps>>({});

export const PlanProvider: FC = ({ children }): JSX.Element => {
  const { user } = useContext(AuthContext);
  const [plan, setPlan] = useState('free');
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [products, setProducts] = useState<{ name: string; id: string }[]>();
  const [activePlanId, setActivePlanId] = useState('');
  const [productsListener, setProductsListener] = useState<() => void>();
  const [customersListener, setCustomersListener] = useState<() => void>();
  const [couponsListener, setCouponsListener] = useState<() => void>();
  const [loadingPlanState, setLoadingPlanState] = useState(true);

  const setup = useCallback(() => {
    setPlan('free');
    setCoupon(null);
    // リスナーを解除
    if (productsListener) productsListener();
    if (customersListener) customersListener();
    if (couponsListener) couponsListener();
    if (!user) return;

    // リスナー解除用の関数用用意
    const productsListenConceler = firebase
      .firestore()
      .collection('products')
      .where('active', '==', true)
      .onSnapshot(() => {
        return;
      });

    const customersListenConceler = firebase
      .firestore()
      .collection('customers')
      .doc(user.uid)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(() => {
        return;
      });

    const couponsListenConceler = firebase
      .firestore()
      .collection('coupons')
      .where('uid', '==', user.uid)
      .onSnapshot(() => {
        return;
      });

    setProductsListener(productsListenConceler);
    setCustomersListener(customersListenConceler);
    setCouponsListener(couponsListenConceler);

    // リスナーの登録
    firebase
      .firestore()
      .collection('products')
      .where('active', '==', true)
      .onSnapshot(async (snapshot) => {
        const productData = [];
        for (const doc of snapshot.docs) {
          const name: string = doc.data().name;
          const productSnap = await doc.ref
            .collection('prices')
            .where('active', '==', true)
            .get();
          const id = productSnap.docs[0].id;
          const data = productSnap.docs[0].data();
          if (!isPriceType(data)) return;
          productData.push({ name, price: data, id });
        }
        setProducts(productData);
      });

    firebase
      .firestore()
      .collection('customers')
      .doc(user.uid)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        const data = snapshot.docs[0] && snapshot.docs[0].data();
        if (!isCustomers(data)) {
          setActivePlanId('');
          return;
        }
        const id = data.items[0].plan.id;
        setActivePlanId(id);
      });

    firebase
      .firestore()
      .collection('coupons')
      .where('uid', '==', user.uid)
      .onSnapshot((snapshot) => {
        const data = snapshot.docs[0] && snapshot.docs[0].data();
        if (!isCoupon(data)) {
          setCoupon(null);
          return;
        }
        setCoupon(data);
      });
  }, [couponsListener, customersListener, productsListener, user]);

  // ユーザー情報に変更があったときに発火
  useEffect(() => {
    setLoadingPlanState(false);
    setup();
  }, [user, setup]);

  // リスナーが検知して有効プランを更新(couponが有効な場合はクーポン優先)
  useEffect(() => {
    if (
      coupon &&
      coupon.valid &&
      coupon.expiration!.toDate().getTime() > Date.now()
    ) {
      setPlan(coupon.plan.replace(' プラン', '').toLocaleLowerCase());
      setLoadingPlanState(false);
      return;
    }
    if (!products || !activePlanId) {
      setPlan('free');
      return;
    }
    for (const product of products) {
      if (product.id === activePlanId) {
        setPlan(product.name.replace(' プラン', '').toLocaleLowerCase());
      }
    }
    setLoadingPlanState(false);
  }, [products, activePlanId, coupon]);

  return (
    <PlanContext.Provider value={{ plan, loadingPlanState, coupon }}>
      {children}
    </PlanContext.Provider>
  );
};
