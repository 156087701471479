import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Animation from '../components/preview/Animation';
import classes from './Preview.module.css';

const Preview = () => {
  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  const history = useHistory();
  // load selectedEnvelopeName from local storage
  const selectedEnvelopeName = localStorage.getItem('selectedEnvelopeName');
  return (
    <div>
      <Container>
        <Animation selectedEnvelopeName={selectedEnvelopeName || ''} />
      </Container>
      <button
        className={
          classes.ukButtonPrimary +
          ' uk-button uk-button-primary uk-position-center'
        }
        onClick={() => {
          history.goBack();
        }}
      >
        戻る
      </button>
    </div>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: radial-gradient(#ffffff, #fffaf6);
`;

export default Preview;
