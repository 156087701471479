export const mainMenus = [
  {
    path: '/mypage/home',
    text: 'マイページ',
    icon: 'home',
  },
  {
    path: '/mypage/settings',
    text: 'アカウント設定',
    icon: 'settings',
  },
];

export const letterMenus = [
  {
    path: '/mypage/draft',
    text: '下書き',
    icon: 'file-text',
  },
  {
    path: '/mypage/completed',
    text: '送った手紙',
    icon: 'push',
  },
  {
    path: '/mypage/received',
    text: '受け取った手紙',
    icon: 'pull',
  },
];

export const fontMenus = [
  {
    path: '/mypage/myfonts',
    text: 'マイフォント',
    icon: 'file-edit',
  },
];
