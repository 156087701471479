import { useState, useEffect, useContext, useReducer } from 'react';
import classes from './Draft.module.css';
import LetterContainer from './LetterContainer';
import fetchReceivedLetters from '../../lib//FetchReceivedLetters';
import { AuthContext } from '../../AuthProvider';
import LetterDocument from '../../lib/InterfaceOfLetterDocument';

const Received = () => {
  const title = { value: 'received', text: '受け取った手紙' };
  const { user } = useContext(AuthContext);
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [data, setData] = useState<{ [id: string]: LetterDocument }>();
  const [page, setPage] = useState<number>(0);
  const [delimiterPositions, dispatch] = useReducer(
    (state: string[], value: { id: number; position: string }) => {
      if (value.id !== 0) {
        state[value.id] = value.position;
      }
      return state;
    },
    [],
  );
  // const [orderField, setOrderField] = useState<string>("updatedDate");
  const orderField = 'updatedDate';

  useEffect(() => {
    const fetchUserLettersData = async () => {
      const uid = user ? user.uid : '';
      try {
        const letters = await fetchReceivedLetters(
          uid,
          orderField,
          'public',
          delimiterPositions[page],
        );
        if (letters !== undefined && letters !== null) {
          setIsFetched(true);
          setData(letters.collection);
          dispatch({ id: page + 1, position: letters.delimiterPosition });
        } else {
          setIsFetched(true);
          console.log('there is not documents');
        }
      } catch {
        setIsFetched(true);
        console.error('fetch document error');
      }
    };
    fetchUserLettersData();
  }, [user, page, orderField, delimiterPositions]);

  return (
    <>
      {isFetched ? (
        data ? (
          <>
            <div className={classes.draft}>
              <LetterContainer title={title} data={data} />
            </div>
            <ul className="uk-pagination " uk-margin="true">
              {page !== 0 ? (
                <li>
                  <button className="uk-button uk-button-link">
                    <span
                      uk-pagination-previous="true"
                      onClick={() => {
                        setPage((previous) => previous - 1);
                        setIsFetched(false);
                      }}
                    ></span>
                  </button>
                </li>
              ) : (
                <li className="uk-disabled">
                  <button className="uk-button uk-button-link">
                    <span
                      uk-pagination-previous="true"
                      style={{ color: '#eee' }}
                    ></span>
                  </button>
                </li>
              )}
              <li className="uk-active">
                <button className="uk-button uk-button-link uk-disabled">
                  {page + 1}
                </button>
              </li>
              {Object.keys(data).length > 10 ? (
                <li>
                  <button className="uk-button uk-button-link">
                    <span
                      uk-pagination-next="true"
                      onClick={() => {
                        setPage((previous) => previous + 1);
                        setIsFetched(false);
                      }}
                    ></span>
                  </button>
                </li>
              ) : (
                <li className="uk-disabled">
                  <button className="uk-button uk-button-link">
                    <span
                      uk-pagination-next="true"
                      style={{ color: '#eee' }}
                    ></span>
                  </button>
                </li>
              )}
            </ul>
          </>
        ) : (
          <div className={classes.draft}>
            <h3 className={classes.ukH3}>受け取った手紙</h3>
            <div className="uk-grid-small uk-grid-match" uk-grid="true">
              手紙がありません
            </div>
          </div>
        )
      ) : (
        <span
          className="uk-position-center"
          uk-spinner="true"
          style={{ color: 'orange' }}
        />
      )}
    </>
  );
};

export default Received;
