import { useState } from 'react';
import classes from './LetterContainer.module.css';
import LetterDocument from '../../lib/InterfaceOfLetterDocument';
import format from 'date-fns/format';
import { useHistory } from 'react-router-dom';
import 'firebase/firestore';
import DeleteConfirmModal from './DeleteConfirmModal';
import LetterContentModal from './LetterContentModal';

type Props = {
  title: { value: string; text: string };
  data: { [id: string]: LetterDocument };
  onDeleteDB?: (documentId: string) => void;
};

const LetterContainer: React.FC<Props> = ({ title, data, onDeleteDB }) => {
  return (
    <div className={classes.draft}>
      <h3 className={classes.ukH3}>{title.text}</h3>
      <div
        className="uk-grid uk-grid-medium uk-grid-match uk-height-match uk-padding-remove-left"
        uk-grid="true"
        uk-height-match="true; target: > button > .uk-card-small"
        id="letters"
      >
        <Letters title={title} data={data} onDeleteDB={onDeleteDB} />
      </div>
    </div>
  );
};

const Letters: React.FC<Props> = ({ title, data, onDeleteDB }) => {
  const history = useHistory();
  const [currentDocId, setCurrentDocId] = useState<string>('');
  const [shownDeleteConfirmModal, setShownDeleteConfirmModal] =
    useState<boolean>(false);
  const [shownLetterContentModal, setShownLetterContentModal] =
    useState<boolean>(false);

  type overviewLetter = {
    movePageText: string;
    movePageFunction: (documentId: string) => void;
  };

  const role: overviewLetter = {
    movePageText: '',
    movePageFunction: () => {
      return;
    },
  };

  let deleteFunc: (flag: boolean) => void;

  switch (title.value) {
    case 'draft':
      role.movePageText = '編集する';
      role.movePageFunction = (documentId: string) => {
        const utcDate = data[documentId].createdDate
          .toDate()
          .toLocaleString('en-US', { timeZone: 'UTC' });
        localStorage.setItem('documentId', documentId);
        localStorage.setItem(
          'createdDate',
          format(Date.parse(utcDate), 'yyyy-MM-dd-HH:mm:ss'),
        );
        localStorage.setItem(
          'selectedEnvelopeName',
          data[documentId].envelopeImageName,
        );
        localStorage.setItem(
          'selectedLetterName',
          data[documentId].letterBackgroundImageName,
        );
        localStorage.setItem(
          'url',
          'https://lightletter.app/letter?id=' +
            data[documentId].uuid +
            '&q=' +
            data[documentId].password,
        );
        history.push('/edit');
      };
      deleteFunc = (flag: boolean) => {
        setShownDeleteConfirmModal(flag);
      };
      break;
    case 'completed':
      role.movePageText = '表示する';
      role.movePageFunction = (documentId: string) => {
        history.push(
          '/letter?id=' +
            data[documentId].uuid +
            '&q=' +
            data[documentId].password,
        );
      };
      deleteFunc = (flag: boolean) => {
        setShownDeleteConfirmModal(flag);
      };
      break;
    case 'received':
      role.movePageText = '表示する';
      role.movePageFunction = (documentId: string) => {
        history.push(
          '/letter?id=' +
            data[documentId].uuid +
            '&q=' +
            data[documentId].password,
        );
      };
      break;
  }

  return (
    <>
      {Object.keys(data).map((documentId, index) => {
        // 表示を10件に抑える
        if (index < 10) {
          return (
            <div
              key={data[documentId].status + index}
              id={data[documentId].status + title.value}
              className="uk-width-1-4@m uk-inline "
              onMouseEnter={() => {
                if (
                  (title.value === 'completed' || title.value === 'draft') &&
                  !navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)
                ) {
                  const closeButton: HTMLButtonElement | null =
                    document.getElementById(
                      'closeButton' + data[documentId].status + index,
                    ) as HTMLButtonElement;
                  closeButton.style.visibility = 'visible';
                }
              }}
              onMouseLeave={() => {
                if (
                  (title.value === 'completed' || title.value === 'draft') &&
                  !navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)
                ) {
                  const closeButton: HTMLButtonElement | null =
                    document.getElementById(
                      'closeButton' + data[documentId].status + index,
                    ) as HTMLButtonElement;
                  closeButton.style.visibility = 'hidden';
                }
              }}
            >
              <div>
                <button
                  id={'closeButton' + data[documentId].status + index}
                  className={
                    'uk-overlay uk-position-top-right uk-close ' +
                    classes.ukCloseButton
                  }
                  type="button"
                  uk-close="true; "
                  style={{
                    padding: '5px',
                    paddingLeft: '5px',
                    visibility:
                      title.value === 'draft' &&
                      navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)
                        ? 'visible'
                        : 'hidden',
                  }}
                  onClick={() => {
                    // set show delete modal state
                    setCurrentDocId(documentId);
                    setShownDeleteConfirmModal(true);
                  }}
                ></button>
                <button
                  id={'letterButton' + data[documentId].status + index}
                  className={
                    classes.draftItemContainer +
                    ' uk-box-shadow-small uk-responsive-height uk-card-small uk-card-default uk-card-hover uk-link-reset uk-button'
                  }
                  type="button"
                  onClick={() => {
                    setCurrentDocId(documentId);
                    setShownLetterContentModal(true);
                  }}
                >
                  <div className="uk-card-media-top">
                    <div
                      className="uk-grid-collpase uk-child-width-expand uk-text-center uk-padding-remove"
                      uk-grid="true"
                    >
                      <img
                        className={
                          classes.ukEnvelopeImg +
                          ' uk-responsive-width uk-responsive-height'
                        }
                        uk-img="true"
                        src={data[documentId].envelopeImageName}
                        alt=""
                      />
                      <img
                        className={
                          classes.ukLetterBackgroundImg +
                          ' uk-responsive-width uk-responsive-height'
                        }
                        uk-img="true"
                        src={data[documentId].letterBackgroundImageName}
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className={
                      classes.ukCardBody +
                      ' uk-card-body uk-padding-remove-horizontal'
                    }
                    style={{
                      padding: '10px',
                      lineHeight: '20px',
                    }}
                  >
                    <p style={{ marginRight: 'auto' }}>
                      {'作成日時: ' +
                        format(
                          data[documentId].updatedDate.toDate(),
                          'MM/dd/yyyy HH:mm:ss',
                        )}
                    </p>
                  </div>
                </button>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
      {shownLetterContentModal && (
        <div
          className="uk-position-cover uk-overlay uk-overlay-primary uk-animation-fade uk-animation-fast"
          style={{
            marginTop: '80px',
            marginBottom: '0px',
            background: 'rgba(0, 0, 0, .32)',
            position: 'fixed',
          }}
          onClick={() => {
            setShownLetterContentModal(false);
          }}
        >
          <div
            className="uk-position-center uk-position-middle uk-overlay-default uk-animation-fade uk-animation-fast"
            style={{
              maxWidth: '80%',
              height: '500px',
              background: 'rgba(255, 255, 255, 1)',
              borderRadius: '5px',
            }}
          >
            <LetterContentModal
              documentId={currentDocId}
              letterBackgroundImageName={
                data[currentDocId].letterBackgroundImageName
              }
              text={data[currentDocId].text}
              movePageText={role.movePageText}
              movePageFunction={() => {
                role.movePageFunction(currentDocId);
              }}
              toggleState={() => {
                setShownLetterContentModal(false);
              }}
              // draft と completed のときだけ削除ボタンを置く
              deleteFunc={
                title.value === 'draft' || title.value === 'completed'
                  ? () => {
                      if (
                        title.value === 'draft' ||
                        title.value === 'completed'
                      ) {
                        deleteFunc(true);
                      }
                    }
                  : undefined
              }
            />
          </div>
        </div>
      )}
      {shownDeleteConfirmModal && (
        <div
          className="uk-position-cover uk-overlay uk-overlay-primary uk-animation-fade uk-animation-fast"
          style={{
            marginTop: '80px',
            marginBottom: '0px',
            background: 'rgba(0, 0, 0, .32)',
            position: 'fixed',
          }}
          onClick={() => {
            setShownDeleteConfirmModal(false);
          }}
        >
          <div
            className="uk-position-center uk-position-middle uk-overlay-default uk-animation-fade uk-animation-fast"
            style={{
              width: '600px',
              height: '300px',
              background: 'rgba(255, 255, 255, 1)',
              top: '30%',
              borderRadius: '5px',
            }}
          >
            <DeleteConfirmModal
              title={title.value}
              toggleState={() => {
                setShownDeleteConfirmModal(false);
              }}
              onDeleteDB={() => {
                onDeleteDB!(currentDocId);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LetterContainer;
