import firebase from '../config/firebase';
import 'firebase/firestore';

// fetch Document
const countDraftLetters = async (uid: string, status?: string) => {
  // DB参照のインスタンス生成
  const db: firebase.firestore.Firestore = firebase.firestore();
  const query = db
    .collectionGroup('letters')
    .where('uid', '==', uid)
    .where('status', '==', status);
  return query
    .get()
    .then((letters) => {
      return letters.size;
    })
    .catch((error) => {
      console.log('Error fetching user letters:', error);
      return null;
    });
};

export default countDraftLetters;
