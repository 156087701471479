import { useContext } from 'react';
import { AuthContext } from '../../AuthProvider';
import Login from '../login/Login';

type Props = {
  containerId: string;
};

const LoginModal: React.FC<Props> = ({ containerId }) => {
  const { loadingAuthState } = useContext(AuthContext);
  const { authenticated } = useContext(AuthContext);

  return (
    <div>
      <div
        id="modal-center"
        className="uk-flex-top"
        uk-modal={'true; container: ' + containerId}
      >
        <div className="uk-modal-dialog" style={{ width: '300px' }}>
          {loadingAuthState && (
            <div className="uk-title">
              <span
                className="uk-position-center"
                uk-spinner="true"
                style={{ color: 'orange' }}
              ></span>
              <button
                className="uk-modal-close-default"
                type="button"
                uk-close="true"
              ></button>
            </div>
          )}
          {!authenticated && (
            <div className="uk-title">
              <Login />
              <button
                className="uk-modal-close-default"
                type="button"
                uk-close="true"
              ></button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
