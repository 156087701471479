import { useContext } from 'react';
import classes from './FontSelectDropDown.module.css';
import { FontContext } from '../../FontProvider';

type Props = {
  activeFontFamily: string;
  onChangeActiveFont: (onChangeActiveFont: string) => void;
  isLoadedFontFace: boolean;
};

interface font {
  title: string;
  id: number;
}

const FontSelectDropDown: React.FC<Props> = ({
  activeFontFamily,
  onChangeActiveFont,
  isLoadedFontFace,
}) => {
  const { myFontExists, loadingFontState } = useContext(FontContext);
  const fonts: font[] = [
    {
      title: myFontExists ? 'MyFont' : 'MyFont(unregistered)',
      id: 0,
    },
    {
      title: 'Noto Serif JP',
      id: 1,
    },
    {
      title: 'Open Sans',
      id: 2,
    },
    {
      title: 'Roboto',
      id: 3,
    },
    {
      title: 'Noto Sans JP',
      id: 4,
    },
    {
      title: 'Montserrat',
      id: 5,
    },
    {
      title: 'RobotoCondensed',
      id: 6,
    },
    {
      title: 'Source Sans Pro',
      id: 7,
    },
    {
      title: 'Oswald',
      id: 8,
    },
    {
      title: 'Poppins',
      id: 9,
    },
    {
      title: 'Roboto Mono',
      id: 10,
    },
    {
      title: 'New Tegomin',
      id: 11,
    },
    {
      title: 'Sawarabi Gothic',
      id: 12,
    },
    {
      title: 'Kosugi Maru',
      id: 13,
    },
    {
      title: 'Kiwi Maru',
      id: 14,
    },
    {
      title: 'Yusei Magic',
      id: 15,
    },
    {
      title: 'Train One',
      id: 16,
    },
    {
      title: 'RocknRoll One',
      id: 17,
    },
  ];

  return (
    <>
      <div className={classes.navbarButton}>
        <button
          className={
            ' uk-button uk-button-default uk-text-truncate ' +
            classes.fontDropDown
          }
          type="button"
          style={{ fontFamily: activeFontFamily }}
        >
          {loadingFontState && !isLoadedFontFace ? (
            <span
              className="uk-position-center"
              uk-spinner="true"
              style={{ color: 'orange' }}
            />
          ) : (
            activeFontFamily
          )}
        </button>
        <div
          uk-dropdown="mode: click;"
          uk-toggle="target: .toggle"
          style={{ width: '200px' }}
        >
          <ul className="uk-nav uk-dropdown-nav">
            <li>
              {fonts.map((font) => {
                const flag: boolean =
                  font.title === 'MyFont(unregistered)' ? true : false;
                if (flag) {
                  return (
                    <div key={font.id}>
                      <div
                        className={classes.dropButton}
                        uk-toggle="target: #ask-modal;"
                      >
                        <div
                          style={{
                            fontFamily: 'Noto Serif JP',
                            cursor: 'pointer',
                          }}
                        >
                          {font.title}
                        </div>
                      </div>
                      <div
                        key="divider"
                        className={'uk-divider ' + classes.divider}
                      ></div>
                    </div>
                  );
                } else {
                  if (font.id === 0) {
                    return (
                      <div key={font.id}>
                        <div
                          className={
                            classes.dropButton + ' ' + classes.bottomLine
                          }
                          onClick={() => {
                            onChangeActiveFont(font.title);
                          }}
                        >
                          <div
                            style={{
                              fontFamily: myFontExists
                                ? 'MyFont'
                                : 'Noto Serif JP',
                              cursor: 'pointer',
                            }}
                          >
                            {font.title}
                          </div>
                        </div>
                        <div
                          key="divider"
                          className={'uk-divider ' + classes.divider}
                        ></div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={font.id}
                        className={classes.dropButton}
                        onClick={() => {
                          onChangeActiveFont(font.title);
                        }}
                      >
                        <div
                          style={{ fontFamily: font.title, cursor: 'pointer' }}
                        >
                          {font.title}
                        </div>
                      </div>
                    );
                  }
                }
              })}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default FontSelectDropDown;
