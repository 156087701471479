import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import classes from './SelectProvider.module.css';
import LightLetterName from '../../assets/imgs/lightletter-name.svg';
import googleIcon from '../../assets/imgs/google-icon.svg';
import twitterIcon from '../../assets/imgs/twitter.svg';
import firebase from '../../config/firebase';

type Props = {
  isLogin: boolean;
};

const SelectProvider: React.FC<Props> = ({ isLogin }) => {
  const history = useHistory();

  const loginGoogle = useCallback(() => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  }, []);

  const loginTwitter = useCallback(() => {
    const provider = new firebase.auth.TwitterAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  }, []);

  return (
    <div
      id="selectProvider"
      className="uk-flex-center uk-position-center uk-margin-large-top"
    >
      <div className={classes.loginFrame + ' uk-flex uk-flex-column'}>
        <img
          className="uk-align-center uk-margin-remove-bottom"
          src={LightLetterName}
          alt=""
          style={{ width: 130 }}
        />
        <p className="uk-margin uk-text-center uk-h4">
          {isLogin ? 'サインイン' : 'アカウントを作成'}
        </p>
        <p id="loginDiscription" className="uk-margin-small uk-h6">
          {isLogin ? 'Sign in with' : 'Create an account with'}
        </p>
        <button
          className={
            classes.loginButton +
            ' uk-button uk-button-default uk-text-capitalize uk-margin-bottom'
          }
          onClick={loginGoogle}
        >
          <img
            src={googleIcon}
            alt=""
            style={{ width: 18, marginRight: '5px' }}
          />{' '}
          Google
        </button>
        <button
          className={
            classes.loginButton +
            ' uk-button uk-button-default uk-text-capitalize'
          }
          onClick={loginTwitter}
          style={{ width: 250 }}
        >
          <img
            src={twitterIcon}
            alt=""
            style={{ width: 18, marginRight: '5px' }}
          />{' '}
          Twitter
        </button>
      </div>
      <div className="uk-margin-top uk-align-right">
        <button
          className={classes.policyButton + ' uk-button uk-button-text'}
          onClick={() => {
            history.push('/policy');
          }}
        >
          プライバシー
        </button>
        <button
          className={
            classes.termsButton + ' uk-button uk-button-text uk-margin-left'
          }
          onClick={() => {
            history.push('/terms');
          }}
        >
          規約
        </button>
      </div>
    </div>
  );
};

export default SelectProvider;
