import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classes from './Sidebar.module.css';
import { PlanContext } from '../../PlanProvider';
import { mainMenus, letterMenus, fontMenus } from './Menus';

type Props = {
  activeRoot: string;
  toggleModal: (flags: boolean) => void;
};

const Sidebar: React.FC<Props> = ({ activeRoot, toggleModal }) => {
  const history = useHistory();
  const { plan } = useContext(PlanContext);
  const root: string = activeRoot === '/mypage' ? 'home' : activeRoot;

  return (
    <>
      {/* in case that sideBar is visible */}
      <div className={classes.sideBar + ' uk-visible@m'}>
        <ul className="uk-nav uk-nav-default">
          {mainMenus.map((menu, index) => (
            <li
              key={index}
              className={`${menu.path.includes(root) ? classes.active : ''} ${
                classes.menu
              }`}
            >
              <Link
                className={
                  menu.path.includes(root) ? classes.activeText : classes.text
                }
                to={menu.path}
              >
                <div
                  className={`${
                    menu.path.includes(root) ? classes.activeIcon : classes.icon
                  }`}
                  uk-icon={`icon:${menu.icon}`}
                ></div>
                {menu.text}
              </Link>
            </li>
          ))}
          <li className={classes.ukNavDivider + ' uk-nav-divider'}></li>
          {letterMenus.map((menu, index) => (
            <li
              key={index}
              className={`${menu.path.includes(root) ? classes.active : ''} ${
                classes.menu
              }`}
            >
              <Link
                className={
                  menu.path.includes(root) ? classes.activeText : classes.text
                }
                to={menu.path}
              >
                <div
                  className={`${
                    menu.path.includes(root) ? classes.activeIcon : classes.icon
                  }`}
                  uk-icon={`icon:${menu.icon}`}
                ></div>
                {menu.text}
              </Link>
            </li>
          ))}
          <li className={classes.ukNavDivider + ' uk-nav-divider'}></li>
          {/* fontMenus は free accountの場合は無効化する */}
          {fontMenus.map((menu, index) => (
            <li
              key={index}
              className={[
                `${menu.path.includes(root) ? classes.active : ''}`,
                `${plan === 'free' ? classes.disableItem : ''}`,
                `${classes.menu}`,
              ].join(' ')}
            >
              <div
                className={[
                  `${
                    menu.path.includes(root) ? classes.activeText : classes.text
                  }`,
                  // `${plan === 'free' ? 'uk-disabled' : ''}`,
                ].join(' ')}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (plan !== 'free') {
                    history.push(menu.path);
                  } else {
                    toggleModal(true);
                  }
                }}
              >
                <div
                  className={[
                    `${
                      menu.path.includes(root)
                        ? classes.activeIcon
                        : classes.icon
                    }`,
                  ].join(' ')}
                  uk-icon={`icon:${menu.icon}`}
                ></div>
                {menu.text}
                {
                  // free のユーザにのみ表示する
                  plan === 'free' && (
                    <span
                      className={[
                        `${
                          menu.path.includes(root)
                            ? classes.activeKeyIcon
                            : classes.keyIcon
                        }`,
                        'uk-badge',
                        'uk-position-small',
                        'uk-position-center-right',
                      ].join(' ')}
                      style={{
                        backgroundColor: 'rgb(255 91 91)',
                        color: 'rgb(255, 255, 255)',
                        fontSize: 'small',
                      }}
                    >
                      S
                    </span>
                  )
                }
              </div>
            </li>
          ))}
          <li>
            <div
              className={
                classes.ukDivButtonPrimary + ' uk-position-bottom-center'
              }
            >
              <button
                onClick={() => {
                  localStorage.removeItem('createdDate');
                  localStorage.removeItem('documentId');
                  localStorage.removeItem('letter');
                  localStorage.removeItem('receivedLetter');
                  localStorage.removeItem('selectedEnvelopeName');
                  localStorage.removeItem('selectedLetterName');
                  localStorage.removeItem('url');
                  history.push('/designs');
                }}
                className={
                  classes.ukButtonPrimary +
                  ' uk-margin uk-button uk-button-primary'
                }
              >
                手紙を書く
              </button>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
