import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../AuthProvider';
import Login from '../../components/login/Login';
import Logined from '../../components/login/Logined';
import classes from './Header.module.css';
import LightLetterLogoAndName from '../../assets/imgs/lightletter-logo-and-name.svg';

type Props = {
  customHeaderMenu?: React.ReactElement;
  headerColor?: string;
};

const Header: React.FC<Props> = ({
  customHeaderMenu,
  headerColor = '#ffefe9d1',
}) => {
  const { user } = useContext(AuthContext);
  const { loadingAuthState } = useContext(AuthContext);

  return (
    <div className="header uk-sticky-fixed" uk-sticky="true">
      <nav
        className={
          classes.ukNavbarContainer +
          ' uk-navbar-container uk-padding-small uk-padding-remove-top uk-padding-remove-left uk-padding-remove-bottom'
        }
        uk-navbar="true"
        style={{ backgroundColor: headerColor }}
      >
        <div className="uk-navbar-left">
          <ul className="uk-navbar-nav">
            <li>
              <Link className="app-title" to="/">
                <img
                  src={LightLetterLogoAndName}
                  width="170px"
                  alt="logo icon"
                />
              </Link>
            </li>
          </ul>
        </div>
        <div className="uk-navbar-right">
          <ul className="uk-navbar-nav">
            {customHeaderMenu}
            <li>
              {loadingAuthState ? (
                <div
                  className={
                    classes.ukNavbarItem + ' uk-navbar-item uk-position-center'
                  }
                  uk-spinner="true"
                  style={{ color: 'orange' }}
                />
              ) : (
                <>
                  <button
                    id="userIconButton"
                    className="uk-button uk-button-link"
                    uk-toggle="mode: click"
                    style={{ justifyContent: 'center', minHeight: '80px' }}
                  >
                    {user ? (
                      user.photoURL ? (
                        <img
                          className="uk-border-circle"
                          alt="profile"
                          style={{ width: '30px' }}
                          src={user.photoURL}
                        />
                      ) : (
                        <span
                          className="uk-icon-link"
                          uk-icon="true; icon: user;"
                          style={{ width: '30px' }}
                        ></span>
                      )
                    ) : (
                      <span
                        className="uk-icon-link"
                        uk-icon="true; icon: user;"
                        style={{ width: '30px' }}
                      ></span>
                    )}
                  </button>
                  <div
                    id="userPanel"
                    className="uk-navbar-dropdown uk-padding-remove uk-border-rounded"
                    style={{ marginTop: '-10px', width: '300px' }}
                  >
                    <div
                      className="uk-nav uk-navbar-dropdown-nav uk-border-rounded"
                      style={{ width: '300px' }}
                    >
                      {user ? (
                        user.email ? (
                          <Logined
                            name={user.displayName}
                            email={user.email}
                            url={user.photoURL}
                          />
                        ) : (
                          <Logined
                            name={user.displayName}
                            email={user.providerData[0]!.providerId}
                            url={user.photoURL}
                          />
                        )
                      ) : (
                        <Login />
                      )}
                    </div>
                  </div>
                </>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default Header;
