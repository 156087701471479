import { useEffect } from 'react';
import LetterDocument from '../../lib/InterfaceOfLetterDocument';

type Props = {
  letterDoc: LetterDocument;
  onFetchImages: (size: number) => void;
};

const SavedImages: React.FC<Props> = ({ letterDoc, onFetchImages }) => {
  useEffect(() => {
    let totalSize = 0;
    letterDoc.elements.forEach((element) => {
      if (element.className.includes('set-image')) {
        totalSize = totalSize + element.src.length;
      }
    });
    onFetchImages(totalSize);
  }, [letterDoc.elements, onFetchImages]);

  return (
    <>
      {letterDoc.elements.map((element, index) => {
        return (
          <img
            key={element.id + index}
            id={element.id}
            className={element.className}
            src={element.src}
            style={{
              top: element.top,
              left: element.left,
              transform: element.transform,
            }}
            alt=""
          />
        );
      })}
    </>
  );
};

export default SavedImages;
