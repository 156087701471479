import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classes from './SettingView.module.css';
import { PlanContext } from '../../PlanProvider';
import Modal from '../common/Modal';
import firebase from '../../config/firebase';
import format from 'date-fns/format';

const SettingView = () => {
  const history = useHistory();
  const firestore = firebase.firestore();
  const { plan, coupon } = useContext(PlanContext);
  const [suspendCouponModal, setSuspendCouponModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteAccount = firebase
    .app()
    .functions('asia-northeast1')
    .httpsCallable('deleteAccount');

  return (
    <>
      <div className={classes.draft}>
        <h3 className={classes.ukH3}>アカウント設定</h3>
        <p className="uk-text-bold">
          {`現在のプラン： ${plan?.charAt(0).toUpperCase()}${plan?.slice(
            1,
          )} プラン`}
          {coupon !== null &&
            coupon !== undefined &&
            coupon.valid &&
            (!(coupon.expiration!.toDate().getTime() > Date.now())
              ? plan === 'free' && ' （クーポン期限切れ）'
              : ` （クーポン利用中： ${format(
                  coupon.expiration!.toDate() as Date,
                  'yyyy/M/d H:mm',
                )} まで有効）`)}
        </p>
        {coupon !== null &&
          coupon !== undefined &&
          coupon.valid &&
          coupon.expiration!.toDate().getTime() > Date.now() && (
            <p className="uk-h6 uk-margin-small-top">
              クーポンをご利用中の場合、プラン変更を行うためには「
              <button
                className="uk-h6 uk-button uk-button-link"
                style={{ marginBottom: '2px' }}
                type="button"
                onClick={() => {
                  setSuspendCouponModal(true);
                }}
              >
                クーポンの利用停止
              </button>
              」が必要となります。
              <br />
              プラン変更後に、再度クーポンを利用してご契約し直すことも可能です。(注:
              クーポンの有効期限は &quot;初回&quot; ご利用時から1年後となります)
            </p>
          )}
        <button
          className={`uk-button uk-margin-medium-bottom ${classes.settingViewButton}`}
          disabled={
            coupon !== null &&
            coupon !== undefined &&
            coupon.valid &&
            coupon.expiration!.toDate().getTime() > Date.now()
          }
          onClick={() => {
            history.push('/payment/plan');
          }}
          style={
            coupon !== null &&
            coupon !== undefined &&
            coupon.valid &&
            coupon.expiration!.toDate().getTime() > Date.now()
              ? { color: '#d2d1d1', backgroundColor: '#fbfbfb' }
              : {}
          }
        >
          変更する
        </button>
        <p className="uk-text-bold">アカウントを削除する</p>
        <button
          className={`uk-button ${classes.settingViewButton}`}
          onClick={() => setDeleteAccountModal(true)}
        >
          削除する
        </button>

        {/* Suspend Coupon */}
        <Modal
          display={suspendCouponModal}
          title="クーポンの利用停止"
          onClose={() => {
            setSuspendCouponModal(false);
          }}
        >
          <p>
            クーポンの利用を停止し、Freeプランに移行します。本当によろしいですか。
          </p>
          <button
            className={`uk-button uk-button-danger ${classes.deleteButton}`}
            onClick={async () => {
              try {
                const snapshots = await firestore
                  .collection('coupons')
                  .where('uid', '==', firebase.auth().currentUser?.uid)
                  .where('valid', '==', true)
                  .get();
                snapshots.forEach((snapshot) => {
                  snapshot.ref.update({ valid: false });
                });
              } catch (e) {
                console.error(e);
              }
              setSuspendCouponModal(false);
            }}
          >
            {deleting ? <span uk-spinner="true" /> : '停止する'}
          </button>
        </Modal>

        {/* Delete Account */}
        <Modal
          display={deleteAccountModal}
          title="アカウント削除"
          onClose={() => {
            setDeleteAccountModal(false);
          }}
        >
          <p>
            削除すると以前に作成した手紙や受け取った手紙を見ることができなくなります。本当によろしいですか？
          </p>
          <button
            className={`uk-button uk-button-danger ${classes.deleteButton}`}
            onClick={async () => {
              setDeleting(true);
              const token = await firebase.auth().currentUser?.getIdToken();
              await deleteAccount({ token });
              firebase.auth().signOut();
              history.push('/');
            }}
          >
            {deleting ? <span uk-spinner="true" /> : '削除する'}
          </button>
        </Modal>
      </div>
    </>
  );
};

export default SettingView;
