import { useState, useEffect, useContext, useReducer } from 'react';
import classes from './Draft.module.css';
import LetterContainer from './LetterContainer';
import fetchUserLetters from '../../lib//FetchUserLetters';
import { AuthContext } from '../../AuthProvider';
import { PlanContext } from '../../PlanProvider';
import LetterDocument from '../../lib/InterfaceOfLetterDocument';
import deleteLetterDocument from '../../lib/DeleteLetterDocument';
import countDraftLetters from '../../lib/CountDraftLetters';

const Completed = () => {
  const title = { value: 'completed', text: '送った手紙' };
  const { user } = useContext(AuthContext);
  const { plan } = useContext(PlanContext);
  const [data, setData] = useState<{ [id: string]: LetterDocument }>();
  const [page, setPage] = useState<number>(0);
  const [numberOfcompleted, setNumberOfcompleted] = useState(0);
  const initialFetchStatus = false;
  const initialDelimiterPosition: string[] = [];

  const toggleFetchState = (state: boolean, action: { type: string }) => {
    switch (action.type) {
      case 'fetched':
        state = true;
        return state;
      case 'reset':
        return initialFetchStatus;
      default:
        throw new Error();
    }
  };

  const delimiterPositionReducer = (
    state: string[],
    value: { id: number; position: string; action: string },
  ) => {
    switch (value.action) {
      case 'count': {
        if (value.id !== 0) {
          state[value.id] = value.position;
        }
        return state;
      }
      case 'reset': {
        return initialDelimiterPosition;
      }
      default:
        throw new Error();
    }
  };

  const [isFetched, setFetchState] = useReducer(
    toggleFetchState,
    initialFetchStatus,
  );
  const [delimiterPositions, setDelimiterPositions] = useReducer(
    delimiterPositionReducer,
    initialDelimiterPosition,
  );
  const orderField = 'updatedDate';

  useEffect(() => {
    const fetchUserLettersData = async () => {
      const uid = user ? user.uid : '';
      try {
        const letters = await fetchUserLetters(
          uid,
          orderField,
          'public',
          delimiterPositions[page],
        );
        if (letters !== undefined && letters !== null) {
          setFetchState({ type: 'fetched' });
          setData(letters.collection);
          setDelimiterPositions({
            id: page + 1,
            position: letters.delimiterPosition,
            action: 'count',
          });
        } else {
          setFetchState({ type: 'fetched' });
          console.log('there is not documents');
        }
      } catch {
        console.error('fetch document error');
      }
    };
    const countDraftLettersData = async () => {
      const uid = user ? user.uid : '';
      try {
        const number = await countDraftLetters(uid, 'public');
        if (number) {
          setNumberOfcompleted(number);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (!isFetched) {
      fetchUserLettersData();
      countDraftLettersData();
    }
  }, [user, page, orderField, delimiterPositions]);
  return (
    <>
      {isFetched ? (
        data ? (
          <>
            <div className={classes.draft}>
              {plan === 'free' && (
                <>
                  {numberOfcompleted < 30 ? (
                    <div
                      className={classes.ukAlert + ' uk-alert-warning'}
                      uk-alert="alert"
                    >
                      <p>
                        {'使用状況： ' + numberOfcompleted + ' 通'}
                        <br />
                        {
                          '送信済みの手紙を最大 30 通まで保存することができます。'
                        }
                        <br />
                      </p>
                      <progress
                        id="js-progressbar"
                        className={
                          classes.ukProgress +
                          ' uk-progress uk-margin-small-top'
                        }
                        value={numberOfcompleted}
                        max="30"
                        uk-progress="true"
                      ></progress>
                    </div>
                  ) : (
                    <div
                      className={classes.ukAlert + ' uk-alert-danger'}
                      uk-alert="true"
                    >
                      <p>
                        {'使用状況： ' + numberOfcompleted + ' 通'}
                        <br />
                        {
                          'これ以上は送信済みの手紙を保存することができません。不要な手紙を削除してください。'
                        }
                        <br />
                      </p>
                      <progress
                        id="js-progressbar"
                        className={classes.ukProgressDanger + ' uk-progress'}
                        value={numberOfcompleted}
                        max="10"
                        uk-progress="true"
                      ></progress>
                    </div>
                  )}
                </>
              )}
              <LetterContainer
                title={title}
                data={data}
                onDeleteDB={async (documentId: string) => {
                  if (user) {
                    await deleteLetterDocument(user.uid, documentId);
                  }
                  setFetchState({ type: 'reset' });
                  setDelimiterPositions({
                    id: 0,
                    position: '',
                    action: 'reset',
                  });
                  setPage(0);
                  setData(undefined);
                }}
              />
            </div>
            <ul className="uk-pagination" uk-margin="true">
              {page !== 0 ? (
                <li>
                  <button className="uk-button uk-button-link">
                    <span
                      uk-pagination-previous="true"
                      onClick={() => {
                        setPage((previous) => previous - 1);
                        setFetchState({ type: 'reset' });
                      }}
                    ></span>
                  </button>
                </li>
              ) : (
                <li className="uk-disabled">
                  <button className="uk-button uk-button-link">
                    <span
                      uk-pagination-previous="true"
                      style={{ color: '#eee' }}
                    ></span>
                  </button>
                </li>
              )}
              <li className="uk-active">
                <button className="uk-button uk-button-link uk-disabled">
                  {page + 1}
                </button>
              </li>
              {Object.keys(data).length > 10 ? (
                <li>
                  <button className="uk-button uk-button-link">
                    <span
                      uk-pagination-next="true"
                      onClick={() => {
                        setPage((previous) => previous + 1);
                        setFetchState({ type: 'reset' });
                      }}
                    ></span>
                  </button>
                </li>
              ) : (
                <li className="uk-disabled">
                  <button className="uk-button uk-button-link">
                    <span
                      uk-pagination-next="true"
                      style={{ color: '#eee' }}
                    ></span>
                  </button>
                </li>
              )}
            </ul>
          </>
        ) : (
          <div className={classes.draft}>
            <h3 className={classes.ukH3}>送った手紙</h3>
            <div className="uk-grid-small uk-grid-match" uk-grid="true">
              手紙がありません
            </div>
          </div>
        )
      ) : (
        <span
          className="uk-position-center"
          uk-spinner="true"
          style={{ color: 'orange' }}
        />
      )}
    </>
  );
};

export default Completed;
