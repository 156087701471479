import firebase from '../config/firebase';
import 'firebase/firestore';
import 'firebase/functions';

const deleteLetterDocument = async (uid: string, documentId: string) => {
  const updateDatebaseFunction = (documentId: string) => {
    const db: firebase.firestore.Firestore = firebase.firestore();
    return db
      .collection('lightLetters')
      .doc(uid)
      .collection('letters')
      .doc(documentId)
      .delete();
  };

  // delete attached images related to draft letter asynchronously
  // in order not to affect to user experience
  const func = firebase
    .app()
    .functions('asia-northeast1')
    .httpsCallable('deleteAttachedImage');
  func({ uid, documentId });

  await updateDatebaseFunction(documentId);
};

export default deleteLetterDocument;
