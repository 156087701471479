import { useHistory } from 'react-router-dom';

type Props = { name: string | null; email: string | null; url: string | null };

const Logined: React.FC<Props> = ({ name, email, url }) => {
  const history = useHistory();

  return (
    <div className="uk-card uk-card-hovar uk-border-rounded">
      <div className="uk-card-header">
        <ul className="uk-list">
          <li>
            {url ? (
              <img
                className="uk-border-circle uk-margin-auto uk-position-relative uk-position-top-center"
                alt="profile"
                style={{ width: '60px' }}
                src={url}
              />
            ) : (
              <span
                className="uk-icon-link"
                uk-icon="true; icon: user;"
                style={{ width: '60px' }}
              ></span>
            )}
          </li>
          <li>
            <h2
              className="uk-card-title uk-margin-remove-bottom uk-text-center"
              style={{ fontSize: '1.0rem' }}
            >
              {name}
            </h2>
          </li>
          <li>
            <h2 className="uk-text-meta uk-margin-remove-top uk-text-center">
              {email}
            </h2>
          </li>
        </ul>
      </div>
      <div className="uk-card-body uk-padding-remove">
        <button
          className="uk-button uk-button-default uk-width-1-1 uk-text-capitalize"
          onClick={() => {
            history.push('/mypage');
          }}
        >
          <div
            className="uk-icon-justify uk-padding-small uk-padding-remove-top uk-padding-remove-left uk-padding-remove-bottom"
            uk-icon="icon:home"
          ></div>
          My Page
        </button>
        <button
          className="uk-button uk-button-default uk-width-1-1 uk-text-capitalize uk-border-rounded"
          style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
          onClick={() => {
            history.push('/', 'logout');
          }}
        >
          <div
            className="uk-icon-justify uk-padding-small uk-padding-remove-top uk-padding-remove-left uk-padding-remove-bottom"
            uk-icon="icon:sign-out"
          ></div>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Logined;
