import { useEffect, useContext } from 'react';
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';
import TopView from '../../views/TopView';
import DesignsView from '../../views/DesignsView';
import EditView from '../../views/EditView';
import Preview from '../../views/Preview';
import Letter from '../../views/Letter';
import Policy from '../../views/PrivacyPolicy';
import Terms from '../../views/Terms';
import CommercialTransactions from '../../views/CommercialTransactions';
import NotFoundView from '../../views/NotFoundView';
import PaymentView from '../../views/PaymentView';
import Mypage from '../../views/Mypage';
import MailTemplateView from '../../views/MailTemplateView';
import classes from '../../views/Mypage.module.css';
import { AuthContext } from '../../AuthProvider';

const SwitchRouter = () => {
  const { authenticated } = useContext(AuthContext);
  const pathname = useLocation();

  useEffect(() => {
    // Move to top of page
    window.scrollTo(0, 0);
    // Remove opened sidebar flag for mypage
    window.document.body.classList.remove(classes.opened);
  }, [pathname]);

  return (
    <Switch>
      <Route exact path="/" component={TopView} />
      <Route exact path="/top" component={TopView} />
      <Route exact path="/designs" component={DesignsView} />
      <Route exact path="/mailtemplate" component={MailTemplateView} />
      <Route path="/payment/:from" component={PaymentView} />
      <Route exact path="/edit" component={EditView} />
      <Route exact path="/preview" component={Preview} />
      <Route exact path="/letter" component={Letter} />
      <Route exact path="/policy" component={Policy} />
      <Route exact path="/terms" component={Terms} />
      <Route
        exact
        path="/commercialtransactions"
        component={CommercialTransactions}
      />
      <Route
        exact
        path="/login"
        render={() =>
          authenticated ? <Redirect to="/mypage" /> : <Redirect to="/" />
        }
      />
      <Route
        exact
        path={[
          '/mypage',
          '/mypage/home',
          '/mypage/settings',
          '/mypage/draft',
          '/mypage/completed',
          '/mypage/myfonts',
          '/mypage/received',
        ]}
        render={() =>
          authenticated ? (
            <Route
              exact
              path={[
                '/mypage',
                '/mypage/home',
                '/mypage/settings',
                '/mypage/draft',
                '/mypage/completed',
                '/mypage/myfonts',
                '/mypage/received',
              ]}
              component={Mypage}
            />
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route exact path="/notfound" component={NotFoundView} />
      <Route component={NotFoundView} />
    </Switch>
  );
};

export default SwitchRouter;
