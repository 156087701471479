import firebase from 'firebase';
import Login from '../login/Login';

type Props = {
  user: firebase.User | null | undefined;
  plan: string | undefined;
  movePage: (plan: string | undefined) => void;
};

const FontRegisterModal: React.FC<Props> = ({ user, plan, movePage }) => {
  return (
    /* ユーザはログインしていない */
    !user ? (
      <div className="uk-modal-dialog uk-modal-body">
        <Login />
      </div>
    ) : /* ユーザはログインしているが、free プランの場合 */
    plan === 'free' ? (
      <div className="uk-modal-dialog uk-modal-body">
        <p>マイフォントは有料プラン向けの機能です</p>
        <p className="uk-text-right">
          <button
            className="uk-button uk-button-default uk-modal-close"
            type="button"
          >
            閉じる
          </button>
          <button
            className="uk-button uk-button-primary uk-modal-close"
            type="button"
            style={{ backgroundColor: 'rgb(253, 177, 127)', color: 'white' }}
            onClick={() => {
              movePage(plan);
            }}
          >
            プラン変更
          </button>
        </p>
      </div>
    ) : (
      /* standard以上のプランで登録を促すモーダル */
      <div className="uk-modal-dialog uk-modal-body">
        <p>マイフォントを登録しますか？</p>
        <p className="uk-text-right">
          <button
            className="uk-button uk-button-default uk-modal-close"
            type="button"
          >
            いいえ
          </button>
          <button
            className="uk-button uk-button-primary uk-modal-close"
            type="button"
            style={{ backgroundColor: 'rgb(253, 177, 127)', color: 'white' }}
            onClick={() => {
              movePage(plan);
            }}
          >
            登録する
          </button>
        </p>
      </div>
    )
  );
};

export default FontRegisterModal;
