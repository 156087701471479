import { useState, useContext } from 'react';
import Modal from '../common/Modal';
import classes from './CouponModal.module.css';
import firebase from '../../config/firebase';
import { AuthContext } from '../../AuthProvider';
import { addYears } from 'date-fns';

const CouponModal = ({
  display,
  onClose,
}: {
  display: boolean;
  onClose: () => void;
}) => {
  type Coupon = {
    uid: string | null;
    expiration: firebase.firestore.Timestamp | null;
    plan: 'standard' | 'premium';
    valid: boolean;
  };

  const isCoupon = (coupon: unknown): coupon is Coupon => {
    try {
      const { uid, expiration, plan, valid } = coupon as Coupon;
      if (
        (uid === null || typeof uid === 'string') &&
        (expiration === null || expiration.toDate() instanceof Date) &&
        (plan === 'premium' || plan === 'standard') &&
        typeof valid === 'boolean'
      ) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };

  const firestore = firebase.firestore();
  const [couponCode, setCouponCode] = useState('');
  const { user } = useContext(AuthContext);
  if (!user) return null;
  return (
    <Modal title="クーポンコードを利用する" display={display} onClose={onClose}>
      <div className={classes.container}>
        <p>クーポンコードをお持ちの方はこちらから登録してください。</p>
        <div className={classes.main}>
          <input type="text" onChange={(e) => setCouponCode(e.target.value)} />
        </div>
        <div className={classes.container}>
          <button
            onClick={async () => {
              try {
                const snapshot = await firestore
                  .collection('coupons')
                  .doc(couponCode)
                  .get();
                if (snapshot.exists) {
                  const data = snapshot.data();
                  if (isCoupon(data) && data) {
                    // 既に期日が入ってる場合は更新しない
                    if (data.expiration) {
                      snapshot.ref.update({
                        uid: user.uid,
                        valid: true,
                        // expiration: addYears(new Date(), 1),
                      });
                    } else {
                      snapshot.ref.update({
                        uid: user.uid,
                        valid: true,
                        expiration: addYears(new Date(), 1),
                      });
                    }
                    onClose();
                  } else {
                    throw new Error('Cannot get data from firestore');
                  }
                }
                onClose();
              } catch (e) {
                onClose();
              }
            }}
          >
            有効にする
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CouponModal;
