import { useEffect, Suspense, useState } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import Controls from './Controls';

type Props = {
  selectedEnvelopeName: string;
};

// this component is only for preview to avoid confliction of localStorage with letter view
const LoadModel: React.FC<Props> = ({ selectedEnvelopeName }) => {
  // generate the name of the glb file
  const str = selectedEnvelopeName?.split('/').slice(-1)[0].split('.')[0];
  const glb = './' + str + '-3d.glb';

  // configure glTF model
  const [scene, set] = useState(null as THREE.Group | null);
  const gltf = useLoader(GLTFLoader, glb);
  const model = gltf.scene;

  // load image url from local storage
  const url = localStorage.getItem('letter') || '';
  const texture = new TextureLoader().load(url);
  texture.center = new THREE.Vector2(0.5, 0.5);
  texture.rotation = Math.PI / 2;
  texture.encoding = THREE.sRGBEncoding;
  model.traverse((child) => {
    if (child instanceof THREE.Mesh && child.name === 'Plane_1') {
      child.material.map = texture;
    }
  });
  const [mixer] = useState(() => new THREE.AnimationMixer(model));
  useFrame((_, delta) => {
    mixer.update(delta);
  });
  useEffect(() => {
    set(model);
    const letterLowerHalfAction = mixer.clipAction(gltf.animations[0]);
    const letterUpperHalfAction = mixer.clipAction(gltf.animations[1]);
    const openEnvelopeAction = mixer.clipAction(gltf.animations[2]);
    letterLowerHalfAction.setLoop(THREE.LoopOnce, 1);
    letterLowerHalfAction.clampWhenFinished = true;
    letterLowerHalfAction.play();
    letterUpperHalfAction.setLoop(THREE.LoopOnce, 1);
    letterUpperHalfAction.clampWhenFinished = true;
    letterUpperHalfAction.play();
    openEnvelopeAction.setLoop(THREE.LoopOnce, 1);
    openEnvelopeAction.clampWhenFinished = true;
    openEnvelopeAction.play();
  }, [gltf.animations, gltf.scene, mixer, model]);

  return scene ? <primitive object={scene} /> : null;
};

const Animation: React.FC<Props> = ({ selectedEnvelopeName }) => {
  return (
    <Canvas camera={{ position: [0, 3, 0] }}>
      <ambientLight intensity={0.5} />
      <spotLight
        intensity={0.6}
        position={[30, 30, 50]}
        angle={0.5}
        penumbra={1}
        castShadow
      />
      <Controls />
      <mesh>
        <Suspense fallback={null}>
          <LoadModel selectedEnvelopeName={selectedEnvelopeName} />
        </Suspense>
      </mesh>
    </Canvas>
  );
};

export default Animation;
