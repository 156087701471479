import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthProvider';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../components/common/Header';
import Animation from '../components/letter/Animation';
import EnvelopeBlue from '../assets/imgs/envelope-blue.png';
import EnvelopeGreen from '../assets/imgs/envelope-green.png';
import EnvelopeOrange from '../assets/imgs/envelope-orange.png';
import EnvelopePink from '../assets/imgs/envelope-pink.png';
import EnvelopeIvory from '../assets/imgs/envelope-ivory.png';
import EnvelopeYellow from '../assets/imgs/envelope-yellow.png';
import EnvelopeBirthday from '../assets/imgs/envelope-birthday.png';
import EnvelopeChristmas from '../assets/imgs/envelope-christmas.png';
import firebase from '../config/firebase';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import base64 from 'urlsafe-base64';

const Letter = () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [selectedEnvelopeName, setSelectedEnvelopeName] = useState<string>();
  // get query parameters from URL
  const urlParams = window.location.search.substring(1);
  let queryUuid = '';
  let queryPassword = '';

  useEffect(() => {
    localStorage.removeItem('receivedLetter');
    const uid = user ? user.uid : 'guests';

    // setEnvelope function
    const setEnvelope = (envelopeImageName: string) => {
      // get image name of envelope (e.g. envelope-blue) and set selectedEnvelopeName
      try {
        switch (envelopeImageName.split('/').slice(-1)[0].split('.')[0]) {
          case 'envelope-blue':
            setSelectedEnvelopeName(EnvelopeBlue);
            break;
          case 'envelope-green':
            setSelectedEnvelopeName(EnvelopeGreen);
            break;
          case 'envelope-pink':
            setSelectedEnvelopeName(EnvelopePink);
            break;
          case 'envelope-ivory':
            setSelectedEnvelopeName(EnvelopeIvory);
            break;
          case 'envelope-yellow':
            setSelectedEnvelopeName(EnvelopeYellow);
            break;
          case 'envelope-orange':
            setSelectedEnvelopeName(EnvelopeOrange);
            break;
          case 'envelope-birthday':
            setSelectedEnvelopeName(EnvelopeBirthday);
            break;
          case 'envelope-christmas':
            setSelectedEnvelopeName(EnvelopeChristmas);
            break;
          default:
            console.error('failed to split envelopeImageName');
            history.push('/notfound');
        }
      } catch {
        console.error('failed to split envelopeImageName');
        history.push('/notfound');
      }
    };

    // downloadLetterImage function
    const downloadLetterImage = async () => {
      // call cloud functions and get letter image data
      const functions = firebase.app().functions('asia-northeast1');

      const fetchLetterDocumentFunc = functions.httpsCallable(
        'fetchLetterDocument',
      );
      const fetchLetterImageFunc = functions.httpsCallable('fetchLetterImage');

      let letterDocumentData;
      if (queryUuid !== '' && queryPassword !== '') {
        try {
          const response: { data: string } = await fetchLetterDocumentFunc({
            queryUuid: queryUuid,
            queryPassword: queryPassword,
            uid: uid,
          });
          if (!Object.keys(response.data).length) {
            console.error(
              'failed to fetch letter document in fetchLetterDocumentFunc',
            );
            history.push('/notfound');
          }
          letterDocumentData = JSON.parse(response.data);
        } catch (e) {
          console.error(e);
          history.push('/notfound');
        }
        try {
          const response: { data: string } = await fetchLetterImageFunc({
            senderUid: letterDocumentData.senderUid,
            letterFileName: letterDocumentData.letterFileName,
          });
          const binary = base64.decode(response.data);
          const blob = new Blob([binary], { type: 'image/png' });
          const url = URL.createObjectURL(blob);
          // save blob url to local storage in order for letter animation
          localStorage.setItem('receivedLetter', url);
          // call setEnvelope function
          setEnvelope(letterDocumentData.envelopeImageName);
        } catch (e) {
          console.error(e);
          history.push('/notfound');
        }
      }
    };
    // call downloadLetterImage function
    downloadLetterImage();
  }, [history, user, queryUuid, queryPassword]);

  // if query parameters are found in request url, display letter animation
  if (urlParams) {
    const param = urlParams.split('&');
    if (param.length !== 2) {
      console.error('query parameters are incorrect in request url');
      history.push('/notfound');
    }
    let paramItem: string[] = [];
    // get uuid and password from query parameters
    for (let i = 0; i < param.length; i++) {
      paramItem = param[i].split('=');
      if (paramItem[0] === 'id') {
        queryUuid = paramItem[1];
      } else if (paramItem[0] === 'q') {
        queryPassword = paramItem[1];
      }
    }
    // if query parameters are not found in request url, show notFoundView
  } else {
    console.error('no query parameters found in request url');
    history.push('/notfound');
  }

  return (
    <div>
      <Header />
      <Container>
        {!selectedEnvelopeName ? (
          <main>
            <span
              className="uk-position-center"
              uk-spinner="true"
              style={{ color: 'orange' }}
            />
          </main>
        ) : (
          <Animation selectedEnvelopeName={selectedEnvelopeName || ''} />
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: radial-gradient(#ffffff, #fffaf6);
`;

export default Letter;
