import classes from './LetterContainer.module.css';

type Props = {
  documentId: string;
  letterBackgroundImageName: string;
  text: string;
  movePageText: string;
  movePageFunction: () => void;
  toggleState: () => void;
  deleteFunc?: () => void;
};

const LetterContentModal: React.FC<Props> = ({
  documentId,
  letterBackgroundImageName,
  text,
  movePageText,
  movePageFunction,
  toggleState,
  deleteFunc,
}) => {
  return (
    <div
      className="uk-animation-fade uk-animation-fast uk-modal-dialog-small"
      style={{ height: 'inherit' }}
    >
      <div key={documentId} id={documentId} style={{ width: '100%' }}>
        <div>
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
            style={{ color: '#999' }}
            onClick={() => {
              toggleState();
            }}
          ></button>
          <div
            className="uk-grid-collapse uk-child-width-expand@s "
            uk-grid="true"
            style={{ flexWrap: 'nowrap', height: '100%' }}
          >
            <div
              style={{
                verticalAlign: 'middle',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={letterBackgroundImageName}
                alt=""
                style={{
                  maxHeight: '400px',
                }}
              />
            </div>
            <div className="uk-text-middle uk-padding">
              <blockquote style={{ fontSize: '0.7rem', lineHeight: '1' }}>
                <p
                  className="uk-text-break"
                  style={{
                    maxHeight: '350px',
                    minHeight: '350px',
                    overflow: 'hidden',
                    color: '#000',
                  }}
                >
                  {text}
                </p>
              </blockquote>
              <div className="uk-grid uk-width-auto uk-align-right ">
                <button
                  className={
                    'uk-button uk-modal-close uk-width-1-3 uk-responsive-height uk-text-truncate ' +
                    classes.ukButtonPrimary
                  }
                  style={{ fontSize: '0.8rem' }}
                  onClick={() => {
                    movePageFunction();
                  }}
                >
                  {movePageText}
                </button>
                {deleteFunc !== undefined && deleteFunc !== null && (
                  <>
                    <button
                      className={
                        'uk-button uk-modal-close uk-button-danger uk-width-1-3 uk-float-right uk-responsive-height uk-text-truncate '
                      }
                      style={{ borderRadius: '5px', fontSize: '0.8rem' }}
                      onClick={() => {
                        deleteFunc();
                      }}
                    >
                      削除する
                    </button>
                  </>
                )}
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LetterContentModal;
