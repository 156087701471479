const moveImg = (name: string) => {
  const image: HTMLImageElement | null = document.querySelector(name);
  const area: HTMLDivElement | null = document.querySelector('.area');
  const areaRect = area?.getBoundingClientRect();

  area?.appendChild(image!);

  const moveAt = (pageX: number, pageY: number) => {
    if (image !== null) {
      const areaMaxX = areaRect!.width - image.offsetWidth;
      const areaMaxY = areaRect!.height - image.offsetHeight;

      const currentX =
        pageX - areaRect!.left - window.pageXOffset - image.offsetWidth / 2;
      const currentY =
        pageY - areaRect!.top - window.pageYOffset - image.offsetHeight / 2;

      // X 処理
      if (currentX > 0) {
        if (currentX < areaMaxX) {
          image.style.left = currentX + 'px';
        } else {
          image.style.left = areaMaxX + 'px';
        }
      } else {
        image.style.left = 0 + 'px';
      }

      // Y 処理
      if (currentY > 0) {
        if (currentY < areaMaxY) {
          image.style.top = currentY + 'px';
        } else {
          image.style.top = areaMaxY + 'px';
        }
      } else {
        image.style.top = 0 + 'px';
      }
    }
  }; // MoveAt

  const onMouseMove = (imageEvent: MouseEvent) => {
    moveAt(imageEvent.pageX, imageEvent.pageY);
  };

  if (image !== null) {
    document.addEventListener('mousemove', onMouseMove);

    image.onmouseup = () => {
      document.removeEventListener('mousemove', onMouseMove);
      image.onmouseup = null;
    };

    document.onmouseup = () => {
      document.removeEventListener('mousemove', onMouseMove);
      image.onmouseup = null;
    };

    image.ondragstart = () => {
      image.ondragstart = null;
      return false;
    };
  }
};

export default moveImg;
