import html2canvas from 'html2canvas';

const saveImage = (cb: () => void) => {
  const node = document.getElementById('hoge');
  const textArea = document.getElementById(
    'content',
  ) as HTMLTextAreaElement | null;
  const fieldset = document.getElementById('editview-textarea');
  const alternativeElement = document.createElement('div');

  if (node && textArea && fieldset) {
    // Replace Textarea to Div
    // Copy Styles, ClassNames and Attributes
    textArea.classList.forEach((className) => {
      alternativeElement.classList.add(className);
    });
    const text = textArea.value.replaceAll(/\r?\n/g, '<br />');
    // スマホでappendChild 実行後はscrollY の値が正しく取得できない
    // const scrollY = window.scrollY;
    // node の幅に対してpadding 15% を左右に入れているため、計算でdivの横幅を指定
    const alternativeWidth = node.clientWidth * 0.7;

    alternativeElement.style.fontFamily = textArea.style.fontFamily;
    alternativeElement.style.width = `${alternativeWidth}px`;
    alternativeElement.style.maxWidth = `${alternativeWidth}px`;
    alternativeElement.style.wordWrap = 'break-word';
    alternativeElement.innerHTML = text;

    textArea.remove();
    fieldset.appendChild(alternativeElement);

    html2canvas(node, { scrollX: 0, scrollY: 0 })
      .then((canvasElement) => {
        if (canvasElement) {
          canvasElement.toBlob((blob) => {
            const url = URL.createObjectURL(blob);
            localStorage.setItem('letter', url);
            cb();
          });
        }
      })
      .catch((error) => {
        console.error('something went wrong with html2canvas', error);
      });
  } else {
    console.error('node object is null');
  }
};

export default saveImage;
