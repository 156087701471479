import Header from '../components/common/Header';

const Policy = () => {
  return (
    <>
      <Header />
      <article className="uk-article uk-padding-small uk-margin uk-margin-left uk-margin-right">
        <h1 className="uk-article-title">プライバシーポリシー</h1>
        <p className="uk-article-meta">
          Written by Softech on May 22nd, 2021.{' '}
        </p>
        <p className="uk-text-lead">個人情報取り扱いに関する基本方針</p>
        <p>
          LightLetter（以下「本サービス」）は、個人情報の重要性を認識し、その適正な収集、利用、保護、管理を行うため、プライバシーポリシーを定め、次の通り運用します。
        </p>
        <p className="uk-text-lead">１．取得する個人情報</p>
        <p>
          本サービスにおいて、以下に定める通り、個人情報(個人情報保護法第2条第1項により定義された「個人情報」をいい、以下同様とします。)を含むお客様情報を取得します。
        </p>
        <ul className="uk-list uk-list-decimal">
          <li>
            本サービスのご利用にあたり収集する情報
            <ul>
              <li>
                外部サービス（Google、Twitter）で使用しているアカウント ID
              </li>
              <li>
                外部サービス（Google、Twitter）で使用しているプロフィール画像
              </li>
              <li>
                外部サービス（Google、Twitter）に登録しているメールアドレス
              </li>
              <li>電子手紙の文章</li>
              <li>電子手紙に貼り付けた画像</li>
              <li>マイフォントの作成時に利用した画像</li>
            </ul>
          </li>
          <li>
            本サービスはお客様情報の取得にあたっては、偽りその他不正の手段によらず、適正に取得します。また、お客様が本サービスを利用することによる取得以外の方法でお客様情報を取得する場合には、その利用目的を事前に通知又は公表します。
          </li>
        </ul>
        <p className="uk-text-lead">２．個人情報の利用目的</p>
        <ul className="uk-list uk-list-decimal">
          <li>
            <p>
              本サービスで取得したお客様情報を、下記の目的の範囲内で適正に取り扱います。お客様ご本人の同意なく定めた利用目的の範囲を超えて取り扱いません。
            </p>
            <table className="uk-table uk-table-divider">
              <thead>
                <tr>
                  <th>利用目的</th>
                  <th>利用目的詳細</th>
                  <th>利用する情報</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul>
                      <tr>
                        <li>本サービスの提供・維持・改善</li>
                      </tr>
                      <li>お客様への通知・対応等</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        本サービスにおけるご本人確認及び不正利用の防止のため
                      </li>
                      <li>本サービスの円滑な提供、維持及び改善のため</li>
                      <li>市場分析、マーケティングのため</li>
                      <li>
                        本サービスに関するご案内、お問い合わせ等への対応のため
                      </li>
                      <li>
                        本サービスに関連する新たなサービスの通知又は提供のため
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>
                        外部サービスで使用しているアカウント
                        ID、プロフィール画像、お客様の外部サービス上の会員情報
                      </li>
                      <li>電子手紙の文章</li>
                      <li>電子手紙に貼り付けた画像</li>
                      <li>マイフォントの作成時に利用した画像</li>
                      <li>Cookie</li>
                      <li>ログ情報及び行動履歴情報</li>
                      <li>
                        本サービスの利用規約又は本ポリシーの変更、本サービスの停止・中止・契約解除その他本サービスに関する重要なお知らせ等の通知のため
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            本サービスは、前項の利用目的を、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には、別途定める方法により、お客様に通知又は公表します
          </li>
        </ul>
        <p className="uk-text-lead">３．個人情報の管理方法</p>
        個人情報の取り扱いにあたり管理責任者を置き、個人情報への不正アクセス、個人情報の紛失、改ざん、漏洩等の発生を防止するため、適正な安全対策を講じ、個人情報の適切な管理に努めます。
        <p className="uk-text-lead">
          ４．個人情報の取扱いに関する相談や苦情の連絡先
        </p>
        このページの内容についてのお問い合わせは、LightLetter
        個人情報取扱い窓口（lightletter.contact@gmail.com）までお寄せください。
        <p className="uk-text-lead">５．SSLセキュリティについて</p>
        本サービスは、お客様の個人情報を保護するために「SSL」に対応しています。
        SSL とは、Web サーバーと Web
        ブラウザーとの間に暗号化し送受信できる通信方法です。
        セキュリティ機能に対応したブラウザを使用することで、お客様が入力される個人情報が自動で暗号化されて通信されるため、万が一、送受信データが第三者に傍受された場合でも、内容が盗み取られる心配はありません。
        <p className="uk-text-lead">
          ６．Cookie（クッキー）・アクセスログについて
        </p>
        本サービスにアクセスされると、その情報はサーバーにアクセスログという形で記録されます。アクセスログの内容は、アクセス日時、ドメイン名、IP
        アドレス、使用している Web
        ブラウザの種類など、基本は個人を特定できる情報は含みません。これらの情報は本サービスの運用に利用いたします。
        <p className="uk-text-lead">７．プライバシーポリシーの変更</p>
        本サービスは、個人情報保護に関する取り組みを継続的に見直し、適宜その改善・向上に努める過程で、必要に応じて本プライバシーポリシーを改訂することがあります。
        <p className="uk-text-lead">８．外部リンク</p>
        本サービス中には他社サイトへのリンクがあります。リンク先となる他社サイトのプライバシーに関する声明またはコンテンツについては、当グループは一切責任を負いません。他社サイトをご利用になる際は、各ウェブサイトでプライバシーに関する声明をお読みになり、個人情報の取得、利用および共有の方法を理解することをお勧めします。
        <p className="uk-text-lead">
          ９．クレジットカード情報を含む個人情報に関して
        </p>
        本サービスがお客様からクレジットカード情報（カード名義・カード番号・有効期間）を取得する目的、クレジット情報の取得者名、提供先名、保存期間は次の通りです。
        <ul className="uk-list uk-list-decimal">
          <li>利用目的</li>
          ご購入された商品およびサービスの代金を決済するため
          <li>情報の提供先</li>
          お客様がご利用のクレジットカード発行会社、加盟または提携先、Stripe
          <li>保存期間</li>
          本サービスは「2.
          情報の提供先」へ提供した後はお客様のクレジットカード情報を保持しません。
        </ul>
        <p className="uk-text-lead">１０．電子手紙の内容について</p>
        第三者のみならず LightLetter
        開発チームのサーバー管理者であっても、通信上及びサーバー上での電子手紙の文章・電子手紙に貼り付けた画像・マイフォントの作成時に利用した画像を閲覧することは出来ないことを保証します。お客様の送受信した電子手紙のテキスト、画像などは、プライバシー性の高い情報として、お客様の同意がある場合または適用法に基づく場合を除き、本サービスの提供・維持・改善以外の目的には一切利用しません。
      </article>
    </>
  );
};
export default Policy;
