type Props = {
  title: string;
  toggleState: () => void;
  onDeleteDB: () => void;
};

const DeleteConfirmModal: React.FC<Props> = ({
  title,
  toggleState,
  onDeleteDB,
}) => {
  return (
    <div className="uk-animation-fade uk-animation-fast uk-modal-dialog-small">
      <div style={{ width: '100%', paddingTop: '40px' }}>
        <div>
          <p
            className="uk-position-top-left"
            style={{ color: '#000', margin: '20px' }}
          >
            {title === 'draft' && '下書きの削除'}
            {title === 'completed' && '送信済みの手紙を削除'}
          </p>
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
            style={{ color: '#999' }}
            onClick={() => {
              toggleState();
            }}
          ></button>
          <div
            className="uk-alert uk-alert-danger"
            uk-alert="true"
            style={{
              marginLeft: '20px',
              marginRight: '20px',
              borderRadius: '5px',
            }}
          >
            <p>
              <span
                className="uk-icon uk-margin-small-right"
                uk-icon="icon: warning"
              />
              {title === 'draft' &&
                '下書き中の手紙を削除します（削除後の復元はできません）'}
              {title === 'completed' &&
                '送信済みの手紙を削除します（削除後の復元はできません）'}
            </p>
          </div>
          <div
            className="uk-grid uk-position-bottom-right uk-width-auto "
            style={{ margin: '20px' }}
          >
            <button
              className="uk-button "
              type="button"
              style={{
                backgroundColor: 'rgb(239, 239, 239)',
                borderRadius: '5px',
                color: 'rgba(59, 59, 59, 1)',
              }}
            >
              キャンセル
            </button>
            <div className="uk-padding-remove" style={{ width: '5px' }}></div>
            <button
              className="uk-button uk-button-danger "
              type="button"
              style={{ borderRadius: '5px' }}
              onClick={() => {
                onDeleteDB();
              }}
            >
              削除する
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteConfirmModal;
