import firebase from '../config/firebase';
import 'firebase/firestore';
import { uploadAttachedImages } from './StorageUtils';

type fieldPath = {
  elements?: {
    id: string;
    className: string;
    src: string;
    top: string;
    left: string;
    transform: string;
  }[];
  text?: string;
  updatedDate?: firebase.firestore.Timestamp;
  letterBackgroundImageName?: string;
  envelopeImageName?: string;
  fontFamily?: string;
  status?: string;
};

// 受け取った配列からimage の画像を削除
const filterAttachedImages = (
  // 引数: 添付画像の要素の配列
  elements: {
    id: string;
    className: string;
    src: string;
    top: string;
    left: string;
    transform: string;
  }[],
) => {
  // 画像のベタデータとclass nameの組み合わせを用意
  // data: base64 string
  // idetifier: className [set-imageX]
  const attachedImages: { data: string; identifier: string }[] = [];
  let size = 0;

  // 容量削減のため, imageのsrcを削除する. stampはやらない
  const modElements = elements.map((element) => {
    if (element.className.includes('set-image')) {
      // srcの中身をattachedImagesのdataにセット
      attachedImages.push({
        data: element.src,
        identifier: element.className.split(' ')[0],
      });
      // 画像のサイズを集計
      size = size + element.src.length;
      // remove src attribute
      element.src = '';
    }
    return element;
  });

  return { modElements, attachedImages, size };
};

const updateLetterDocument = async (
  uid: string,
  documentId: string,
  data: fieldPath,
) => {
  const { modElements, attachedImages, size } = filterAttachedImages(
    data.elements!,
  );

  // 添付画像の合計サイズが5 MB以上のとき処理を実施しない。
  if (size > 5 * 1024 * 1024) return;

  data.elements = modElements;
  data.updatedDate = firebase.firestore.Timestamp.now();
  data.letterBackgroundImageName =
    localStorage.getItem('selectedLetterName') || '';
  data.envelopeImageName = localStorage.getItem('selectedEnvelopeName') || '';

  const db: firebase.firestore.Firestore = firebase.firestore();
  const lettersRef = db.collection('lightLetters').doc(uid);
  return lettersRef
    .collection('letters')
    .doc(documentId)
    .update(data)
    .then(async () => {
      await uploadAttachedImages(uid, documentId, attachedImages, size);
      console.log('document update success!');
    })
    .catch((error) => {
      console.error('Error update document: ', error);
    });
};

export default updateLetterDocument;
