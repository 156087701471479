import LetterDocument from './InterfaceOfLetterDocument';

export const isLetterDocument = (doc: unknown): doc is LetterDocument => {
  const {
    createdDate,
    creator,
    elements,
    envelopeImageName,
    fontFamily,
    letterBackgroundImageName,
    password,
    receiver,
    status,
    text,
    uid,
    uuid,
    updatedDate,
  } = doc as LetterDocument;

  if (
    createdDate !== undefined &&
    creator !== undefined &&
    elements !== undefined &&
    envelopeImageName !== undefined &&
    fontFamily !== undefined &&
    letterBackgroundImageName !== undefined &&
    password !== undefined &&
    receiver !== undefined &&
    status !== undefined &&
    text !== undefined &&
    uid !== undefined &&
    uuid !== undefined &&
    updatedDate !== undefined
  ) {
    return true;
  } else {
    console.log(doc);
    return false;
  }
};
