import { useState, useEffect, useContext, useCallback } from 'react';
import classes from './EditView.module.css';
import TextareaAutosize from 'react-textarea-autosize';
import CustomContextMenu from '../components/edit/CustomContextMenu';
import Header from '../components/common/Header';
import LetterPaperSimple1 from '../assets/imgs/letter-paper-simple1.png';
import SelectedImages from '../components/edit/SelectedImages';
import SelectedStamps from '../components/edit/SelectedStamps';
import SavedImages from '../components/edit/SavedImages';
import WarnNumberOfDraftModal from '../components/edit/WarnNumberOfDraftModal';
import SendLimitModal from '../components/edit/SendLimitModal';
import Toolbar from '../components/edit/Toolbar';
import fetchLetterDocument from '../lib/FetchLetterDocument';
import countDraftLetters from '../lib/CountDraftLetters';
import letterDocument from '../lib/InterfaceOfLetterDocument';
import moveImg from '../components/edit/MoveImage';
import FontRegisterModal from '../components/edit/FontRegisterModal';
import { AuthContext } from '../AuthProvider';
import { FontContext } from '../FontProvider';
import { PlanContext } from '../PlanProvider';
import 'firebase/storage';
import { useHistory, Prompt } from 'react-router-dom';
import UIkit from 'uikit';

const EditView = () => {
  const { user } = useContext(AuthContext);
  const { plan } = useContext(PlanContext);
  const { url, myFontExists, loadingFontState } = useContext(FontContext);

  const [activeFontFamily, setActiveFontFamily] = useState<string>();
  const [isOverflown, setIsOverflown] = useState<boolean>(false);
  const [isFetchedDocument, setIsFetchedDocument] = useState<boolean>(false);
  const [letterDoc, setLetterDoc] = useState<letterDocument>();
  const [selectedImageList, setSelectedImageList] = useState<string[]>([]);
  const [selectedStampList, setSelectedStampList] = useState<string[]>([]);
  const [selectedLetterName, setSelectedLetterName] = useState<string | null>(
    localStorage.getItem('selectedLetterName'),
  );
  const [textValue, setTextValue] = useState<string>('');
  const [uploadFile, setUploadfile] = useState<File | null>();
  const [isContextMenuShown, setContextMenuShown] = useState<boolean>(false);
  const [menuPoint, setMenuPoint] = useState<{ x: number; y: number }>();
  const [rightClickedElement, setRightClickedElement] =
    useState<HTMLImageElement>();
  const [numberOfDraft, setNumberOfDraft] = useState<number>(0);
  const [shownLimitDocumentModal, setShownLimitDocumentModal] =
    useState<boolean>(false);
  const [shownWarnNumberOfDocumentModal, setShownWarnNumberOfDocumentModal] =
    useState<boolean>(true);
  const [isLoadedFontFace, setIsLoadedFontFace] = useState<boolean>(false);
  const [sizeOfImages, setSizeOfImages] = useState<number>(0);
  const [sendLimitModal, setSendLimitModal] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] =
    useState<boolean>(true);
  const [userAgent, setUserAgent] = useState<string>('pc');

  const history = useHistory();
  const documentId: string | null = localStorage.getItem('documentId');

  // firestore から取得した添付画像のサイズを取得する処理
  const onFetchImages = useCallback((size: number) => {
    setSizeOfImages(size);
  }, []);

  // TextArea(本文)に文字を入力するたびに発生する処理
  // TextAreaの高さが一定値を超えたらisOverflownのフラグを切り替える
  const handleKeyAction = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const area = document.querySelector('#editview-textarea') as HTMLElement;
    const areaRect = area.getBoundingClientRect();
    const textTarget = e.target as HTMLTextAreaElement;
    if (areaRect.height < textTarget.scrollHeight) {
      setIsOverflown(true);
    } else if (isOverflown === true) {
      setIsOverflown(false);
    }
  };

  useEffect(() => {
    const uid = user ? user.uid : 'guests';
    if (uid === 'guests' || plan !== 'free') return;
    const checkSentLetters = async () => {
      const number = await countDraftLetters(uid, 'public');

      if (number && number >= 30) {
        setSendLimitModal(true);
      }
    };
    checkSentLetters();
  }, []);

  useEffect(() => {
    // PC, sp/tablet判定
    if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
      setUserAgent(navigator.userAgent);
    } else {
      setUserAgent('pc');
    }

    // マウスがクリックしている間に発生する処理
    const onMouseDown = (event: MouseEvent) => {
      const element = event.target as HTMLImageElement;
      moveImg('.' + element.classList[0]);
    };

    // 右クリック時に発生する処理
    const onContextMenu = (event: MouseEvent) => {
      const element = event.target as HTMLImageElement;
      event.preventDefault();
      element.removeEventListener('mousemove', onMouseDown);
      const area: HTMLDivElement | null = document.querySelector('.area');
      const areaRect = area?.getBoundingClientRect();
      setMenuPoint({
        x: event.pageX - areaRect!.left - window.pageXOffset,
        y: event.pageY - areaRect!.top - window.pageYOffset,
      });
      setRightClickedElement(element);
      setContextMenuShown(true);
    };

    // 画面を更新したり、移動する際に抑制するウィンドウを表示するためのイベントリスナー
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      event.returnValue = '';
    };

    // firestore に保存済みのimage/stampのimg要素にイベントリスナーを付与
    const imageElements: NodeListOf<HTMLImageElement> =
      document.querySelectorAll('[class*=saved]');
    imageElements.forEach((imageElement: HTMLImageElement) => {
      imageElement.addEventListener('mousedown', onMouseDown);
      imageElement.addEventListener('contextmenu', onContextMenu);
    });

    // ユーザが添付画像をアップロードしたあと、画面に表示する処理
    const showUploadImage = (e: ProgressEvent<FileReader>) => {
      // 画像の制限は合計5 MB
      const limitSizeOfImages = 5 * 1024 * 1024;

      if (typeof e.target?.result === 'string') {
        // 制限サイズを超えていなければimage 配列のstate を更新
        // else : 超えている場合、通知を画面に表示
        if (sizeOfImages + e.target.result.length < limitSizeOfImages) {
          setSelectedImageList((prevList) => [
            ...prevList,
            e.target!.result!.toString(),
          ]);
          setSizeOfImages(
            (prevValue) => prevValue + e.target!.result!.toString().length,
          );
        } else {
          UIkit.notification({
            message: '画像の合計サイズが5 MBを超えています。',
            pos: 'top-center',
            timeout: 750,
            status: 'danger',
          });
        }
        setUploadfile(null);
      }
    };

    // ユーザの画像アップロードが完了した際のイベントリスナー
    const reader = new FileReader();
    reader.addEventListener('loadend', showUploadImage);
    if (uploadFile) {
      reader.readAsDataURL(uploadFile);
    }

    // firestore からdocument を取得する処理
    const fetchLetterDocumentData = async () => {
      // documentIdがlocalstorageにない場合、実行されない
      if (documentId === null) return;

      const uid = user ? user.uid : 'guests';
      try {
        const data = await fetchLetterDocument(uid, documentId);
        // data がnull の場合、処理を止める
        if (data === null) return;
        // 取得したdata をstate に反映
        setLetterDoc(data);
        setTextValue(data.text);
        setActiveFontFamily(data.fontFamily);
        if (localStorage.getItem('selectedLetterName') === null) {
          setSelectedLetterName(data.letterBackgroundImageName);
        }
      } catch {
        console.error('fetch document error');
      }
      setIsFetchedDocument(true);
    };

    // ユーザの下書きの枚数を取得
    const countDraftLettersData = async () => {
      const uid = user ? user.uid : '';
      // uid がない場合、処理しない
      if (uid === '') return;
      try {
        const number = await countDraftLetters(uid, 'draft');
        if (number) {
          setNumberOfDraft(number);
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Cloud Storage 上にMyFontが存在
    if (myFontExists) {
      // url としてデータを取得できているケース
      if (url) {
        const fontface = new FontFace('MyFont', `url(${url})`, {
          style: 'normal',
          weight: '400',
        });
        // fontface のロードが完了時に発火
        fontface
          .load()
          .then((font) => {
            document.fonts.add(font);
            setIsLoadedFontFace(true);
          })
          .catch((error) => {
            console.log('fontface failed ' + error);
          });
      }
    }

    // documentIdが存在する場合はフォントはdata.fontFamilyが確定するので処理不要
    // documentIdが存在しない場合
    // デフォルトフォントはMyFont を生成しているかどうかで分岐
    if (!documentId) {
      if (!loadingFontState) {
        if (myFontExists) {
          setActiveFontFamily('MyFont');
        } else {
          setActiveFontFamily('Noto Serif JP');
        }
      }
    }

    if (!isFetchedDocument) {
      fetchLetterDocumentData();
      countDraftLettersData();
    }

    // リロードおよび画面遷移時に警告のウィンドウを出すイベントリスナの追加
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      imageElements.forEach((imageElement: HTMLImageElement) => {
        imageElement.removeEventListener('mousedown', onMouseDown);
        imageElement.removeEventListener('contextmenu', onContextMenu);
      });
      window.removeEventListener('beforeunload', onBeforeUnload);
      reader.removeEventListener('loadend', showUploadImage);
    };
  }, [
    uploadFile,
    documentId,
    isFetchedDocument,
    url,
    user,
    loadingFontState,
    myFontExists,
    sizeOfImages,
  ]);

  return (
    <div id="editview">
      <Prompt
        when={shouldBlockNavigation}
        message="Changes you made may not be saved."
      />
      <Header />
      {userAgent === 'pc' ? (
        <div className="uk-sticky-fixed" uk-sticky="true">
          <Toolbar
            callback={
              plan === 'free' && sendLimitModal
                ? () => setSendLimitModal(true)
                : undefined
            }
            activeFontFamily={activeFontFamily!}
            onChangeActiveFont={(activeFontFamily) => {
              setActiveFontFamily(activeFontFamily);
            }}
            onImportImage={(imagefile) => {
              setUploadfile(imagefile);
            }}
            onImportStamp={(stampfile) => {
              const imageElements: NodeListOf<HTMLImageElement> =
                document.querySelectorAll('[class*=set-stamp]');
              if (imageElements.length > 9) {
                UIkit.notification({
                  message: 'スタンプの数が上限(10個)を超えています。',
                  pos: 'top-center',
                  timeout: 750,
                  status: 'danger',
                });
              } else {
                setSelectedStampList((prevList) => [...prevList, stampfile]);
              }
            }}
            onUpdateDatabase={() => {
              // firestore に保管等の処理をした際、
              // ローカルのstate を初期化
              // 'firestoreに保管 → 最新のデータをフェッチ'を実現するため
              setUploadfile(null);
              setSelectedImageList([]);
              setSelectedStampList([]);
              setIsFetchedDocument(false);
              setLetterDoc(undefined);
              setTextValue('');
              setSizeOfImages(0);
            }}
            // 保管している下書きの数をToolbarへ
            // 数が超えている場合、警告を表示
            numberOfDraft={numberOfDraft}
            limitDraft={() => {
              setShownLimitDocumentModal(true);
            }}
            isLoadedFontFace={isLoadedFontFace}
            allowNavigation={() => {
              setShouldBlockNavigation(false);
            }}
            userAgent={userAgent}
          />
        </div>
      ) : (
        <div>
          <Toolbar
            activeFontFamily={activeFontFamily!}
            onChangeActiveFont={(activeFontFamily) => {
              setActiveFontFamily(activeFontFamily);
            }}
            onImportImage={(imagefile) => {
              setUploadfile(imagefile);
            }}
            onImportStamp={(stampfile) => {
              const imageElements: NodeListOf<HTMLImageElement> =
                document.querySelectorAll('[class*=set-stamp]');
              if (imageElements.length > 9) {
                UIkit.notification({
                  message: 'スタンプの数が上限(10個)を超えています。',
                  pos: 'top-center',
                  timeout: 750,
                  status: 'danger',
                });
              } else {
                setSelectedStampList((prevList) => [...prevList, stampfile]);
              }
            }}
            onUpdateDatabase={() => {
              // firestore に保管等の処理をした際、
              // ローカルのstate を初期化
              // 'firestoreに保管 → 最新のデータをフェッチ'を実現するため
              setUploadfile(null);
              setSelectedImageList([]);
              setSelectedStampList([]);
              setIsFetchedDocument(false);
              setLetterDoc(undefined);
              setTextValue('');
              setSizeOfImages(0);
            }}
            // 保管している下書きの数をToolbarへ
            // 数が超えている場合、警告を表示
            numberOfDraft={numberOfDraft}
            limitDraft={() => {
              setShownLimitDocumentModal(true);
            }}
            isLoadedFontFace={isLoadedFontFace}
            allowNavigation={() => {
              setShouldBlockNavigation(false);
            }}
            userAgent={userAgent}
          />
        </div>
      )}
      {
        // TextArea が一定の高さ or 文字数に至った際に表示する要素
        isOverflown && (
          <div
            className={classes.ukAlertDanger + ' uk-alert-danger'}
            data-uk-alert
          >
            <p>
              <span className={classes.ukSpan} uk-icon="icon: warning" />
              手紙の枠外に文字が入力されています。
              <br />
              枠外の文字は出力されないためご注意ください。
            </p>
          </div>
        )
      }
      {documentId && !letterDoc ? (
        // documentIdがある場合、読み込み終わるまでspinnerを表示
        <main className={classes.editviewMain + ' editview-main'}>
          <span
            className="uk-position-center"
            uk-spinner="true"
            style={{ color: 'orange' }}
          />
        </main>
      ) : (
        <main
          className={classes.editviewMain + ' editview-main'}
          style={{ fontFamily: activeFontFamily }}
        >
          <div className={classes.ukPositionCenter + ' uk-position-center'}>
            <div id="hoge">
              <img
                className="uk-box-shadow-small"
                src={selectedLetterName || LetterPaperSimple1}
                alt=""
                width="800px"
              />
              {loadingFontState || activeFontFamily === undefined ? ( // activeFontFamily がundefined の場合、spinnerを表示
                <div
                  className={
                    classes.ukOverlayDefault +
                    ' uk-overlay-default uk-position-cover'
                  }
                >
                  <span
                    className="uk-position-center"
                    uk-spinner="true"
                    style={{ color: 'orange' }}
                  />
                </div>
              ) : (
                <div
                  className={
                    classes.ukOverlayDefault +
                    ' uk-overlay-default uk-position-cover'
                  }
                >
                  <fieldset
                    id="editview-textarea"
                    className={classes.ukFieldset + ' uk-fieldset'}
                  >
                    {/* set maxLength as 2000. this is because two thousand "l"
                              which is the narrowest letter can fit a whole single page. */}
                    <TextareaAutosize
                      id="content"
                      style={{ fontFamily: activeFontFamily }}
                      maxLength={2000}
                      className={classes.ukTextarea + ' uk-textarea apply-font'}
                      placeholder="ここに文章を入力しましょう"
                      defaultValue={textValue}
                      onChange={(event) => {
                        handleKeyAction(event);
                      }}
                    />
                  </fieldset>
                </div>
              )}
              <div
                id="images"
                className={
                  classes.ukOverlayImageArea +
                  ' uk-overlay-default uk-position-cover area'
                }
              >
                {
                  // firestore にデータを保管している場合、画像を表示する要素
                  letterDoc !== undefined && (
                    <SavedImages
                      letterDoc={letterDoc}
                      onFetchImages={onFetchImages}
                    />
                  )
                }
                {
                  // ユーザがアップロードした画像の要素(配列) ローカルのstate
                  <SelectedImages
                    selectedImageList={selectedImageList}
                    onImportImageList={(imageList, size) => {
                      setSizeOfImages((previousValue) => previousValue - size);
                      setSelectedImageList(imageList);
                    }}
                  />
                }
                {
                  // ユーザが使用しているstamp の要素(配列) ローカルのstate
                  <SelectedStamps
                    selectedStampList={selectedStampList}
                    onImportStampList={(stampList) => {
                      setSelectedStampList(stampList);
                    }}
                  />
                }
                {
                  // 右クリックの要素
                  // State で管理している画像/スタンプ用
                  isContextMenuShown && (
                    <CustomContextMenu
                      menuPoint={menuPoint!}
                      toggleContextMenu={(flag) => {
                        setContextMenuShown(flag);
                      }}
                      // 右クリックメニュー内 '削除'の処理
                      removeElement={() => {
                        if (rightClickedElement) {
                          if (
                            rightClickedElement.className.includes('set-image')
                          ) {
                            setSizeOfImages(
                              (previousValue) =>
                                previousValue - rightClickedElement.src.length,
                            );
                          }
                          rightClickedElement.remove();
                        }
                      }}
                      // 右クリックメニュー内 '縮小/拡大'の処理
                      resizeElement={(action: string) => {
                        const resizeWeight = 0.1;
                        if (rightClickedElement) {
                          switch (action) {
                            case 'minus': {
                              let size = 0;
                              if (!rightClickedElement.style.transform) {
                                // undefinedの場合、100%想定
                                size = 1;
                              } else {
                                // 現在のscaleを取得し、resizeWeight分[%]減少
                                // replace で数字のみ抽出
                                size = Number(
                                  rightClickedElement.style.transform
                                    .toString()
                                    .replace(/[^0-9.]/g, ''),
                                );
                                size = size - resizeWeight;
                              }
                              // styleに反映
                              rightClickedElement.style.transform =
                                'scale(' + size + ')';
                              break;
                            }
                            case 'plus': {
                              let size = 0;
                              if (!rightClickedElement.style.transform) {
                                // undefinedの場合、100%想定
                                size = 1;
                              } else {
                                // 現在のscaleを取得し、resizeWeight分[%]増加
                                // replace で数字のみ抽出
                                size = Number(
                                  rightClickedElement.style.transform
                                    .toString()
                                    .replace(/[^0-9.]/g, ''),
                                );
                                size = size + resizeWeight;
                              }
                              // styleに反映
                              rightClickedElement.style.transform =
                                'scale(' + size + ')';
                              break;
                            }
                            default: {
                              return;
                            }
                          }
                        }
                      }}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </main>
      )}
      {
        // 下書きボタン押下時、下書きの枚数が10枚以上の場合に表示される要素
        numberOfDraft > 9 && shownLimitDocumentModal && (
          <WarnNumberOfDraftModal
            content={'マイページから不要な下書きを削除してください。'}
            numberOfDraft={numberOfDraft}
            toggleModal={() => {
              setShownLimitDocumentModal(false);
            }}
            movePage={() => {
              history.push('/mypage/draft');
            }}
          />
        )
      }
      {
        // 下書きの枚数が10枚以上かつ documentIdが存在しない場合警告を表示
        // documentIdが存在しない場合: 下書き or プレビューを実行していない
        numberOfDraft > 9 && !documentId && shownWarnNumberOfDocumentModal && (
          <WarnNumberOfDraftModal
            content={
              '下書きを保存する場合、マイページから不要な下書きを削除してください。'
            }
            numberOfDraft={numberOfDraft}
            toggleModal={() => {
              setShownWarnNumberOfDocumentModal(false);
            }}
            movePage={() => {
              history.push('/mypage/draft');
            }}
          />
        )
      }
      {
        // Toolbar でMyfont を指定するときの要素
        // guest: ログインモーダルの表示
        // Free: /payment 画面へ遷移するモーダル
        // 有料会員: /mypage/myfonts 画面へ遷移するモーダル
        <div id="ask-modal" uk-modal="true; container: editview">
          <FontRegisterModal
            user={user}
            plan={plan}
            movePage={(plan) => {
              switch (plan) {
                case 'free': {
                  history.push('/payment/plan');
                  break;
                }
                default: {
                  history.push('/mypage/myfonts');
                }
              }
            }}
          />
        </div>
      }
      {plan === 'free' && sendLimitModal && (
        <SendLimitModal
          content={
            '手紙を送信する場合、マイページから不要な送信済みの手紙を削除してください。'
          }
          toggleModal={() => {
            setSendLimitModal(true);
          }}
          movePage={() => {
            history.push('/mypage/completed');
          }}
        />
      )}
    </div>
  );
};
export default EditView;
