import { useEffect, useState, createContext, FC } from 'react';
import firebase from './config/firebase';

type ContextProps = {
  user: firebase.User | null;
  authenticated: boolean;
  setUser: (value: firebase.User | null) => void;
  loadingAuthState: boolean;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

export const AuthProvider: FC = ({ children }): JSX.Element => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  useEffect(() => {
    const unlisten = firebase
      .auth()
      .onAuthStateChanged((user: firebase.User | null) => {
        setUser(user);
        setLoadingAuthState(false);
      });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, authenticated: user !== null, setUser, loadingAuthState }}
    >
      {children}
    </AuthContext.Provider>
  );
};
