const countImages = (className: string) => {
  const imgs: NodeListOf<HTMLImageElement> =
    document.querySelectorAll('[class*=saved]');
  let tmp: number[] = [];

  if (imgs !== null) {
    imgs.forEach((img) => {
      img.classList.forEach((value: string) => {
        if (value.match(className)) {
          tmp = tmp.concat(parseInt(value.slice(9)));
        }
      });
    });
  }
  tmp.sort((a, b) => a - b);
  const maxNum: number = tmp.length > 0 ? tmp[tmp.length - 1] : 0;

  return maxNum;
};

export default countImages;
