import classes from './Footer.module.css';
import { useHistory } from 'react-router-dom';

const Footer = () => {
  const history = useHistory();

  return (
    <div className={classes.ukMarginTop + ' footer'} uk-sticky="true">
      <div
        className="uk-grid-small uk-padding-small uk-padding-remove-bottom uk-margin-small-left"
        uk-grid="true"
      >
        <div className="uk-container">
          <button
            className={classes.ukButton + ' uk-button uk-button-text'}
            onClick={() => {
              history.push('/terms');
            }}
          >
            利用規約
          </button>
        </div>
        <div className="uk-container">
          <button
            className={classes.ukButton + ' uk-button uk-button-text'}
            onClick={() => {
              history.push('/policy');
            }}
          >
            プライバシーポリシー
          </button>
        </div>
        <div className="uk-container">
          <button
            className={classes.ukButton + ' uk-button uk-button-text'}
            onClick={() => {
              history.push('/commercialtransactions');
            }}
          >
            特定商取引法に基づく表示
          </button>
        </div>
      </div>
      <div className="uk-container uk-padding-small">
        <p className="uk-text-small">
          Copyright © 2020-2021 LightLetter. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
