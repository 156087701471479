type Props = {
  content: string;
  numberOfDraft: number;
  toggleModal: () => void;
  movePage: () => void;
};

const WarnNumberOfDraftModal: React.FC<Props> = ({
  content,
  numberOfDraft,
  toggleModal,
  movePage,
}) => {
  return (
    <div
      className="uk-position-cover uk-overlay uk-overlay-primary uk-animation-fade uk-animation-fast uk-modal-close"
      style={{
        marginTop: '80px',
        marginBottom: '0px',
        background: 'rgba(0, 0, 0, .32)',
      }}
      onClick={() => {
        toggleModal();
      }}
    >
      <div
        className="uk-position-center uk-position-middle uk-overlay-default uk-animation-fade uk-animation-fast"
        style={{
          width: '600px',
          height: '300px',
          background: 'rgba(255, 255, 255, 1)',
          top: '30%',
          borderRadius: '5px',
        }}
      >
        <div className="uk-animation-fade uk-animation-fast uk-modal-dialog-small">
          <p
            className="uk-position-top-left"
            style={{ color: '#000', margin: '20px' }}
          >
            下書きの削除
          </p>
          <div
            className="uk-alert uk-alert-danger"
            uk-alert="true"
            style={{
              marginLeft: '20px',
              marginRight: '20px',
              borderRadius: '5px',
            }}
          >
            <p>
              すでに下書きが {numberOfDraft} 通保存されています。 (※ 最大 10 通)
              <br />
              {content}
            </p>
          </div>
          <div
            className="uk-grid uk-position-bottom-right uk-width-auto"
            style={{ margin: '20px' }}
          >
            <button
              className="uk-button uk-button-primary uk-modal-close"
              type="button"
              style={{
                borderRadius: '5px',
                backgroundColor: 'rgb(239, 239, 239)',
              }}
              onClick={() => {
                toggleModal();
              }}
            >
              下書きを保存しない
            </button>
            <div className="uk-padding-remove" style={{ width: '5px' }}></div>
            <button
              className={'uk-button uk-button-danger uk-modal-close'}
              type="button"
              style={{ borderRadius: '5px' }}
              onClick={() => {
                movePage();
              }}
            >
              マイページへ移動
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarnNumberOfDraftModal;
