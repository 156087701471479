import { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import { PlanContext } from './PlanProvider';
import { FontContext } from './FontProvider';
import SwitchRouter from './components/common/SwitchRouter';

const AppRouter = () => {
  const { loadingAuthState } = useContext(AuthContext);
  const { loadingPlanState } = useContext(PlanContext);
  const { loadingFontState } = useContext(FontContext);

  return (
    <>
      {loadingAuthState || loadingPlanState || loadingFontState ? (
        <span
          className="uk-position-center"
          uk-spinner="true"
          style={{ color: 'orange' }}
        />
      ) : (
        <Router>
          <SwitchRouter />
        </Router>
      )}
    </>
  );
};

export default AppRouter;
