import { useState, useEffect, useContext } from 'react';
import firebase from '../config/firebase';
import { loadStripe } from '@stripe/stripe-js';
import Header from '../components/common/Header';
import CouponModal from '../components/paymentView/CouponModal';
import classes from './PaymentView.module.css';
import { AuthContext } from './../AuthProvider';
import { PlanContext } from './../PlanProvider';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import SelectProvider from '../components/selectProvider/SelectProvider';
import { addYears } from 'date-fns';

const STRIPE_PUBLISHABLE_KEY = process.env
  .REACT_APP_STRIPE_PUBLISHABLE_KEY as string;
const TAX_RATE = process.env.REACT_APP_TAX_RATE as string;
const CREATE_PORTALLINK = process.env.REACT_APP_STRIPE_CREATE_PORTAL as string;

type Price = {
  // eslint-disable-next-line camelcase
  unit_amount: number;
};

type Product = {
  id: string;
  isSubscribed: boolean;
  name: string;
  // eslint-disable-next-line camelcase
  unit_amount: number;
};

type Coupon = {
  uid: string | null;
  expiration: firebase.firestore.Timestamp | null;
  plan: 'standard' | 'premium';
  valid: boolean;
};

const isCoupon = (coupon: unknown): coupon is Coupon => {
  try {
    const { uid, expiration, plan, valid } = coupon as Coupon;
    if (
      (uid === null || typeof uid === 'string') &&
      (expiration === null || expiration.toDate() instanceof Date) &&
      (plan === 'premium' || plan === 'standard') &&
      typeof valid === 'boolean'
    ) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

// 選択したプランの登録画面に遷移
const subscribe = async (product: Product, pathname: string) => {
  const checkoutSession = {
    collect_shipping_address: true,
    tax_rates: [TAX_RATE],
    allow_promotion_codes: true,
    line_items: [
      {
        price: product.id,
        quantity: 1,
      },
    ],
    success_url: window.location.origin + pathname,
    cancel_url: window.location.origin + pathname,
    metadata: {
      key: 'value',
    },
  };

  const db = firebase.firestore();
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) return;
  const docRef = await db
    .collection('customers')
    .doc(currentUser.uid)
    .collection('checkout_sessions')
    .add(checkoutSession);
  // Wait for the CheckoutSession to get attached by the extension
  // allow snap to be 'any' because its type is defined by stripe and mutable
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  docRef.onSnapshot(async (snap: any) => {
    const { error, sessionId } = snap.data();
    if (error) {
      // Show an error to your customer and then inspect your function logs.
      alert(`An error occured: ${error.message}`);
      document.querySelectorAll('button').forEach((b) => (b.disabled = false));
    }
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
      stripe?.redirectToCheckout({ sessionId });
    }
  });
};

const isPriceType = (price: unknown): price is Price => {
  // eslint-disable-next-line camelcase
  const { unit_amount } = price as Price;
  // eslint-disable-next-line camelcase
  if (typeof unit_amount === 'number') {
    return true;
  }
  return false;
};

type Props = {
  location: {
    state?: {
      isSingUp: boolean;
    };
  };
};

const PaymentView = (props: Props) => {
  const isLogin = !props.location.state?.isSingUp;
  const { from }: { from?: string } = useParams();
  const [products, setProducts] = useState<Product[]>();
  const [portalLink, setPortalLink] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const { user } = useContext(AuthContext);
  const { coupon } = useContext(PlanContext);
  const [couponModal, setCouponModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const firestore = firebase.firestore();

  useEffect(() => {
    if (
      coupon !== null &&
      coupon !== undefined &&
      coupon.valid &&
      coupon.expiration!.toDate().getTime() > Date.now()
    ) {
      console.log(coupon);
      history.push('/');
    }
    // firestoreから有効な商品一覧を取ってくる
    const fetchProducts = async () => {
      if (!user) return;
      const productsSnap = await firebase
        .firestore()
        .collection('products')
        .where('active', '==', true)
        .get();

      const subscriptionsSnap = await firebase
        .firestore()
        .collection('customers')
        .doc(user.uid)
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .get();

      const res: Product[] = [];
      for (const doc of productsSnap.docs) {
        const name = doc.data().name;
        const productSnap = await doc.ref
          .collection('prices')
          .where('active', '==', true)
          .get();
        const id = productSnap.docs[0].id;
        const data = productSnap.docs[0].data();
        if (!isPriceType(data)) return;
        const isSubscribed = !!subscriptionsSnap.docs.find((doc) => {
          return doc.data().items[0].plan.id === id;
        });
        res.push({
          id,
          isSubscribed,
          name,
          // eslint-disable-next-line camelcase
          unit_amount: data.unit_amount,
        });
      }
      setProducts(res);
    };

    // stripeの管理画面用のurlを発行
    const fetchPortalLink = async () => {
      if (!user) return;
      const stripeInfo = await firebase
        .firestore()
        .collection('customers')
        .doc(user.uid)
        .get();

      if (!stripeInfo.exists) return;
      const functionRef = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable(CREATE_PORTALLINK);
      try {
        const { data } = await functionRef({
          returnUrl: `${window.location.origin}/payment/plan`,
        });
        setPortalLink(data.url);
      } catch (e) {
        console.error(e);
      }
    };
    const checkFirstUse = async () => {
      if (!user) return;
      if (typeof from === 'string' && from !== 'plan') {
        const res = await firebase
          .firestore()
          .collection('firstUseAppDate')
          .doc(user?.uid)
          .get();
        if (res.exists) {
          history.push(`/${from}`);
          return;
        }
        setCouponModal(true);
        firebase
          .firestore()
          .collection('firstUseAppDate')
          .doc(user?.uid)
          .set({ created_at: new Date() });
      }
    };

    fetchProducts();
    fetchPortalLink();
    checkFirstUse();
  }, [user, from, history, coupon]);

  if (!user) {
    return (
      <>
        <Header />
        <SelectProvider isLogin={isLogin} />
      </>
    );
  }

  if (!products || products.length !== 2 || isUploading) {
    return (
      <span
        className="uk-position-center"
        uk-spinner="true"
        style={{ color: 'orange' }}
      />
    );
  }

  const standard = products.find(
    (product) => product.name === 'Standard プラン',
  );
  const premium = products.find((product) => product.name === 'Premium プラン');

  return (
    <>
      <Header
        customHeaderMenu={
          <li className="uk-hidden@m">
            <button
              className={
                classes.menuIcon + ' uk-navber-item uk-button uk-button-reset'
              }
              type="button"
              uk-toggle="target: #offcanvas-push"
              uk-icon="menu"
            />
          </li>
        }
      />
      <div className={classes.line} />
      <div className={classes.line2} />
      <div className={classes.line3} />
      <div className={classes.line4} />
      <div className={classes.productArea}>
        <div
          className="uk-grid-match uk-child-width-expand@s uk-text-center"
          uk-grid="true"
        >
          <div>
            <div
              className={`${classes.card} uk-card uk-card-default uk-card-body`}
            >
              <p className="uk-text-large uk-text-emphasis">Free プラン</p>
              <p>無料</p>
              <button
                uk-tooltip={
                  !products?.some((product) => product.isSubscribed) ||
                  (coupon !== null &&
                    coupon !== undefined &&
                    coupon.valid &&
                    coupon.expiration!.toDate().getTime() > Date.now())
                    ? 'cls: disable'
                    : '移動先の画面にて「プランをキャンセル」をご選択ください。'
                }
                className={`${classes.registerButton} uk-button`}
                onClick={() => {
                  if (products.find((product) => product.isSubscribed)) {
                    window.location.assign(portalLink);
                    return;
                  }
                }}
                disabled={
                  !products?.some((product) => product.isSubscribed) ||
                  (coupon !== null &&
                    coupon !== undefined &&
                    coupon.valid &&
                    coupon.expiration!.toDate().getTime() > Date.now())
                }
                key="free"
              >
                {coupon !== null &&
                coupon !== undefined &&
                coupon.valid &&
                coupon.expiration!.toDate().getTime() > Date.now()
                  ? 'クーポン利用中'
                  : !products?.some((product) => product.isSubscribed)
                  ? '登録中'
                  : '登録する'}
              </button>
              <div className={`${classes.discription} uk-text-left`}>
                <ul className={`${classes.listsample}, ${classes.check}`}>
                  <li>
                    <p>手紙の作成とURLの発行</p>
                  </li>
                  <li>
                    <p>下書きの管理（10枚）</p>
                  </li>
                  <li>
                    <p>受け取った手紙の履歴確認</p>
                  </li>
                  <li>
                    <p>送った手紙の履歴確認</p>
                  </li>
                  <li>
                    <p>送信できる手紙の枚数: 30枚</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`${classes.card} uk-card uk-card-default uk-card-body`}
            >
              <p className="uk-text-large uk-text-emphasis">Standard プラン</p>
              <p>110円（税込み）</p>
              {standard && (
                <button
                  className={`${classes.registerButton} uk-button`}
                  disabled={
                    standard.isSubscribed ||
                    (coupon !== null &&
                      coupon !== undefined &&
                      coupon.valid &&
                      coupon.expiration!.toDate().getTime() > Date.now())
                  }
                  key={standard.name}
                  onClick={() => {
                    if (products.find((product) => product.isSubscribed)) {
                      window.location.assign(portalLink);
                      return;
                    }
                    setIsUploading(true);
                    subscribe(standard, location.pathname);
                  }}
                >
                  {coupon !== null &&
                  coupon !== undefined &&
                  coupon.valid &&
                  coupon.expiration!.toDate().getTime() > Date.now()
                    ? 'クーポン利用中'
                    : standard.isSubscribed
                    ? '登録中'
                    : '登録する'}
                </button>
              )}
              <div className={`${classes.discription} uk-text-left`}>
                <ul className={`${classes.listsample}, ${classes.check}`}>
                  <li>
                    <p>手紙の作成とURLの発行</p>
                  </li>
                  <li>
                    <p>下書きの管理（10枚）</p>
                  </li>
                  <li>
                    <p>受け取った手紙の履歴確認</p>
                  </li>
                  <li>
                    <p>送った手紙の履歴確認</p>
                  </li>
                  <li>
                    <p>マイフォントの作成と使用</p>
                  </li>
                  <li>
                    <p>送信できる手紙の枚数: 無制限</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`${classes.card} uk-card uk-card-default uk-card-body`}
            >
              <p className="uk-text-large uk-text-emphasis">Premium プラン</p>
              <p>220円（税込み）</p>
              {premium && (
                <button
                  className={`${classes.registerButton} uk-button`}
                  disabled={
                    premium.isSubscribed ||
                    (coupon !== null &&
                      coupon !== undefined &&
                      coupon.valid &&
                      coupon.expiration!.toDate().getTime() > Date.now())
                  }
                  key={premium.name}
                  onClick={() => {
                    if (products.find((product) => product.isSubscribed)) {
                      window.location.assign(portalLink);
                      return;
                    }
                    setIsUploading(true);
                    subscribe(premium, location.pathname);
                  }}
                >
                  {coupon !== null &&
                  coupon !== undefined &&
                  coupon.valid &&
                  coupon.expiration!.toDate().getTime() > Date.now()
                    ? 'クーポン利用中'
                    : premium.isSubscribed
                    ? '登録中'
                    : '登録する'}
                </button>
              )}
              <div className={`${classes.discription} uk-text-left`}>
                <ul className={`${classes.listsample}, ${classes.check}`}>
                  <li>
                    <p>手紙の作成とURLの発行</p>
                  </li>
                  <li>
                    <p>下書きの管理（10枚）</p>
                  </li>
                  <li>
                    <p>受け取った手紙の履歴確認</p>
                  </li>
                  <li>
                    <p>送った手紙の履歴確認</p>
                  </li>
                  <li>
                    <p>マイフォントの作成と使用</p>
                  </li>
                  <li>
                    <p>
                      封筒、便箋、スタンプを全て使用可能
                      <br />
                      （スタンプは PC のみ対応）
                    </p>
                  </li>
                  <li>
                    <p>送信できる手紙の枚数: 無制限</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.productArea}>
        <p>クーポンコードをお持ちの方はこちらから登録してください。</p>

        {(standard?.isSubscribed || premium?.isSubscribed) && (
          <p className="uk-text-warning">
            すでに有料プランにご登録頂いている場合、クーポンを有効化するためには一度「Free
            プラン」に変更頂く必要があります。
            <br />
            Free
            プランの「登録する」→「プランをキャンセル」を実行してから再度クーポンコードの入力をお試しください。
          </p>
        )}
        <div className={classes.main}>
          <input
            disabled={standard?.isSubscribed || premium?.isSubscribed}
            type="text"
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <span className={classes.couponContainer}>
            <button
              disabled={standard?.isSubscribed || premium?.isSubscribed}
              onClick={async () => {
                try {
                  const snapshot = await firestore
                    .collection('coupons')
                    .doc(couponCode)
                    .get();
                  if (snapshot.exists) {
                    const data = snapshot.data();
                    if (isCoupon(data) && data) {
                      // 既に期日が入ってる場合は更新しない
                      if (data.expiration) {
                        snapshot.ref.update({
                          uid: user.uid,
                          valid: true,
                          // expiration: addYears(new Date(), 1),
                        });
                      } else {
                        snapshot.ref.update({
                          uid: user.uid,
                          valid: true,
                          expiration: addYears(new Date(), 1),
                        });
                      }
                    } else {
                      throw new Error('Cannot get data from firestore');
                    }
                  }
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              有効にする
            </button>
          </span>
        </div>
      </div>
      <CouponModal
        display={couponModal}
        onClose={() => {
          setCouponModal(false);
        }}
      />
    </>
  );
};

export default PaymentView;
