import firebase from '../config/firebase';
import 'firebase/firestore';
import LetterDocument from './InterfaceOfLetterDocument';
import { isLetterDocument } from './LetterDocumentTypeGuard';

// fetch Document
const fetchUserLetters = async (
  uid: string,
  orderField: string,
  status: string,
  position?: string,
) => {
  // DB参照のインスタンス生成
  const db: firebase.firestore.Firestore = firebase.firestore();
  const query = db
    .collectionGroup('letters')
    .where('uid', '==', uid)
    .where('status', '==', status)
    .orderBy(orderField, 'desc')
    .limit(11);
  // Collection参照のインスタンス生成
  const collection: { [documentId: string]: LetterDocument } = {};
  if (position !== undefined) {
    const delimiterPosition = firebase.firestore.Timestamp.fromDate(
      new Date(position),
    );
    // delimiterPositionを使った処理
    const next = db
      .collectionGroup('letters')
      .where('uid', '==', uid)
      .where('status', '==', status)
      .orderBy(orderField, 'desc')
      .startAfter(delimiterPosition)
      .limit(11);
    return next
      .get()
      .then((letters) => {
        let delimiterPosition: string;
        // string以外のデータはタイムスタンプのみなので、例外をいれる
        if (orderField === 'updatedDate') {
          // 11件未満しかない場合は、最後の手紙をpositionとして返す
          if (letters.docs.length < 11) {
            const lastVisible: firebase.firestore.Timestamp =
              letters.docs[letters.docs.length - 1].get(orderField);
            delimiterPosition = lastVisible.toDate().toString();
          } else {
            const lastVisible: firebase.firestore.Timestamp =
              letters.docs[letters.docs.length - 2].get(orderField);
            delimiterPosition = lastVisible.toDate().toString();
          }
        } else {
          // 11件未満しかない場合は、最後の手紙をpositionとして返す
          if (letters.docs.length < 11) {
            delimiterPosition =
              letters.docs[letters.docs.length - 1].get(orderField);
          } else {
            delimiterPosition =
              letters.docs[letters.docs.length - 2].get(orderField);
          }
        }
        // Construct a new query starting at this document,
        // get the next 25 cities.
        letters.forEach((letter) => {
          const data = letter.data();
          if (isLetterDocument(data)) {
            collection[letter.id] = data;
          }
        });
        return { collection, delimiterPosition };
      })
      .catch((error) => {
        console.log('Error fetching user letters:', error);
        return null;
      });
  } else {
    // delimiterPositionが無い時の処理(1ページ目想定)
    return query
      .get()
      .then((letters) => {
        let delimiterPosition: string;
        // string以外のデータはタイムスタンプのみなので、例外をいれる
        if (orderField === 'updatedDate') {
          // 11件未満しかない場合は、最後の手紙をpositionとして返す
          if (letters.docs.length < 11) {
            const lastVisible: firebase.firestore.Timestamp =
              letters.docs[letters.docs.length - 1].get(orderField);
            delimiterPosition = lastVisible.toDate().toString();
          } else {
            const lastVisible: firebase.firestore.Timestamp =
              letters.docs[letters.docs.length - 2].get(orderField);
            delimiterPosition = lastVisible.toDate().toString();
          }
        } else {
          // 11件未満しかない場合は、最後の手紙をpositionとして返す
          if (letters.docs.length < 11) {
            delimiterPosition =
              letters.docs[letters.docs.length - 1].get(orderField);
          } else {
            delimiterPosition =
              letters.docs[letters.docs.length - 2].get(orderField);
          }
        }

        // Construct a new query starting at this document,
        letters.forEach((letter) => {
          const data = letter.data();
          if (isLetterDocument(data)) {
            collection[letter.id] = data;
          }
        });
        return { collection, delimiterPosition };
      })
      .catch((error) => {
        console.log('Error fetching user letters:', error);
        return null;
      });
  }
};

export default fetchUserLetters;
