import html2canvas from 'html2canvas';
import firebase from '../../config/firebase';
import 'firebase/storage';
import 'firebase/functions';
import { uploadLetterImage } from '../../lib/StorageUtils';

const uploadImage = (cb: () => void) => {
  const node = document.getElementById('hoge');
  const textArea = document.getElementById(
    'content',
  ) as HTMLTextAreaElement | null;
  const fieldset = document.getElementById('editview-textarea');
  const alternativeElement = document.createElement('div');

  if (node && textArea && fieldset) {
    // Replace Textarea to Div, \n -> <br />
    // Copy Styles, ClassNames and Attributes
    textArea.classList.forEach((className) => {
      alternativeElement.classList.add(className);
    });
    // スマホではなぜかappendChild 実行後はscrollY の値が正しく取得できない
    const scrollY = window.scrollY;
    // node の幅に対してpadding 15% を左右に入れているため、計算でdivの横幅を指定
    const alternativeWidth = node.clientWidth * 0.7;
    const text = textArea.value.replaceAll(/\r?\n/g, '<br />');
    alternativeElement.style.fontFamily = textArea.style.fontFamily;
    alternativeElement.innerHTML = text;
    alternativeElement.style.width = `${alternativeWidth}px`;
    alternativeElement.style.maxWidth = `${alternativeWidth}px`;
    alternativeElement.style.wordWrap = 'break-word';
    textArea.remove();
    fieldset.appendChild(alternativeElement);

    html2canvas(node, { scrollX: 0, scrollY: -scrollY })
      .then(async (canvasElement) => {
        if (canvasElement) {
          // get createdDate of Firestore document from localstorage
          const documentId = localStorage.getItem('documentId') || '';
          const createdDate = localStorage.getItem('createdDate') || '';
          // naming convention is "documentId-createdDate.png" for captured letter
          const fileName = documentId + '-' + createdDate + '.png';
          const uid = firebase.auth().currentUser?.uid;
          const base64data = canvasElement.toDataURL();
          const path = uid ? uid : 'guests';
          await uploadLetterImage(path, fileName, base64data);
        }
        cb();
      })
      .catch((error) => {
        console.error('something went wrong with html2canvas', error);
      });
  } else {
    console.error('node object is null');
  }
};

export default uploadImage;
