import { useContext } from 'react';
import { AuthContext } from '../../AuthProvider';
import { useHistory, useLocation } from 'react-router-dom';

const Login: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { loadingAuthState } = useContext(AuthContext);

  if (loadingAuthState) {
    return null;
  } else {
    return (
      <div className="uk-card uk-card-hovar uk-border-rounded">
        <div className="uk-card-header">
          <ul className="uk-list">
            <li>
              <div
                className="uk-icon uk-margin-auto uk-position-relative uk-position-top-center"
                uk-icon="icon:user; ratio: 2.0"
              ></div>
            </li>
            <li>
              <h2
                className="uk-card-title uk-margin-remove-bottom uk-text-center"
                style={{ fontSize: '1.0rem' }}
              >
                Guest
              </h2>
            </li>
            <li>
              <h2 className="uk-text-meta uk-margin-remove-top uk-text-center">
                ログインすると更に便利に
              </h2>
            </li>
          </ul>
        </div>
        <div className="uk-card-body uk-padding-remove">
          <button
            id="signInButton"
            className="uk-button uk-button-default uk-width-1-1 uk-text-capitalize"
            style={{ borderRight: '0px', borderLeft: '0px' }}
            onClick={() => {
              if (location.pathname.includes('payment')) return;
              history.push(
                `/payment${
                  location.pathname === '/' ? '/top' : location.pathname
                }`,
              );
            }}
          >
            <div className="uk-icon-justify uk-padding-small uk-padding-remove-top uk-padding-remove-left uk-padding-remove-bottom"></div>
            Sign In
          </button>
          <button
            id="signUpButton"
            className="uk-button uk-button-default uk-width-1-1 uk-text-capitalize uk-border-rounded"
            style={{
              border: 'none',
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
            }}
            onClick={() => {
              if (location.pathname.includes('payment')) return;
              history.push({
                pathname: `/payment${
                  location.pathname === '/' ? '/top' : location.pathname
                }`,
                state: { isSingUp: true },
              });
            }}
          >
            <div className="uk-icon-justify uk-padding-small uk-padding-remove-top uk-padding-remove-left uk-padding-remove-bottom"></div>
            Sign Up
          </button>
        </div>
      </div>
    );
  }
};

export default Login;
