import { useContext, useState } from 'react';
import LoginModal from '../common/LoginModal';
import { useHistory, Link } from 'react-router-dom';
import classes from './Toolbar.module.css';
import FontSelectDropDown from './FontSelectDropDown';
import createLetterDocument from '../../lib/CreateLetterDocument';
import updateLetterDocument from '../../lib/UpdateLetterDocument';
import { AuthContext } from '../../AuthProvider';
import { PlanContext } from '../../PlanProvider';
import saveImage from './SaveImage';
import uploadImage from './UploadImage';
import UIkit from 'uikit';
import DropdownStamps from './DropdownStamps';

type Props = {
  callback?: () => void;
  activeFontFamily: string;
  onChangeActiveFont: (onChangeActiveFont: string) => void;
  onImportImage: (importImage: File) => void;
  onImportStamp: (importStamp: string) => void;
  onUpdateDatabase: () => void;
  numberOfDraft: number;
  limitDraft: () => void;
  isLoadedFontFace: boolean;
  allowNavigation: () => void;
  userAgent: string;
};

const Toolbar: React.FC<Props> = ({
  callback,
  activeFontFamily,
  onChangeActiveFont,
  onImportImage,
  onImportStamp,
  onUpdateDatabase,
  numberOfDraft,
  limitDraft,
  isLoadedFontFace,
  allowNavigation,
  userAgent,
}) => {
  const { user } = useContext(AuthContext);
  const { plan } = useContext(PlanContext);
  const { loadingAuthState } = useContext(AuthContext);
  const { authenticated } = useContext(AuthContext);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const history = useHistory();

  const letterElementsToString = () => {
    const el = document.querySelector<HTMLDivElement>('#images');
    const images = el?.querySelectorAll<HTMLImageElement>('img');
    const text = document.querySelector<HTMLTextAreaElement>('#content');
    const textValue = text ? text.value : '';

    const imgObj: {
      id: string;
      className: string;
      src: string;
      top: string;
      left: string;
      transform: string;
    }[] = [];

    if (images) {
      Array.prototype.slice.call(images).forEach((img) => {
        imgObj.push({
          id: img.id,
          className: img.className,
          src: img.src,
          top: img.style.top,
          left: img.style.left,
          transform: img.style.transform,
        });
      });
    }

    return { imgObj, textValue };
  };

  return (
    <>
      <nav
        className={classes.ukNavbarContainer + ' uk-navbar-container'}
        uk-navbar="true"
      >
        <div className={classes.ukNavbarLeft + ' uk-navbar-left'}>
          <button
            className={
              userAgent !== 'pc'
                ? classes.navbarButton +
                  ' uk-button uk-button-default uk-padding-remove-vertical'
                : classes.navbarButton + ' uk-button uk-button-default '
            }
            style={userAgent !== 'pc' ? { padding: '0px 10px 0px 10px' } : {}}
            uk-toggle="target: #modal-center; cls: uk-modal-primary"
            onClick={async () => {
              if (authenticated && !loadingAuthState) {
                const uid = user ? user.uid : 'guests';
                const username = user ? user.displayName || '' : 'Guest';
                const images: NodeListOf<HTMLImageElement> =
                  document.querySelectorAll('[class*=set-]');
                images.forEach((image) => {
                  // クラウドに保存済か管理するためにclass名にsaved を追加
                  if (!image.classList.contains('saved')) {
                    image.classList.add('saved');
                  }
                });
                const { imgObj, textValue } = letterElementsToString();
                const documentId = localStorage.getItem('documentId');
                if (!documentId) {
                  if (numberOfDraft < 10) {
                    try {
                      await createLetterDocument(
                        uid,
                        username,
                        imgObj,
                        textValue,
                        activeFontFamily,
                        'draft',
                      );
                      onUpdateDatabase();
                      UIkit.notification({
                        message: '下書きを保存しました。',
                        pos: 'bottom-left',
                        timeout: 750,
                        status: 'danger',
                      });
                      console.log('create draft');
                    } catch {
                      console.error('failed create draft');
                    }
                  } else {
                    limitDraft();
                  }
                } else {
                  try {
                    await updateLetterDocument(uid, documentId, {
                      elements: imgObj,
                      text: textValue,
                      fontFamily: activeFontFamily,
                      status: 'draft',
                    });
                    onUpdateDatabase();
                    UIkit.notification({
                      message: '下書きを保存しました。',
                      pos: 'bottom-left',
                      timeout: 750,
                      status: 'danger',
                    });
                    console.log('update draft');
                  } catch {
                    console.log('failed update draft');
                  }
                }
              }
            }}
          >
            <span
              className="uk-visible@m"
              uk-icon="icon: cloud-upload; ratio: 0.8;"
            ></span>
            <div className="uk-hidden@m">
              <div
                className={classes.ukIcon + ' '}
                uk-icon="icon: cloud-upload; ratio: 0.8;"
              />
              <div className={classes.ukIconText}>保存する</div>
            </div>
          </button>
          {!authenticated && !loadingAuthState && (
            <LoginModal containerId={'editview'} />
          )}
          {/* Fontを選択するドロップダウンリスト */}
          <div className={classes.FontSelectDropDown}>
            <FontSelectDropDown
              onChangeActiveFont={onChangeActiveFont}
              activeFontFamily={activeFontFamily}
              isLoadedFontFace={isLoadedFontFace}
            />
          </div>
          {/* Image用のボタン */}
          {userAgent === 'pc' && (
            <div uk-form-custom="true" className={classes.navbarButton}>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                style={{ cursor: 'pointer' }}
                onChange={(e) => {
                  if (e.target.files) {
                    if (e.target.files[0].size > 1024 * 1024 * 1) {
                      UIkit.notification({
                        message: '画像のサイズが1 MBを超えています。',
                        pos: 'top-center',
                        timeout: 750,
                        status: 'danger',
                      });
                      console.log(e.target.files[0].size);
                    } else {
                      onImportImage(e.target.files[0]);
                    }
                    e.target.value = '';
                  }
                }}
              />
              <button
                className={
                  classes.buttonBorder + ' uk-button uk-button-default'
                }
                type="button"
              >
                <span
                  className="uk-postion-center-right"
                  uk-icon="icon: image; ratio: 0.8;"
                ></span>
              </button>
            </div>
          )}
          {/* Stamp用のボタン */}
          {userAgent === 'pc' && (
            <div className={classes.navbarButton + ' uk-button-group '}>
              <button
                className={
                  classes.buttonBorder + ' uk-button uk-button-default'
                }
                type="button"
              >
                <span
                  className="uk-postion-center-right"
                  uk-icon="icon: happy; ratio: 0.8;"
                ></span>
              </button>
              <div
                className="uk-dropdown"
                uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true; pos: bottom-justify;"
                style={{ padding: '10px' }}
              >
                {/* uk-child-widthの幅n 等分を列数として指定 */}
                <div
                  className="uk-dropdown-grid uk-child-width-1-3@m"
                  uk-grid="true"
                >
                  {/* 1列目 */}
                  <DropdownStamps columnNum={0} onImportStamp={onImportStamp} />
                  {/* 2列目 */}
                  <DropdownStamps columnNum={1} onImportStamp={onImportStamp} />
                  {/* 3列目 */}
                  <DropdownStamps columnNum={2} onImportStamp={onImportStamp} />
                </div>
                {
                  // Premiumプランではない場合、以下の文言を追加
                  plan !== 'premium' && (
                    <div>
                      <ul className="uk-nav uk-dropdown-nav">
                        <li
                          className={classes.ukNavDivider + ' uk-nav-divider'}
                        ></li>
                        <li>
                          <div className={'uk-text-break'}>
                            Premiumプランでは
                            <br />
                            更に多くのスタンプが使用可能
                          </div>
                        </li>
                        <li>
                          <Link
                            className="uk-link"
                            to={'/payment/plan'}
                            style={{ color: 'rgb(253, 218, 189)' }}
                          >
                            プラン変更
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )
                }
              </div>
            </div>
          )}
        </div>
        <div className={classes.ukNavbarRight + ' uk-navbar-right'}>
          <button
            onClick={async () => {
              if (!loadingAuthState && !loadingPreview) {
                setLoadingPreview(true);
                const uid = user ? user.uid : 'guests';
                const username = user ? user.displayName || '' : 'Guest';
                const images: NodeListOf<HTMLImageElement> =
                  document.querySelectorAll('[class*=set-]');
                images.forEach((image) => {
                  // クラウドに保存済か管理するためにclass名にsaved を追加
                  if (!image.classList.contains('saved')) {
                    image.classList.add('saved');
                  }
                });
                const { imgObj, textValue } = letterElementsToString();
                const documentId = localStorage.getItem('documentId');
                if (!documentId) {
                  try {
                    await createLetterDocument(
                      uid,
                      username,
                      imgObj,
                      textValue,
                      activeFontFamily,
                      'preview',
                    );
                    console.log('create preview');
                  } catch {
                    console.error('failed create preview');
                  }
                } else {
                  try {
                    await updateLetterDocument(uid, documentId, {
                      elements: imgObj,
                      text: textValue,
                      fontFamily: activeFontFamily,
                    });
                    console.log('update preview');
                  } catch {
                    console.log('failed update preview');
                  }
                }
                window.scrollTo(0, 0);
                saveImage(() => {
                  allowNavigation();
                  history.push('/preview');
                });
              }
            }}
            style={userAgent !== 'pc' ? { padding: '0px 10px 0px 10px' } : {}}
            className={
              userAgent !== 'pc'
                ? classes.navbarButton +
                  ' uk-button uk-button-default uk-padding-remove-vertical'
                : classes.navbarButton + ' uk-button uk-button-default '
            }
          >
            <div className="uk-panel">
              <span className="uk-visible@m">
                {loadingPreview ? (
                  <span uk-spinner="true" style={{ color: 'orange' }} />
                ) : (
                  'プレビュー'
                )}
              </span>
              <span className="uk-hidden@m">
                {loadingPreview ? (
                  <span uk-spinner="true" style={{ color: 'orange' }} />
                ) : (
                  <div>
                    <div
                      className={classes.ukIcon}
                      uk-icon="icon: play-circle; ratio: 0.8;"
                    />
                    <div className={classes.ukIconText}>プレビュー</div>
                  </div>
                )}
              </span>
            </div>
          </button>
          <button
            className={
              userAgent !== 'pc'
                ? classes.ukButtonPrimary +
                  ' uk-button uk-button-primary uk-padding-remove-vertical'
                : classes.ukButtonPrimary + ' uk-button uk-button-primary'
            }
            style={userAgent !== 'pc' ? { padding: '0px 10px 0px 10px' } : {}}
            onClick={async () => {
              if (callback) {
                callback();
                return;
              }
              if (!loadingAuthState) {
                const documentId = localStorage.getItem('documentId');
                const { imgObj, textValue } = letterElementsToString();
                const uid = user ? user.uid : 'guests';
                const username = user ? user.displayName || '' : 'Guest';
                if (!documentId) {
                  try {
                    await createLetterDocument(
                      uid,
                      username,
                      imgObj,
                      textValue,
                      activeFontFamily,
                      'public',
                    );
                    console.log('create document');
                  } catch {
                    console.error('failed create document');
                  }
                } else {
                  try {
                    await updateLetterDocument(uid, documentId, {
                      elements: imgObj,
                      text: textValue,
                      fontFamily: activeFontFamily,
                      status: 'public',
                    });
                    console.log('update document');
                  } catch {
                    console.error('failed update document');
                  }
                }
                // html2canvas の問題で、iOS端末でスクロールされている場合描画がバグることの是正
                window.scrollTo(0, 0);
                // Upload letter image to firebase storage and go to mailtemplate page
                uploadImage(() => {
                  allowNavigation();
                  history.push('/mailtemplate');
                });
              }
            }}
          >
            <div className="uk-panel">
              <span className="uk-margin-remove uk-visible@m">書き終える</span>
              <div className="uk-hidden@m">
                <div
                  className={classes.ukIcon}
                  uk-icon="icon: forward; ratio: 0.8;"
                />
                <div className={classes.ukIconText}>書き終える</div>
              </div>
            </div>
          </button>
        </div>
      </nav>
      <hr className={classes.hr} />
    </>
  );
};

export default Toolbar;
