import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { createGlobalState } from 'react-hooks-global-state';
import EnvelopeBlue from '../assets/imgs/envelope-blue.png';
import EnvelopeGreen from '../assets/imgs/envelope-green.png';
import EnvelopeIvory from '../assets/imgs/envelope-ivory.png';
import EnvelopeYellow from '../assets/imgs/envelope-yellow.png';
import EnvelopeOrange from '../assets/imgs/envelope-orange.png';
import EnvelopePink from '../assets/imgs/envelope-pink.png';
import EnvelopePurple from '../assets/imgs/envelope-purple.png';
import EnvelopeSkin from '../assets/imgs/envelope-skin.png';
import EnvelopeBirthday from '../assets/imgs/envelope-birthday.png';
import EnvelopeChristmas from '../assets/imgs/envelope-christmas.png';
import LetterPaperSimple1 from '../assets/imgs/letter-paper-simple1.png';
import LetterPaperSimple2 from '../assets/imgs/letter-paper-simple2.png';
import LetterPaperHeart from '../assets/imgs/letter-paper-heart.png';
import LetterPaperPink from '../assets/imgs/letter-paper-pink.png';
import LetterPaperLeaf from '../assets/imgs/letter-paper-leaf.png';
import LetterPaperSnow from '../assets/imgs/letter-paper-snow.png';
import LetterPaperSakura from '../assets/imgs/letter-paper-sakura.png';
import LetterPaperMaple from '../assets/imgs/letter-paper-maple.png';
import LetterPaperBirthday from '../assets/imgs/letter-paper-birthday.png';
import LetterPaperChristmas from '../assets/imgs/letter-paper-christmas.png';
import classes from './DesignsView.module.css';
import Header from '../components/common/Header';
import { AuthContext } from '../AuthProvider';
import { PlanContext } from '../PlanProvider';

// Use global state for tracking the selected envelope and letter
const initialState = {
  activeEnvelopeIndex: 1,
  activeEnvelopeName: EnvelopeBlue,
  activeLetterIndex: 1,
  activeLetterName: LetterPaperSimple1,
};
const { useGlobalState } = createGlobalState(initialState);

// Envelope Component
const envelope = (id: number, envelopeName: string) => {
  const [activeEnvelopeIndex, setEnvelopeIndex] = useGlobalState(
    'activeEnvelopeIndex',
  );
  const [activeEnvelopeName, setEnvelopeName] =
    useGlobalState('activeEnvelopeName');
  if (activeEnvelopeName) {
    return (
      <div>
        <button
          className={[
            id === activeEnvelopeIndex ? 'active' : '',
            classes.ukButtonDefault,
            'uk-button',
            'uk-button-default',
          ].join(' ')}
          type="button"
          onClick={() => {
            setEnvelopeIndex(id);
            setEnvelopeName(envelopeName);
          }}
        >
          <img src={envelopeName} alt="" />
        </button>
      </div>
    );
  }
};

// Envelope for Premium Component
const envelopePremium = (
  id: number,
  envelopeName: string,
  plan: string | undefined,
  toggleModal: () => void,
) => {
  const [activeEnvelopeIndex, setEnvelopeIndex] = useGlobalState(
    'activeEnvelopeIndex',
  );
  const [activeEnvelopeName, setEnvelopeName] =
    useGlobalState('activeEnvelopeName');
  if (activeEnvelopeName) {
    return (
      <div>
        <button
          className={[
            id === activeEnvelopeIndex ? 'active' : '',
            classes.ukButtonDefault,
            'uk-button',
            'uk-button-default',
            // plan !== 'premium' ? 'uk-disabled' : '',
          ].join(' ')}
          type="button"
          onClick={() => {
            if (plan === 'premium') {
              setEnvelopeIndex(id);
              setEnvelopeName(envelopeName);
            } else {
              toggleModal();
            }
          }}
        >
          <img src={envelopeName} alt="" />
          {plan !== 'premium' && (
            <span
              className={classes.ukBadge + ' uk-badge uk-position-top-right'}
              style={{ backgroundColor: 'rgb(255 91 91)' }}
            >
              P
            </span>
          )}
        </button>
      </div>
    );
  }
};

// Letter Component
const letter = (id: number, letterName: string) => {
  const [activeLetterIndex, setLetterIndex] =
    useGlobalState('activeLetterIndex');
  const [activeLetterName, setLetterName] = useGlobalState('activeLetterName');
  if (activeLetterName) {
    return (
      <div>
        <button
          className={[
            id === activeLetterIndex ? 'active' : '',
            classes.ukButtonDefault,
            'uk-button',
            'uk-button-default',
          ].join(' ')}
          type="button"
          onClick={() => {
            setLetterIndex(id);
            setLetterName(letterName);
          }}
        >
          <img src={letterName} alt="" />
        </button>
      </div>
    );
  }
};

// Letter for Premium Component
const letterPremium = (
  id: number,
  letterName: string,
  plan: string | undefined,
  toggleModal: () => void,
) => {
  const [activeLetterIndex, setLetterIndex] =
    useGlobalState('activeLetterIndex');
  const [activeLetterName, setLetterName] = useGlobalState('activeLetterName');
  if (activeLetterName) {
    return (
      <div>
        <button
          className={[
            id === activeLetterIndex ? 'active' : '',
            classes.ukButtonDefault,
            'uk-button',
            'uk-button-default',
            // plan !== 'premium' ? 'uk-disabled' : '',
          ].join(' ')}
          type="button"
          onClick={() => {
            if (plan === 'premium') {
              setLetterIndex(id);
              setLetterName(letterName);
            } else {
              toggleModal();
            }
          }}
        >
          <img src={letterName} alt="" />
          {plan !== 'premium' && (
            <span
              className={classes.ukBadge + ' uk-badge uk-position-top-right'}
              style={{ backgroundColor: 'rgb(255 91 91)' }}
            >
              P
            </span>
          )}
        </button>
      </div>
    );
  }
};

// DesignsView Component
const DesignsView = () => {
  const history = useHistory();
  const [activeEnvelopeName] = useGlobalState('activeEnvelopeName');
  const [activeLetterName] = useGlobalState('activeLetterName');
  const { user } = useContext(AuthContext);
  const { plan } = useContext(PlanContext);
  const [shownPlanChangeModal, setShownPlanChangeModal] =
    useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  if (user?.uid === undefined && checked === false) {
    setShowLoginModal(true);
    setChecked(true);
  }

  return (
    <div id="designsView">
      <Header />
      <main className={classes.designsMain}>
        <div className={classes.ukButton + ''}>
          <button
            onClick={() => {
              localStorage.setItem('selectedEnvelopeName', activeEnvelopeName);
              localStorage.setItem('selectedLetterName', activeLetterName);
              history.push('/edit');
            }}
            className={classes.ukButtonPrimary + ' uk-button uk-button-primary'}
          >
            手紙を書く
          </button>
        </div>
        <div
          className={
            classes.ukGridDivider + ' uk-grid-divider uk-child-width-expand@s'
          }
          uk-grid="true"
        >
          <div>
            <div className={classes.ukTextCenter + ' uk-text-center'}>
              封筒を選ぶ
            </div>
            <ul
              className={classes.ukChildWidthExpand + ' uk-child-width-expand'}
              uk-tab="true"
              data-uk-switcher="{connect:'#envelopes'}"
            >
              <li>
                <a href="#cool">爽やか</a>
              </li>
              <li>
                <a href="#cute">可愛い</a>
              </li>
              <li>
                <a href="#event">イベント</a>
              </li>
            </ul>
            <ul id="envelopes" className="uk-switcher uk-margin">
              <li>
                <div
                  className="uk-grid-medium uk-child-width-1-3"
                  uk-grid="true"
                >
                  {envelope(1, EnvelopeBlue)}
                  {envelope(2, EnvelopeGreen)}
                  {envelope(3, EnvelopeIvory)}
                  {envelope(4, EnvelopeYellow)}
                </div>
              </li>
              <li>
                <div
                  className="uk-grid-medium uk-child-width-1-3"
                  uk-grid="true"
                >
                  {envelope(5, EnvelopePink)}
                  {envelope(6, EnvelopeOrange)}
                  {envelope(7, EnvelopePurple)}
                  {envelope(8, EnvelopeSkin)}
                </div>
              </li>
              <li>
                <div
                  className="uk-grid-medium uk-child-width-1-3"
                  uk-grid="true"
                >
                  {envelopePremium(101, EnvelopeBirthday, plan, () =>
                    setShownPlanChangeModal(true),
                  )}
                  {envelopePremium(102, EnvelopeChristmas, plan, () =>
                    setShownPlanChangeModal(true),
                  )}
                </div>
              </li>
            </ul>
          </div>
          <div>
            <div className={classes.ukTextCenter + ' uk-text-center'}>
              便箋を選ぶ
            </div>
            <ul
              className={classes.ukChildWidthExpand + ' uk-child-width-expand'}
              uk-tab="true"
              data-uk-switcher="{connect:'#letter-papers'}"
            >
              <li>
                <a href="#cool">爽やか</a>
              </li>
              <li>
                <a href="#cute">可愛い</a>
              </li>
              <li>
                <a href="#event">イベント</a>
              </li>
            </ul>
            <ul id="letter-papers" className="uk-switcher uk-margin">
              <li>
                <div
                  className="uk-grid-medium uk-child-width-1-3"
                  uk-grid="true"
                >
                  {letter(1, LetterPaperSimple1)}
                  {letter(2, LetterPaperSimple2)}
                  {letterPremium(101, LetterPaperSnow, plan, () =>
                    setShownPlanChangeModal(true),
                  )}
                  {letterPremium(102, LetterPaperLeaf, plan, () =>
                    setShownPlanChangeModal(true),
                  )}
                </div>
              </li>
              <li>
                <div
                  className="uk-grid-medium uk-child-width-1-3"
                  uk-grid="true"
                >
                  {letter(1, LetterPaperHeart)}
                  {letter(2, LetterPaperPink)}
                  {letterPremium(101, LetterPaperSakura, plan, () =>
                    setShownPlanChangeModal(true),
                  )}
                  {letterPremium(102, LetterPaperMaple, plan, () =>
                    setShownPlanChangeModal(true),
                  )}
                </div>
              </li>
              <li>
                <div
                  className="uk-grid-medium uk-child-width-1-3"
                  uk-grid="true"
                >
                  {letterPremium(103, LetterPaperBirthday, plan, () =>
                    setShownPlanChangeModal(true),
                  )}
                  {letterPremium(104, LetterPaperChristmas, plan, () =>
                    setShownPlanChangeModal(true),
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </main>
      {shownPlanChangeModal && (
        <div
          className="uk-position-cover uk-overlay uk-overlay-primary uk-animation-fade uk-animation-fast"
          style={{
            marginTop: '80px',
            marginBottom: '0px',
            background: 'rgba(0, 0, 0, .32)',
            zIndex: 1000,
          }}
          onClick={() => {
            setShownPlanChangeModal(false);
          }}
        >
          <div
            className="uk-position-center uk-position-middle uk-overlay-default uk-animation-fade uk-animation-fast"
            style={{
              width: '600px',
              height: '300px',
              background: 'rgba(255, 255, 255, 1)',
              top: '30%',
              borderRadius: '5px',
            }}
          >
            <div className="uk-animation-fade uk-animation-fast uk-modal-dialog-small">
              <div style={{ width: '100%', paddingTop: '40px' }}>
                <div>
                  <p
                    className="uk-position-top-left"
                    style={{ color: '#000', margin: '20px' }}
                  >
                    プレミアムプラン向け封筒・便箋
                  </p>
                  <button
                    className="uk-modal-close-default"
                    type="button"
                    uk-close="true"
                    style={{ color: '#999' }}
                    onClick={() => {
                      setShownPlanChangeModal(false);
                    }}
                  ></button>
                  <div
                    className="uk-alert uk-alert-warning"
                    uk-alert="true"
                    style={{
                      marginLeft: '20px',
                      marginRight: '20px',
                      borderRadius: '5px',
                    }}
                  >
                    <p>
                      <span
                        className="uk-icon uk-margin-small-right"
                        uk-icon="icon: info"
                      />
                      選択した封筒・便箋はプレミアムプランでのみご利用頂けます。
                    </p>
                  </div>
                  <div
                    className="uk-grid uk-position-bottom-right uk-width-auto"
                    style={{ margin: '20px' }}
                  >
                    <button
                      className="uk-button "
                      type="button"
                      style={{
                        backgroundColor: 'rgb(239, 239, 239)',
                        borderRadius: '5px',
                        color: 'rgba(59, 59, 59, 1)',
                      }}
                    >
                      キャンセル
                    </button>
                    <div
                      className="uk-padding-remove"
                      style={{ width: '5px' }}
                    ></div>
                    <button
                      className={classes.ukButtonPrimary + ' uk-button'}
                      type="button"
                      style={{
                        borderRadius: '5px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      }}
                      onClick={() => {
                        history.push('/payment/plan');
                      }}
                    >
                      プラン確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showLoginModal && (
        <div
          className="uk-position-cover uk-overlay uk-overlay-primary uk-animation-fade uk-animation-fast"
          style={{
            marginTop: '80px',
            marginBottom: '0px',
            background: 'rgba(0, 0, 0, .32)',
            zIndex: 1000,
          }}
          onClick={() => {
            setShowLoginModal(false);
          }}
        >
          <div
            className="uk-position-center uk-position-middle uk-overlay-default uk-animation-fade uk-animation-fast"
            style={{
              width: '600px',
              height: '300px',
              background: 'rgba(255, 255, 255, 1)',
              top: '30%',
              borderRadius: '5px',
            }}
          >
            <div className="uk-animation-fade uk-animation-fast uk-modal-dialog-small">
              <div style={{ width: '100%', paddingTop: '40px' }}>
                <div>
                  <p
                    className="uk-position-top-left"
                    style={{ color: '#000', margin: '20px' }}
                  >
                    アカウントを登録しますか？
                  </p>
                  <button
                    className="uk-modal-close-default"
                    type="button"
                    uk-close="true"
                    style={{ color: '#999' }}
                    onClick={() => {
                      setShowLoginModal(false);
                    }}
                  ></button>
                  <div
                    className="uk-alert uk-alert-warning"
                    uk-alert="true"
                    style={{
                      marginLeft: '20px',
                      marginRight: '20px',
                      borderRadius: '5px',
                    }}
                  >
                    <p>
                      <span
                        className="uk-icon uk-margin-small-right"
                        uk-icon="icon: info"
                      />
                      <br />
                      アカウント未登録の場合、以下の機能制限があります。
                      <br />
                      無料でアカウントを登録して、LightLetter
                      をもっと便利に利用しましょう。
                      <br />
                      ・利用できない機能：
                      下書き保存、送った手紙・受け取った手紙の閲覧
                      <br />
                      ・手紙の保存期間： 30日間（※
                      その後手紙データは削除されます）
                    </p>
                  </div>
                  <div
                    className="uk-grid uk-position-bottom-right uk-width-auto"
                    style={{ margin: '20px' }}
                  >
                    <button
                      className="uk-button "
                      type="button"
                      style={{
                        borderRadius: '5px',
                        color: 'rgba(59, 59, 59, 1)',
                      }}
                    >
                      キャンセル
                    </button>
                    <div
                      className="uk-padding-remove"
                      style={{ width: '5px' }}
                    ></div>
                    <button
                      className={classes.ukButtonPrimary + ' uk-button'}
                      type="button"
                      style={{
                        borderRadius: '5px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      }}
                      onClick={() => {
                        history.push('/payment/plan');
                      }}
                    >
                      登録する
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesignsView;
