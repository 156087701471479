import Header from '../components/common/Header';

const NotFoundView = () => {
  return (
    <div className="not-found">
      <Header />
      <div className="uk-margin uk-margin-left">
        <h3 className="uk-card-title">404 エラー</h3>
        <p>お探しのページは見つかりませんでした :(</p>
      </div>
    </div>
  );
};

export default NotFoundView;
