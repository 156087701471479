import * as react from 'react';
import { Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import classes from './MailTemplateView.module.css';
import Header from '../components/common/Header';
import UIkit from 'uikit';

const MailTemplateView = () => {
  let url = localStorage.getItem('url');
  if (!url) {
    url = 'リンクが設定されていません。';
  }

  // TODO: いつかremoveItemをするかもしれない
  const placeholder = 'リンクのみ表示';

  interface occasion {
    id: number;
    name: string;
    text: string;
  }

  const occasions: occasion[] = [
    { id: 0, name: 'リンクのみ表示', text: `${url}` },
    {
      id: 1,
      name: '誕生日',
      text: `誕生日おめでとう！\n素敵な一年になりますように。これからもよろしくね！\n\n${url}`,
    },
    {
      id: 2,
      name: '年明け',
      text: `明けましておめでとう！\n今年も一年、良い年でありますように。\n\n${url}`,
    },
    {
      id: 3,
      name: 'クリスマス',
      text: `メリークリスマス！\n素敵なクリスマスになりますように。\n\n${url}`,
    },
    {
      id: 4,
      name: '感謝のメッセージ',
      text: `いつもありがとう。これからもよろしくね！\n\n${url}`,
    },
  ];

  const [label, setLabel] = react.useState(placeholder);
  const [text, setText] = react.useState(`${url}`);

  return (
    <div>
      <Header />
      <main className={classes.mailTemplateView}>
        <div>
          <p className={classes.ukH3 + ' uk-h3'}>手紙が完成しました！</p>
        </div>
        <div className="uk-child-width-1-2@m" uk-grid="true">
          <div>
            <p className={classes.ukH5 + ' uk-h5'}>
              手紙のリンクをメールや SNS で送りましょう。
            </p>
            <div>
              <button
                className={
                  classes.dropdownMenu +
                  ' uk-button uk-button-default uk-margin'
                }
                type="button"
              >
                <span>{label}</span>
                <span
                  className="uk-postion-left"
                  uk-icon="icon: triangle-down; ratio: 1.0;"
                ></span>
              </button>
              <div
                className="dropdown"
                uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true; pos: bottom-justify;"
              >
                <ul className="uk-nav uk-dropdown-nav">
                  {occasions.map((o) => (
                    <li
                      key={o.name}
                      onClick={() => {
                        setLabel(o.name);
                        setText(o.text);
                      }}
                    >
                      <div
                        className={classes.mailTypes}
                        uk-toggle="target: .dropdown"
                      >
                        {o.name}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="uk-inline">
              <pre>
                <code>{text}</code>
              </pre>
              <CopyToClipboard
                text={text}
                onCopy={() => {
                  // ToDo: Add response
                }}
              >
                <button
                  className="uk-icon-link uk-position-top-right"
                  uk-tooltip="Copy to clipboard"
                  uk-icon="icon: copy; ratio: 1.0"
                  type="button"
                  onClick={() =>
                    UIkit.notification({
                      message: 'Copied to clipboard!',
                      pos: 'bottom-left',
                      timeout: 750,
                    })
                  }
                ></button>
              </CopyToClipboard>
            </div>
            <div>
              <Link
                to="/"
                className={
                  classes.ukButtonPrimary +
                  ' uk-button-primary uk-border-pill uk-margin uk-button'
                }
              >
                トップページに戻る
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MailTemplateView;
