import { useState, useEffect, useContext, createContext, FC } from 'react';
import { AuthContext } from './AuthProvider';
import { PlanContext } from './PlanProvider';
import { fetchMyFont } from './lib/StorageUtils';

type ContextProps = {
  url: string;
  setUrl: (value: string) => void;
  myFontExists: boolean;
  setMyFontExists: (flag: boolean) => void;
  loadingFontState: boolean;
  generatingMyFont: boolean;
  setGeneratingMyFont: (flag: boolean) => void;
  failedLastTime: boolean;
  setFailedLastTime: (flag: boolean) => void;
};

export const FontContext = createContext<Partial<ContextProps>>({});

export const FontProvider: FC = ({ children }): JSX.Element => {
  const { user, loadingAuthState } = useContext(AuthContext);
  const { plan } = useContext(PlanContext);
  const [myFontExists, setMyFontExists] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const [loadingFontState, setLoadingFontState] = useState(true);
  const [generatingMyFont, setGeneratingMyFont] = useState<boolean>(false);
  const [failedLastTime, setFailedLastTime] = useState<boolean>(false);

  useEffect(() => {
    setLoadingFontState(true);

    // guest の場合、MyFont取得を実施しない.
    const uid = user ? user.uid : null;
    if (uid === null || uid === undefined) {
      setLoadingFontState(false);
      return;
    }

    // free accountの場合、MyFont取得を実施しない.
    if (plan === 'free') {
      setLoadingFontState(false);
      return;
    }

    const downloadMyFont = async () => {
      if (!loadingAuthState) {
        try {
          // uid, plan を引数に取り、以下の返り値を返す
          // res[0]: フォントのbase64 string or null
          // res[1]: GCS上にinprogress ファイルが存在 -> true
          // res[2]: GCS上にfailed ファイルが存在 -> true
          const res = await fetchMyFont(uid);
          setGeneratingMyFont(res[1]);
          setFailedLastTime(res[2]);
          // マイフォントがGCS上に存在する場合
          if (res[0]) {
            // マイフォントが存在するフラグをtrue に変更
            setMyFontExists(true);
            // 生成したblobURL を設定
            setUrl(res[0]);
          }
          setLoadingFontState(false);
          return;
        } catch (e) {
          console.error('error=' + e);
          setLoadingFontState(false);
          return;
        }
      }
      setLoadingFontState(false);
    };

    downloadMyFont();
  }, [loadingAuthState, user, plan]);
  return (
    <FontContext.Provider
      value={{
        url,
        myFontExists,
        setMyFontExists,
        setUrl,
        loadingFontState,
        generatingMyFont,
        setGeneratingMyFont,
        failedLastTime,
        setFailedLastTime,
      }}
    >
      {children}
    </FontContext.Provider>
  );
};
