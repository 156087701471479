import firebase from '../config/firebase';
import 'firebase/storage';
import 'firebase/functions';
import format from 'date-fns/format';

const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
    reader.onabort = () => reject(new Error('Read aborted'));
    reader.readAsDataURL(blob);
  });
};

const project = process.env.REACT_APP_FIREBASE_PROJECT_ID as string;

/*
 * マイフォントを取得
 * @param {string} uid
 * @return {Promise<[string, boolean, boolean]>}
 *   string:  フォントのbase64 string or null
 *   boolean: GCS上にinprogress ファイルが存在 -> true
 *   boolean: GCS上にfailed ファイルが存在 -> true
 */
export const fetchMyFont = async (
  uid: string,
): Promise<[string, boolean, boolean]> => {
  // uid が存在しない場合よばない
  if (uid === null || uid === undefined) {
    return ['', false, false];
  }

  // login user
  const storageRef = firebase.app().storage(`${project}-generated-fonts`).ref();

  let generatingMyFont = false;
  let failedLastTime = false;
  let myFontExists = false;

  const fileList = await storageRef.child(`${uid}`).listAll();
  if (fileList.items.length > 0) {
    fileList.items.forEach((file) => {
      // Storage上に存在するファイルでフラグを設定
      if (file.name === 'inprogress') generatingMyFont = true;
      if (file.name === 'failed') failedLastTime = true;
      if (file.name === `myfont_${uid}.otf`) myFontExists = true;
    });
  } else {
    // ファイルが何もない場合は全てのフラグをfalse に設定
    generatingMyFont = false;
    failedLastTime = false;
    myFontExists = false;
  }

  if (myFontExists) {
    const url = await storageRef
      .child(`${uid}/myfont_${uid}.otf`)
      .getDownloadURL();
    const res = await fetch(url, { method: 'GET' });
    const blob = await res.blob();
    const dataUrl = await blobToDataURL(blob);
    return [dataUrl, generatingMyFont, failedLastTime];
  }

  return ['', generatingMyFont, failedLastTime];
};

/*
 * 手書き文字を撮った画像アップロード
 * @param {string} uid
 * @param {string} imageString
 * @return {Promise<void>}
 */
export const uploadHandwritingImage = async (
  uid: string,
  imageString: string,
): Promise<void> => {
  // uid が存在しない場合よばない
  if (uid === null || uid === undefined) {
    return;
  }

  // login user
  const storageRef = firebase
    .app()
    .storage(`${project}-handwriting-images`)
    .ref();

  try {
    const data = imageString.split(',');
    const contentType = data[0]
      .toString()
      .slice(data[0].indexOf(':') + 1, data[0].indexOf(';'));
    const file = storageRef.child(
      `${uid}/${format(Date.now(), 'yyyy-MM-dd-hh:mm:ss')}`,
    );
    await file.putString(imageString, 'data_url', { contentType: contentType });
  } catch (e) {
    console.error(e);
  }
};

/*
 *
 * @param {string} uid
 * @param {string} imageString
 * @return {Promise<void>}
 */
export const uploadLetterImage = async (
  uid: string,
  fileName: string,
  imageString: string,
): Promise<void> => {
  // Guest 向けの処理
  if (uid === 'guests') {
    const func = firebase
      .app()
      .functions('asia-northeast1')
      .httpsCallable('uploadLetterImage');
    func({ path: uid ? uid : 'guests', fileName, imageString: imageString });
  }

  // login user
  const storageRef = firebase.app().storage(`${project}-letters`).ref();

  try {
    const data = imageString.split(',');
    const contentType = data[0]
      .toString()
      .slice(data[0].indexOf(':') + 1, data[0].indexOf(';'));
    const file = storageRef.child(`${uid}/${fileName}`);
    await file.putString(imageString, 'data_url', { contentType: contentType });
  } catch (e) {
    console.error(e);
  }
};

/*
 * スタンプ、添付画像をダウンロード
 * @param {string} uid
 * @param {string} documentId
 * @param {{data: string, identifier: string}[]} attachedImages
 * @return {Promise<{key: string, url: string}[]>} identifier, dataUrl
 */
export const fetchAttachedImages = async (
  path: string,
  documentId: string,
  identifiers: string[],
): Promise<{ key: string; url: string }[]> => {
  // Guest 向けの処理
  if (path === 'guests') {
    /* 添付画像をstorage に保管*/
    const func = firebase
      .app()
      .functions('asia-northeast1')
      .httpsCallable('fetchAttachedImage');
    const res: { data: { key: string; url: string }[] } = await func({
      path: path,
      documentId,
      identifiers,
    });
    return res.data;
  }

  // ログインユーザ向けの処理
  const storageRef = firebase.app().storage(`${project}-attached-images`).ref();

  const files: { key: string; data: string }[] = [];
  await Promise.all(
    identifiers.map(async (identifier) => {
      const url = await storageRef
        .child(`${path}/${documentId}/${identifier}`)
        .getDownloadURL();
      const res = await fetch(url, { method: 'GET' });
      const blob = await res.blob();
      const dataUrl = await blobToDataURL(blob);
      files.push({
        key: identifier,
        data: dataUrl,
      });
    }),
  );

  try {
    const downloadFiles: { key: string; url: string }[] = [];
    for (const file of files) {
      downloadFiles.push({
        key: file.key,
        url: file.data,
      });
    }
    return downloadFiles;
  } catch (e) {
    return e;
  }
};

/*
 * スタンプ、添付画像をアップロード
 * @param {string} uid
 * @param {string} documentId
 * @param {{data: string, identifier: string}[]} attachedImages
 * @param {number} size
 */
export const uploadAttachedImages = async (
  path: string,
  documentId: string,
  attachedImages: { data: string; identifier: string }[],
  size: number,
): Promise<void> => {
  // Guest 向けの処理
  if (path === 'guests') {
    /* 添付画像をstorage に保管*/
    const func = firebase
      .app()
      .functions('asia-northeast1')
      .httpsCallable('uploadAttachedImage');
    await func({
      path: path,
      documentId,
      attachedImages: attachedImages,
      size: size,
    });
    return;
  }

  // ログインユーザ向けの処理
  // 添付画像の合計サイズが5 MB以上のとき処理を実施しない。
  if (size > 5 * 1024 * 1024) {
    console.log('size over: ' + size);
    return;
  }

  const storageRef = firebase.app().storage(`${project}-attached-images`).ref();

  try {
    await Promise.all(
      attachedImages.map(async (attachedImage) => {
        const data = attachedImage.data.split(',');
        const contentType = data[0]
          .toString()
          .slice(data[0].indexOf(':') + 1, data[0].indexOf(';'));
        // ファイル名はclassNameをもとにset-imageXXとする
        const file = storageRef.child(
          `${path}/${documentId}/${attachedImage.identifier}`,
        );
        await file.putString(attachedImage.data, 'data_url', {
          contentType: contentType,
        });
      }),
    );
  } catch (e) {
    console.error(e);
  }
};
