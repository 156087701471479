import Header from '../components/common/Header';

const CommercialTransactions = () => {
  return (
    <>
      <Header />
      <article className="uk-article uk-padding-small uk-margin uk-margin-left uk-margin-right">
        <h1 className="uk-article-title">特定商取引法に基づく表示</h1>
        <p className="uk-article-meta">
          Written by Softech on May 22nd, 2021.{' '}
        </p>

        <table className="uk-table uk-table-divider">
          <tbody>
            <tr>
              <td>E-Mail</td>
              <td>lightletter.contact@gmail.com</td>
            </tr>
            <tr>
              <td>販売価格</td>
              <td>プランページに表示された価格に基づきます。</td>
            </tr>
            <tr>
              <td>商品代金以外の必要料金</td>
              <td>消費税は販売価格に含まれています。</td>
            </tr>
            <tr>
              <td>お支払い方法</td>
              <td>
                <div>クレジットカード決済（Stripe）</div>
                <div>
                  ストライプジャパン株式会社
                  （以下、Stripe社）が提供する決済システム「Stripe」を利用します。
                </div>
                <div>
                  Stripeの利用条件、引き落とし時期及びその他Stripeに関する事項は、Stripe社が定める規約によるものとします。
                </div>
              </td>
            </tr>
            <tr>
              <td>支払時期</td>
              <td>ご利用のカード会社の引き落とし日となります。</td>
            </tr>
            <tr>
              <td>申込みの有効期限</td>
              <td>なし</td>
            </tr>
            <tr>
              <td>ソフトウェアを使用するための動作環境</td>
              <td>
                <p>Google Chrome 最新バージョン</p>
                <p>※ Microsoft Edge、Internet Explorerは推奨しておりません。</p>
                <p>
                  ※ Microsoft EdgeやInternet
                  Explorerで互換性モード(IEモード)をお使いの場合、サイトが利用できない場合があります。その場合は互換表示を無効にするか、他のブラウザから操作してください。
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          ※
          上記以外の事項に関しましては、お取引の際に請求があれば遅延なく提示いたします。
        </p>
      </article>
    </>
  );
};
export default CommercialTransactions;
